import React from "react";
import { ImSubscriptionActionBlocker } from "../subscription/ImSubscriptionActionBlocker";
import { Preloader } from "../../common";
import IconSVG from "../../../styles/svg-icons";

interface Props {
    isExportLoad: boolean;
    handleExport: () => void;
    disabled?: boolean;
}

export default function ExportButton({
    isExportLoad,
    handleExport,
    disabled = false,
}: Props) {
    return (
        <ImSubscriptionActionBlocker>
            {(blocked) => (
                <Preloader
                    small
                    fullScreen={false}
                    inProgress={isExportLoad}
                    text="Exporting..."
                >
                    <button
                        disabled={disabled || blocked}
                        onClick={handleExport}
                        className="btn-link"
                    >
                        <IconSVG name="export" width="16" height="16" />
                        Export
                    </button>
                </Preloader>
            )}
        </ImSubscriptionActionBlocker>
    );
}
