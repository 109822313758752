import { Contact } from "../../../../../../types/amr-pipeline/models/Contact";
import { Table } from "../../../../../bidding/common/table";
import { EmptyPlaceholder } from "../../../../../common";
import { SectionContent } from "../../../../common/section/SectionContent";
import { syndicateContactsColumns } from "../../syndicate-contacts/syndicate-contacts-columns";

interface Props {
    contacts: Contact[];
}

export function SyndicateContacts({ contacts }: Props) {
    return (
        <SectionContent title="Syndicate Contacts" className="data-item-intex">
            {!!contacts?.length ? (
                <Table
                    className="data-list-striped data-list-syndicate-contacts"
                    dataItems={contacts}
                    columns={syndicateContactsColumns()}
                />
            ) : (
                <EmptyPlaceholder text="There is no information to display." />
            )}
        </SectionContent>
    );
}
