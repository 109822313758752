import React from "react";
import { IColumnDefinition } from "../../../../bidding/common/table/types/ColumnDefinition";
import { ColumnBuilder } from "../../../../bidding/common/table/columns/column-builder/ColumnBuilder";
import { constants, routes } from "../../../../../constants";
import { formatUtils, moneyUtils, numericUtils } from "../../../../../utils";
import { AmrClassStatusLabel } from "../../../common/AmrClassStatusLabel";
import moment from "moment";
import { Link } from "react-router-dom";
import { NonAmrPeriod } from "../../../common/NonAmrPeriod";
import { AmrClass } from "../../../../../types/amr-pipeline/models/AmrClass";
import { OnHoverTooltip } from "../../../../common";
import { BloombergLink } from "../../../../common/BloombergLink";
import { ValitanaLink } from "../../../../common/ValitanaLink";
import { AddToPortfolioLink } from "../../../common/AddToPortfolioLink";

export const classesColumns = () => {
    const classesColumnDefinitions: IColumnDefinition<AmrClass>[] = [
        {
            columnKey: "auctionState",
            renderColumnHeaderContent: () => "Auction State",
            renderColumnContent: (amrClass) => <AmrClassStatusLabel status={amrClass.status} />,
            headerClassName: "data-list-cell-lg",
            bodyClassName: "data-list-cell-lg",
        },
        {
            columnKey: "pricingDate",
            renderColumnHeaderContent: () => (
                <OnHoverTooltip overlay={"Date when the new margin is determined"}>
                    <span>AMR Pricing</span>
                </OnHoverTooltip>
            ),
            renderColumnContent: (amrClass) =>
                moment(amrClass.pricingDate).format(constants.dateFormat),
            headerClassName: "data-list-cell-sm",
            bodyClassName: "data-list-cell-sm",
        },
        {
            columnKey: "className",
            renderColumnHeaderContent: () => "Class",
            renderColumnContent: (amrClass, { transaction }) => (
                <OnHoverTooltip overlay={amrClass.name}>
                    <Link
                        to={routes.transactionClassUrl(
                            transaction.referenceName,
                            transaction.dealReferenceName,
                            amrClass.referenceName
                        )}
                    >
                        {amrClass.name}
                    </Link>
                </OnHoverTooltip>
            ),
            headerClassName: "data-list-cell-sm",
            bodyClassName: "data-list-cell-sm",
        },
        {
            columnKey: "cusip",
            renderColumnHeaderContent: () => "CUSIP (144A)",
            renderColumnContent: (amrClass) => (
                <OnHoverTooltip overlay={amrClass.cusip}>
                    <span className="text-ellipsis">{amrClass.cusip}</span>
                </OnHoverTooltip>
            ),
            headerClassName: "data-list-cell-sm",
            bodyClassName: "data-list-cell-sm",
        },
        {
            columnKey: "rating",
            renderColumnHeaderContent: () => "Rtg (M/S/F/K/D)",
            renderColumnContent: (amrClass) =>
                `${
                    amrClass.ratingMoodys || constants.emptyPlaceholder
                }/${amrClass.ratingSnP || constants.emptyPlaceholder}/${
                    amrClass.ratingFitch || constants.emptyPlaceholder
                }/${
                    amrClass.ratingKbra || constants.emptyPlaceholder
                }/${amrClass.ratingDbrs || constants.emptyPlaceholder}`,
            headerClassName: "data-list-cell-xl",
            bodyClassName: "data-list-cell-xl",
        },
        {
            columnKey: "balance",
            renderColumnHeaderContent: () => "Cur Bal",
            renderColumnContent: (amrClass) =>
                amrClass.balance != null && numericUtils.isNumber(amrClass.balance)
                    ? moneyUtils.money(amrClass.balance)
                    : constants.emptyPlaceholder,
            headerClassName: "data-list-cell-sm text-right",
            bodyClassName: "data-list-cell-sm text-right",
        },
        {
            columnKey: "margin",
            renderColumnHeaderContent: () => "Margin, %",
            renderColumnContent: (amrClass) =>
                amrClass.margin
                    ? formatUtils.formatMargin(amrClass.margin)
                    : constants.emptyPlaceholder,
            headerClassName: "data-list-cell-xs text-right",
            bodyClassName: "data-list-cell-xs text-right",
        },
        {
            columnKey: "capMargin",
            renderColumnHeaderContent: () => (
                <OnHoverTooltip overlay={"Maximum margin which was accepted for the auction"} placement="bottomRight">
                    <span>Cap Margin, %</span>
                </OnHoverTooltip>
            ),
            renderColumnContent: (amrClass) =>
                amrClass.capMargin
                    ? formatUtils.formatMargin(amrClass.capMargin)
                    : constants.emptyPlaceholder,
            headerClassName: "data-list-cell-sm text-right",
            bodyClassName: "data-list-cell-sm text-right",
        },
        {
            columnKey: "nonAmr",
            renderColumnHeaderContent: () => (
                <OnHoverTooltip overlay={"Number of months before another AMR can be started"} placement="bottomRight">
                    <span>Non-AMR</span>
                </OnHoverTooltip>
            ),
            renderColumnContent: (amrClass) => (
                <NonAmrPeriod period={amrClass.nonAmrPeriod} />
            ),
            headerClassName: "data-list-cell-sm",
            bodyClassName: "data-list-cell-sm",
        },
        {
        columnKey: 'actions',
        renderColumnHeaderContent: () => 'Actions',
        renderColumnContent: (transactionClass, { transaction }) =>
            <div className="action-row">
                <div className="action-col">
                    <AddToPortfolioLink
                        transaction={transaction}
                        transactionClass={transactionClass}
                        checkSubscription
                    />
                </div>
                <div className="action-col">
                    <BloombergLink
                        placeholderIfEmpty
                        secondaryPlaceholderColor
                        identifiers={transactionClass.class}
                    />
                </div>
                <div className="action-col">
                    <ValitanaLink
                        placeholderIfEmpty
                        secondaryPlaceholderColor
                        identifiers={transactionClass.class}
                    />
                </div>
            </div>,
        className: 'data-list-cell-sm-01 data-list-cell-bloomberg-menu overflow-visible text-right',
        stickRight: true,
    },
    ];

    return classesColumnDefinitions.map((c) => {
        return new ColumnBuilder(c);
    });
};;
