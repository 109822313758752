import { Difference, DifferenceType, ObjectArrayDifference, PrimitiveDifference } from '../../../../utils/differ/types';
import { ITableSection, TableIndicator } from './types';
import { indicatorMatchesSearchTerm } from './utils';
import { TableSectionWrapper } from './TableSectionWrapper';
import { TableIndicatorItemsSection } from './TableIndicatorItemsSection';
import { TableItemIndicatorsSection } from './TableItemIndicatorsSection';

/**
 * This indicator section utilizes both item-indicators and indicator-items sections.
 */
export class TableIndicatorSection<T, K extends unknown> implements ITableSection<T> {
    private tableIndicatorItemsSection: TableIndicatorItemsSection<T, K>;
    private tableItemIndicatorsSection: TableItemIndicatorsSection<T, K>;

    constructor(
        public title: string,
        public entityTitle: string,
        protected getItemsDifference: (difference: Difference<T>) => ObjectArrayDifference<K[]> | undefined,
        protected getEntityDifference: (itemDifference: Difference<K>) => PrimitiveDifference<unknown> | undefined,
        public indicators: TableIndicator<T, K>[],
        protected entityTitleFormatter?: (value: unknown) => string,
    ) {
        this.tableIndicatorItemsSection = new TableIndicatorItemsSection(
            title,
            entityTitle,
            getItemsDifference,
            getEntityDifference,
            indicators,
            entityTitleFormatter
        );

        this.tableItemIndicatorsSection = new TableItemIndicatorsSection(
            title,
            entityTitle,
            getItemsDifference,
            getEntityDifference,
            indicators,
            entityTitleFormatter
        );

        this.isMatchSearchTerm = this.isMatchSearchTerm.bind(this);
    }

    isMatchSearchTerm(searchTerms: string[], difference: Difference<T>) {
        if (this.hasChanges(difference) && searchTerms.some(s => this.entityTitle.toLowerCase().includes(s.toLowerCase()))) {
            return true;
        }

        const itemsDifference = this.getItemsDifference(difference)?.filter(d => d.type !== DifferenceType.Unchanged);

        const isUpdatedItemMatchSearchTerm = !!itemsDifference
            ?.filter(d => d.type === DifferenceType.Updated)
            .reduce((acc: boolean, item) => {
                if (acc) {
                    return acc;
                }

                if (!item.difference) {
                    return false;
                }

                return searchTerms.some(s =>
                    `${this.entityTitle} ${String(item.id)}`.toLowerCase().includes(s.toLowerCase()),
                );
            }, false);


        const entityMatchSearchTerm = !!itemsDifference?.reduce((acc: boolean, item) => {
            // Preserve 'true' value
            if (acc) {
                return acc;
            }

            if (!item.difference) {
                return false;
            }

            const entityDifference = this.getEntityDifference(item.difference);


            const searchValue = this.entityTitleFormatter
                ? this.entityTitleFormatter(String(entityDifference?.derivedValue))
                : `${this.entityTitle} ${String(entityDifference?.derivedValue)}`;

            const isSearchTermMatchTitle = searchTerms.some(s => searchValue.toLowerCase() === s.toLowerCase());

            return entityDifference?.type !== DifferenceType.Unchanged && isSearchTermMatchTitle;
        }, false);

        return entityMatchSearchTerm || isUpdatedItemMatchSearchTerm;
    }

    hasChanges(difference: Difference<T>) {
        const itemsDifference = this.getItemsDifference(difference);

        const entityHasChanges = !!itemsDifference?.reduce((acc: boolean, item) => {
            // Preserve 'true' value
            if (acc) {
                return acc;
            }

            if (!item.difference) {
                return false;
            }

            const entityDifference = this.getEntityDifference(item.difference);

            return entityDifference?.type !== DifferenceType.Unchanged;
        }, false);

        return this.indicators.some(indicator => indicator.hasChanges(difference)) || entityHasChanges;
    }

    render(searchTerm: string[] = [], difference: Difference<T>) {
        if (!this.hasChanges(difference)) {
            return null;
        }

        // Just to not do excess job in next steps
        if (
            searchTerm.some(s => s.length) &&
            !this.isMatchSearchTerm(searchTerm, difference) &&
            !this.indicators.some(indicatorMatchesSearchTerm(searchTerm, difference))
        ) {
            return null;
        }

        const itemsDifference = this.getItemsDifference(difference);

        if (!itemsDifference) {
            return null;
        }

        const indicatorItemsSection = this.tableIndicatorItemsSection.renderSection(searchTerm, difference);

        const itemIndicatorsSection = this.tableItemIndicatorsSection.renderSection(
            searchTerm,
            difference,
            this.tableIndicatorItemsSection.renderedIndicatorItems
        );

        return (
            <TableSectionWrapper title={this.title}>
                {itemIndicatorsSection}
                {indicatorItemsSection}
            </TableSectionWrapper>
        );
    }
}
