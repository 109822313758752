import { useEffect } from "react";
import { General } from "./sections/General";
import { TabBody } from "../../../common/tabs/TabBody";
import { DownloadedDocumentsType } from "./sections/DownloadedDocumentsType";
import { ViewedTabs } from "./sections/ViewedTabs";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../types/state/AppState";
import { EmptyPlaceholder } from "../../../../common/EmptyPlaceholder";
import { OriginatingTransaction } from "../../../../../types/amr-pipeline/models/OriginatingTransaction";
import { amrTransactionAnalyticsActions as actions } from "../../../../../actions/amr-transaction-analytics.actions";
import { Preloader } from "../../../../common";
import { ClientsActivityWidget } from './clientsActivity/ClientsActivityWidget';
import { TransactionViewHistoryWidget } from "./transactionViewHistory/TransactionViewHistoryWidget";
import { TransactionStatus } from "../../../../../types/amr-pipeline/enums/TransactionStatus";
import { StatusMessageSectionType } from "../../../../../types/state/NotificationState";
import { StatusMessageSection } from "../../../../status-message/StatusMessageSection";

interface Props {
    transaction: OriginatingTransaction;
    isLoading: boolean;
}

export const Analytics = ({ transaction, isLoading }: Props) => {
    const dispatch = useDispatch();
    const analytics = useSelector(
        (state: AppState) => state.issuanceMonitor.amrPipelineDetailed.analytics
    );

    useEffect(() => {
        if (!transaction) {
            return;
        }

        dispatch(
            actions.analyticsInit(
                transaction.dealReferenceName,
                transaction.referenceName,
            )
        );

        return () => {
            dispatch(actions.reset());
        };
    }, [transaction, dispatch]);

    if (isLoading || analytics.isLoading) {
        return <Preloader inProgress={true} />;
    }

    const infoNotification = [
        TransactionStatus.Priced,
        TransactionStatus.Closed,
    ].includes(transaction.status) ? (
        <div className="analytics-info">
            <StatusMessageSection type={StatusMessageSectionType.Info}>
                Client analytics is calculated only from deal announcement to pricing
            </StatusMessageSection>
        </div>
    ) : null;

    return (
        <>
            {infoNotification}
            <TabBody className="tabs-data-padding component-tabs-analytics">
                {!!analytics.totalTransactionViews ? (
                    <>
                        <General
                            daysInMarket={analytics.daysInMarket}
                            totalDocumentsDownloads={analytics.totalDocumentsDownloads}
                            recentTransactionViews={analytics.recentTransactionViews}
                            totalTransactionViews={analytics.totalTransactionViews}
                        />
                        <div className="data-item data-item-analytics data-item-general-cols">
                            <div className="data-item-content">
                                <div className="data-item-row-col">
                                    <DownloadedDocumentsType
                                        documentDownloads={analytics.documentDownloads}
                                        totalDocumentsDownloads={analytics.totalDocumentsDownloads}
                                    />
                                </div>
                                <div className="data-item-row-col">
                                    <ViewedTabs clientsActivity={analytics.clientsActivity.data} />
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <ClientsActivityWidget transaction={transaction} />
                        </div>
                        <div className="">
                            <TransactionViewHistoryWidget transaction={transaction} />
                        </div>
                    </>
                ) : (
                    <EmptyPlaceholder text="There is no information to display." />
                )}
            </TabBody>
        </>
    );
};
