import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { amrPipelineDetailedActions } from '../../../../../../actions';
import { Contact } from '../../../../../../types/amr-pipeline/models/Contact';
import { AppState } from '../../../../../../types/state/AppState';
import { sortContactsByPrimary } from '../../../../../../utils/amr-pipeline.utils';
import { TabList, TabItem } from '../../../../../bidding/common/tab-list';
import { Popup } from '../../../../../controls';
import CompanyTab from './tabs/CompanyTab';
import MembersTab from './tabs/MembersTabs';

enum Tab {
    Members,
    Company,
}

export const InvestorPopup = () => {
    const dispatch = useDispatch();
    const [tab, setTab] = useState(Tab.Members);

    const { investor } = useSelector((state: AppState) => state.issuanceMonitor.amrPipelineDetailed.investorPopup);

    const renderTab = () => {
        if (tab === Tab.Company) {
            return <CompanyTab investor={investor?.company} />;
        }

        let members: Contact[] = [];

        if (investor?.members.length) {
            members = sortContactsByPrimary(investor.members, investor.company?.primaryContact);
        }

        return (
            <MembersTab members={members} primaryContact={investor?.company?.primaryContact} />
        );
    };

    if (!investor?.members.length) {
        return null;
    }

    return (
        <Popup
            title={investor?.company?.legalName}
            modalClass="modal-iois"
            onClose={() => dispatch(amrPipelineDetailedActions.hideInvestorPopup())}
        >
            <div className="modal-body">
                <TabList>
                    <TabItem
                        active={tab === Tab.Members}
                        title={`Members (${investor?.members.length})`}
                        onClick={() => setTab(Tab.Members)}
                    />
                    <TabItem active={tab === Tab.Company} title="Company" onClick={() => setTab(Tab.Company)} />
                </TabList>
                <div className="tabs-content container-flex">{renderTab()}</div>
            </div>
        </Popup>
    );
};
