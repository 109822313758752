import { FormField } from "../../../../common/FormField";
import { SectionContent } from "../../../../common/section/SectionContent";
import { constants } from "../../../../../../constants";
import { RequiredFeature } from "../../../../../access/RequiredFeature";
import { SubscriptionFeature } from "../../../../../../types/billing/SubscriptionFeature";
import { BlockedFeatureContent, SubscribeLink } from "../../../../../../components/access/BlockedFeatureText";

interface Props {
    daysInMarket: number;
    totalDocumentsDownloads: number;
    recentTransactionViews: number;
    totalTransactionViews: number;
}

export function General({
    daysInMarket,
    totalDocumentsDownloads,
    recentTransactionViews,
    totalTransactionViews,
}: Props) {
    return (
        <SectionContent title="General" className="data-item-general-information data-item-general-cols">
            <div className="data-item-row-col">
                <FormField
                    infoTooltip="Count of days from transaction marketing start day to pricing day"
                    title="Days on the Market"
                    className="data-item-row-full-height"
                >
                    {daysInMarket || constants.emptyPlaceholder}
                </FormField>
                <RequiredFeature
                    feature={SubscriptionFeature.IssuanceMonitorFullAccess}
                    renderBlockedContent={() => (
                        <BlockedFeatureContent
                            inline
                            text={<><SubscribeLink /> to see total docs downloads</>}
                            className="restricted-placeholder-general"
                        />
                    )}
                >
                    <FormField title="Total Docs Downloads" className="data-item-row-full-height">
                        {totalDocumentsDownloads || constants.emptyPlaceholder}
                    </FormField>
                </RequiredFeature>
            </div>
            <div className="data-item-row-col">
                <FormField
                    infoTooltip="Past 24 hours"
                    title="Recent Transaction Views"
                    className="data-item-row-full-height"
                >
                    {recentTransactionViews || constants.emptyPlaceholder}
                </FormField>
                <RequiredFeature
                    feature={SubscriptionFeature.IssuanceMonitorFullAccess}
                    renderBlockedContent={() => (
                        <BlockedFeatureContent
                            inline
                            text={<><SubscribeLink /> more transaction view(s).</>}
                            className="restricted-placeholder-general"
                        />
                    )}
                >
                    <FormField title="Total Transaction Views" className="data-item-row-full-height">
                        {totalTransactionViews || constants.emptyPlaceholder}
                    </FormField>
                </RequiredFeature>
            </div>
        </SectionContent>
    );
}
