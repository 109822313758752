import classNames from "classnames";
import { SectionContent } from "../../../../common/section/SectionContent";
import { Plot } from "../../../../../common";
import { DocumentDownloadsStatistic } from "../../../../../../types/amr-pipeline/models/DocumentDownloadsStatistic";
import { groupBy, kebabCase, sumBy } from "lodash";
import { constants } from "../../../../../../constants";
import { SubscriptionFeature } from "../../../../../../types/billing/SubscriptionFeature";
import { user } from "../../../../../../user";
import { RequiredFeature } from "../../../../../access/RequiredFeature";
import { BlockedFeatureContent, SubscribeLink } from "../../../../../../components/access/BlockedFeatureText";

const generalData = {
    textinfo: "none",
    hoverinfo: "text",
    hole: 0.65,
    type: "pie",
    hoverlabel: {
        bgcolor: "#4A4A4A",
        bordercolor: "#4A4A4A",
        font: {
            size: 12,
            color: "#fff",
        },
    },
    direction: "clockwise",
};

const emptyPieChartData = {
    values: [100],
    marker: {
        colors: ["#F5F5F5"],
    },
};

const layout = {
    height: 122,
    width: 122,
    showlegend: false,
    autosize: false,
    margin: {
        l: 0,
        r: 0,
        b: 0,
        t: 0,
        pad: 4,
    },
};

const mainConfig = {
    responsive: true,
    displaylogo: false,
    doubleClickDelay: 1000,
    displayModeBar: false,
    showTips: false,
};

const colorsSubscribed = ['#FFB400', '#8D40BE', '#71C18D', '#487EB2', '#5BBED8'];
const colorsUnsubscribed = ['#FFB400', '#8D40BE', '#F5F5F5', '#F5F5F5', '#F5F5F5'];

interface Props {
    documentDownloads: DocumentDownloadsStatistic[];
    totalDocumentsDownloads: number;
}

export function DownloadedDocumentsType({
    documentDownloads,
    totalDocumentsDownloads,
}: Props) {
    const groupedByAccessType = groupBy(documentDownloads, (x) => x.accessType);
    const dealDocuments = [
        ...(groupedByAccessType.DownloadAllDocuments || []),
        ...(groupedByAccessType.DownloadOneDocument || []),
    ];

    const withImSubscription = user.hasFeatures(SubscriptionFeature.IssuanceMonitorFullAccess);

    const documentsData = [
        {
            title: "Deal Documents",
            downloads: sumBy(dealDocuments, (dt) => dt.numberOfDocuments),
        },
        {
            title: "Disclosure",
            downloads: sumBy(
                groupedByAccessType.DownloadDisclosureFile,
                (dt) => dt.numberOfDocuments
            ),
        },
        {
            title: "Manager Presentation",
            downloads: sumBy(
                groupedByAccessType.DownloadManagerPresentationFile,
                (dt) => dt.numberOfDocuments
            ),
        },
        {
            title: "Target Portfolio",
            downloads: sumBy(
                groupedByAccessType.ExportTargetPortfolio,
                (dt) => dt.numberOfDocuments
            ),
        },
        {
            title: "Intex File",
            downloads: sumBy(
                groupedByAccessType.DownloadIntexFile,
                (dt) => dt.numberOfDocuments
            ),
        },
    ];

    const documentsWithDownloads = documentsData.filter(
        (d) => d.downloads
    ).length;

    const data = [
        {
            ...generalData,
            ...(documentsWithDownloads
                ? {
                      values: documentsData.map((d) =>
                          d.downloads
                              ? (d.downloads * 100) / totalDocumentsDownloads
                              : 0
                      ),
                      text: withImSubscription ? documentsData.map((d) => d.downloads) : documentsData[0].downloads,
                      marker: {
                          colors: withImSubscription ? colorsSubscribed : colorsUnsubscribed,
                          ...(documentsWithDownloads > 1 && {
                              line: {
                                  color: "#fff",
                                  width: 2,
                              },
                          }),
                      },
                  }
                : emptyPieChartData),
        },
    ];

    const chartData = withImSubscription ? data : [data[0]];

    const renderAgenda = () =>
        documentsData.map(d => (
            <div key={d.title} className={classNames('agenda-item', kebabCase(d.title))}>
                <span className="downloaded-documents-label">{d.title} </span>
                <span>{d.downloads || constants.emptyPlaceholder}</span>
            </div>
        ));

    const renderBlockedContent = () => (
        <>
            <div
                key={documentsData[0].title}
                className={classNames('agenda-item', kebabCase(documentsData[0].title))}
            >
                <span className="downloaded-documents-label">{documentsData[0].title} </span>
                <span>{documentsData[0].downloads || constants.emptyPlaceholder}</span>
            </div>
            <BlockedFeatureContent
                inline
                className="empty-placeholder analytics-legend"
                text={
                    <>
                        <h3>See {documentsData.length - 1} more type stats</h3>
                        <SubscribeLink />
                    </>
                }
            />
        </>
    );

    return (
        <SectionContent
            title="Downloaded Documents Type"
            className="data-item-general-information data-item-general-cols"
        >
            <div className="downloaded-documents-type">
                <div className="downloaded-documents-chart">
                    <Plot data={chartData} layout={layout} config={mainConfig} />
                </div>
                <div className="agenda agenda-vertical">
                    <RequiredFeature feature={SubscriptionFeature.IssuanceMonitorFullAccess} renderBlockedContent={renderBlockedContent}>
                        {renderAgenda()}
                    </RequiredFeature>
                </div>
            </div>
        </SectionContent>
    );
}
