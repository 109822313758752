import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { routes } from '../../../../../constants';
import { OriginatingTransactionClass } from "../../../../../types/amr-pipeline/models/OriginatingTransactionClass";
import { Table } from "../../../../bidding/common/table";
import { amrFormatUtils } from "../../../../../utils";
import { TotalBalance } from "../../../detailed/new-issue/capital-structure/TotalBalance";
import { mailColumns } from "./columns";
import { ColumnBuilder } from "../../../../bidding/common/table/columns/column-builder/ColumnBuilder";
import { OriginatingTransaction } from "../../../../../types/amr-pipeline/models/OriginatingTransaction";
import { GeneralInfo } from "./GeneralInfo";
import { AmrTransaction } from "../../../../../types/amr-pipeline/models/AmrTransaction";
import { TransactionType } from "../../../../../types/amr-pipeline/enums/TransactionType";
import { CollateralQualityTest } from '../../../../../types/amr-pipeline/models/CollateralQualityTest';
import { Preloader } from '../../../../common';

interface Props {
    transaction: OriginatingTransaction | AmrTransaction;
    collateralQualityTests: CollateralQualityTest[];
    text: string;
    loading: boolean;
    setLoading: (status: boolean) => void;
}

export function MailPreview({
    transaction,
    collateralQualityTests,
    text,
    loading,
    setLoading
}: Props) {
    const { classes } = transaction;
    const isOriginatingTransaction = transaction.type !== TransactionType.amr;
    const capitalStructureTransactionClasses = isOriginatingTransaction
        ? (classes as OriginatingTransactionClass[]).map((tc) => {
            const {
                tranche,
                floaterIndex,
                margin,
                ...capitalStructureClass
            } = tc;
            const coupon = amrFormatUtils.formatInventoryCoupon(
                floaterIndex,
                margin
            );
            return { ...capitalStructureClass, ...tranche, coupon };
        })
        : [];

    useEffect(() => {
        if(isOriginatingTransaction && !collateralQualityTests) {
            setLoading(true)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Preloader inProgress={loading} fullScreen={false}>
            <div className="mail-preview">
                <div className="mail-preview-head">
                    <h2>Hello,</h2>
                    <p>
                        {transaction.arranger?.legalName} shared <b>{transaction.dealLegalName}</b> transaction with you.
                    </p>
                    <p
                        style={{
                            whiteSpace: "break-spaces", // TODO Remove this after markdown editor will be added
                        }}
                    >
                        {text}
                    </p>
                </div>
                <GeneralInfo transaction={{
                    ...transaction,
                    collateralQualityTests,
                }} />
                {isOriginatingTransaction && (
                    <Table
                        className="data-list-striped data-list-share-transaction"
                        dataItems={capitalStructureTransactionClasses}
                        columns={mailColumns}
                        renderFooterRow={(
                            columns: ColumnBuilder<OriginatingTransactionClass>[]
                        ) => (
                            <TotalBalance
                                classes={classes as OriginatingTransactionClass[]}
                                columns={columns}
                            />
                        )}
                    />
                )}
                <p>
                    Please use the link below to view the transaction:
                </p>
                <p className="mail-preview-link-placeholder">
                    Here will be a shareable link to the transaction
                </p>
                <p className="mail-preview-info">
                    If you do not have an account on the KTX ATS platform, you will be redirected to the registration page first.
                </p>
                <div>
                    <h3>Regards,</h3>
                    <p>Team KopenTech</p>
                </div>
            </div>
            <div className="mail-preview-bottom">
                This email (including any attachments) is confidential and, by accepting this email,
                you agree that you will treat it as such and act or refrain from acting in accordance
                with applicable law. It does not constitute investment advice, is only for the use of
                the intended recipient and copying, redistributing or acting upon such email by unintended
                recipients is unauthorized and may be unlawful. Receipt by anyone other than the intended
                recipient is not a waiver of any protections or work-product or attorney-client privilege.
                If you received this email in error, please notify KopenTech, LLC ("KopenTech") immediately.
                KopenTech makes no representation or warranty as to the accuracy or completeness of the email
                and its contents and is not obligated to update or revise any information. This email is not an
                official confirmation of any transaction and is not offer to sell or the solicitation for the
                purchase of any security, financial instrument or interest in an investment vehicle. Such
                offering shall be made only by a final confidential offering circular to eligible investors.
                Any comments or opinions expressed in this communication do not necessarily reflect those of
                KopenTech or its affiliates. Emails sent to and from this address may be monitored and stored
                in accordance with internal policy. This email and any attachments are confidential and may
                be privileged or otherwise protected from disclosure. If you are not the intended recipient,
                please telephone or email the sender and delete this message and any attachment from your
                system immediately. If you are not the intended recipient you must not copy this message or
                attachment or disclose the contents to any other person. For more details, see the&nbsp;
                <Link to={routes.termsAndConditions}>Terms and Conditions</Link> and <Link to={routes.privacyPolicy}>Privacy Policy</Link>
            </div>
        </Preloader>
    );
}
