import React from "react";
import { IColumnDefinition } from "../../../../bidding/common/table/types/ColumnDefinition";
import { ColumnBuilder } from "../../../../bidding/common/table/columns/column-builder/ColumnBuilder";
import { constants } from "../../../../../constants";
import { formatUtils } from "../../../../../utils";
import { AmrClassStatusLabel } from "../../../common/AmrClassStatusLabel";
import moment from "moment";
import { NonAmrPeriod } from "../../../common/NonAmrPeriod";
import { AmrClass } from "../../../../../types/amr-pipeline/models/AmrClass";
import { OnHoverTooltip } from "../../../../common";

export const auctionsColumns = () => {
    const auctionsColumnDefinitions: IColumnDefinition<AmrClass>[] = [
        {
            columnKey: "auctionState",
            renderColumnHeaderContent: () => "Auction State",
            renderColumnContent: (auction) => (
                <AmrClassStatusLabel status={auction.status} />
            ),
            headerClassName: "data-list-cell-lg",
            bodyClassName: "data-list-cell-lg",
        },
        {
            columnKey: "pricingDate",
            renderColumnHeaderContent: () => (
                <OnHoverTooltip overlay={"Date when the new margin is determined"}>
                    <span>AMR Pricing</span>
                </OnHoverTooltip>
            ),
            renderColumnContent: (auction, context) =>
                auction.pricingDate
                    ? <span>{moment(auction.pricingDate).format(constants.dateFormat)}</span>
                    : constants.emptyPlaceholder,
            headerClassName: "data-list-cell-sm",
            bodyClassName: "data-list-cell-sm",
        },
        {
            columnKey: "winningMargin",
            renderColumnHeaderContent: () => "Win Margin, %",
            renderColumnContent: (auction) =>
                auction.winningMargin
                    ? formatUtils.formatMargin(auction.winningMargin)
                    : constants.emptyPlaceholder,
            headerClassName: "data-list-cell-sm text-right",
            bodyClassName: "data-list-cell-sm text-right",
        },
        {
            columnKey: "capMargin",
            renderColumnHeaderContent: () => (
                <OnHoverTooltip overlay={"Maximum margin which was accepted for the auction"} placement="bottomRight">
                    <span>Cap Margin, %</span>
                </OnHoverTooltip>
            ),
            renderColumnContent: (auction) =>
                formatUtils.formatMargin(auction.capMargin),
            headerClassName: "data-list-cell-sm text-right",
            bodyClassName: "data-list-cell-sm text-right",
        },
        {
            columnKey: "nonAmr",
            renderColumnHeaderContent: () => (
                <OnHoverTooltip overlay={"Number of months before another AMR can be started"} placement="bottomRight">
                    <span>Non-AMR</span>
                </OnHoverTooltip>
            ),
            renderColumnContent: (auction) => (
                <NonAmrPeriod period={auction.nonAmrPeriod} />
            ),
            headerClassName: "data-list-cell-sm",
            bodyClassName: "data-list-cell-sm",
        },
        {
            columnKey: "settlementDate",
            renderColumnHeaderContent: () => (
                <OnHoverTooltip overlay={"Date when the winning margin applied"} placement="bottomRight">
                    <span>AMR Settlement</span>
                </OnHoverTooltip>
            ),
            renderColumnContent: (auction) =>
                moment(auction.settlementDate).format(constants.dateFormat),
            headerClassName: "data-list-cell-md",
            bodyClassName: "data-list-cell-md",
        },

    ]

    return auctionsColumnDefinitions.map((c) => {
        return new ColumnBuilder(c);
    });
};
