import { constants } from "../../../../../constants";
import { Contact } from "../../../../../types/amr-pipeline/models/Contact";
import { IColumnDefinition } from "../../../../bidding/common/table/types/ColumnDefinition";
import { ColumnBuilder } from "../../../../bidding/common/table/columns/column-builder/ColumnBuilder";
import { OnHoverTooltip } from "../../../../common";
import IconSVG from '../../../../../styles/svg-icons';
import { nameUtils } from "../../../../../utils";
import { ContactType } from '../../../../../types/amr-pipeline/enums/ContactType';

export const syndicateContactsColumns = () => {
    const syndicateContactsColumnsDefinition: IColumnDefinition<Contact>[] = [
        {
            columnKey: "full-name",
            renderColumnHeaderContent: () => "Full Name",
            renderColumnContent: ({ firstName, lastName }) => {
                const fullName = nameUtils.getFullName(firstName, lastName);

                return (
                    <OnHoverTooltip
                        overlay={fullName}
                        disabled={!(firstName || lastName)}
                        placement="bottom"
                    >
                        <span>{fullName}</span>
                    </OnHoverTooltip>
                );
            },
            headerClassName: "data-list-cell-lg data-list-cell-full-name",
            bodyClassName: "data-list-cell-lg",
        },
        {
            columnKey: 'distribution-list',
            renderColumnHeaderContent: () => '',
            renderColumnContent: ({ type }) => {
                if (type === ContactType.DistributionList) {
                    return <IconSVG name="distrib" width={16} height={16} />
                }

                if (type === ContactType.HeadOfDesk) {
                    return <IconSVG name="head-of-desk" width={16} height={16} />
                }
            },
            headerClassName: 'data-list-cell-xxs',
            bodyClassName: 'data-list-cell-xxs',
        },
        {
            columnKey: 'title',
            renderColumnHeaderContent: () => 'Title',
            renderColumnContent: ({ type, title }) => {
                if (type === ContactType.DistributionList) {
                    return (
                        <OnHoverTooltip overlay="Distribution List" placement="bottom">
                            <span>Distribution List</span>
                        </OnHoverTooltip>
                    );
                }

                if (type === ContactType.HeadOfDesk) {
                    return (
                        <OnHoverTooltip overlay="Head of Desk" placement="bottom">
                            <span>Head of Desk</span>
                        </OnHoverTooltip>
                    );
                }

                if (title) {
                    return (
                        <OnHoverTooltip overlay={title} placement="bottom">
                            <span>{title}</span>
                        </OnHoverTooltip>
                    );
                }

                return constants.emptyPlaceholder;
            },
            headerClassName: 'data-list-cell-md data-list-cell-full-name',
            bodyClassName: 'data-list-cell-md',
        },
        {
            columnKey: "email",
            renderColumnHeaderContent: () => "Email",
            renderColumnContent: ({ email }) =>
                email ? (
                    <OnHoverTooltip overlay={email} placement="bottom">
                        <a href={"mailto:" + email}>{email}</a>
                    </OnHoverTooltip>
                ) : (
                    constants.emptyPlaceholder
                ),
            headerClassName: "data-list-cell-xl-xxl",
            bodyClassName: "data-list-cell-xl-xxl",
        },
        {
            columnKey: "office",
            renderColumnHeaderContent: () => "Office",
            renderColumnContent: ({ officePhoneNumber }) =>
                officePhoneNumber ? (
                    <OnHoverTooltip
                        overlay={officePhoneNumber}
                        placement="bottom"
                    >
                        <span>{officePhoneNumber}</span>
                    </OnHoverTooltip>
                ) : (
                    constants.emptyPlaceholder
                ),
            headerClassName: "data-list-cell-sm",
            bodyClassName: "data-list-cell-sm",
        },
        {
            columnKey: "mobile",
            renderColumnHeaderContent: () => "Mobile",
            renderColumnContent: ({ mobilePhoneNumber }) =>
                mobilePhoneNumber ? (
                    <OnHoverTooltip
                        overlay={mobilePhoneNumber}
                        placement="bottom"
                    >
                        <span>{mobilePhoneNumber}</span>
                    </OnHoverTooltip>
                ) : (
                    constants.emptyPlaceholder
                ),
            headerClassName: "data-list-cell-sm",
            bodyClassName: "data-list-cell-sm",
        },
    ];
    return syndicateContactsColumnsDefinition.map((c) => {
        return new ColumnBuilder(c);
    });
};
