import * as React from 'react';
import moment from 'moment';
import { constants } from '../../../../../constants';
import { AmrTrancheStatus } from '../../../../../types/amr-pipeline/enums/AmrTrancheStatus';
import { formatUtils, moneyUtils } from '../../../../../utils';
import { FormField } from '../../../common/FormField';
import { SectionContent } from '../../../common/section/SectionContent';
import { TabBody } from '../../../common/tabs/TabBody';
import { InlineAlertPanel } from '../../../common/InlineAlertPanel';
import { AmrClassStatusLabel } from '../../../common/AmrClassStatusLabel';
import { AmrClass } from '../../../../../types/amr-pipeline/models/AmrClass';
import { amrTrancheStatuses } from '../../../../../constants/amr.tranche.statuses';


interface DetailsProps {
    amrClass: AmrClass;
}

export function Details({ amrClass} : DetailsProps) {
    const statusVisible =
        (
            amrClass.status === AmrTrancheStatus.Initiated ||
            amrClass.status === AmrTrancheStatus.Scheduled ||
            amrClass.status === AmrTrancheStatus.AcceptingBids
        );

    return (
        <TabBody className="tabs-data-padding component-tabs-deal-class-details">
            {
                statusVisible &&
                <SectionContent title="Status">
                    <InlineAlertPanel className="panel-info-primary">
                        Current Status <AmrClassStatusLabel status={amrClass.status} />
                    </InlineAlertPanel>
                </SectionContent>
            }
            <SectionContent title="Information">
                <div className="data-item-row-col">
                    <FormField title="Class Name" value={amrClass.name} />
                    <FormField title="Ticker (Rule 144A)" value={amrClass.ticker || constants.emptyPlaceholder} />
                    <FormField title="CUSIP (Rule 144A)" value={amrClass.cusip || constants.emptyPlaceholder} />
                    <FormField title="ISIN (Rule 144A)" value={amrClass.isin || constants.emptyPlaceholder} />
                    <FormField title="Ticker (Reg S)" value={amrClass.class.tickerRegS || constants.emptyPlaceholder} />
                    <FormField title="CUSIP (Reg S)" value={amrClass.class.cusipRegS || constants.emptyPlaceholder} />
                    <FormField title="ISIN (Reg S)" value={amrClass.class.isinRegS || constants.emptyPlaceholder} />
                    <FormField
                        title="Common Code (Reg S)"
                        value={amrClass.class.commonCodeRegS || constants.emptyPlaceholder}
                    />
                    <FormField
                        title="Ticker (Acc’d Inv./Certif.)"
                        value={amrClass.class.tickerAccdInvCertif || constants.emptyPlaceholder}
                    />
                    <FormField
                        title="CUSIP (Acc’d Inv./Certif.)"
                        value={amrClass.class.cusipAccdInvCertif || constants.emptyPlaceholder}
                    />
                    <FormField
                        title="ISIN (Acc’d Inv./Certif.)"
                        value={amrClass.class.isinAccdInvCertif || constants.emptyPlaceholder}
                    />
                    <FormField title="Type" value={amrClass.type || constants.emptyPlaceholder} />
                    <FormField title="AMR" value={formatUtils.formatBoolean(amrClass.class.amr)} className="text-capitalize" />
                    <FormField title="Equity" value={formatUtils.formatBoolean(amrClass.class.equity)} className="text-capitalize" />
                    <FormField title="Redeemed" value={formatUtils.formatBooleanWithPlaceholder(amrClass.redeemed)} className="text-capitalize" />
                    <FormField title="Pari-Passu Classes" value={amrClass.pariPassuTranches || constants.emptyPlaceholder} />
                    <FormField title="Class Supported" value={amrClass.trancheSupported || constants.emptyPlaceholder} />
                    <FormField
                        title="Non-AMR Period (month)"
                        value={amrClass.nonAmrPeriod || constants.emptyPlaceholder}
                        tooltip="Number of months before another AMR can be started"
                        tooltipLabel={true}
                    />
                    <FormField
                        title="Non-AMR Period End"
                        value={amrClass.nonAmrPeriodEnd
                            ? moment(amrClass.nonAmrPeriodEnd).format(constants.dateFormat)
                            : constants.emptyPlaceholder}
                    />
                </div>

                <div className="data-item-row-col">
                    <FormField
                        title="Original Balance"
                        value={amrClass.originalBalance
                            ? moneyUtils.money(amrClass.originalBalance)
                            : constants.emptyPlaceholder}
                    />
                    <FormField
                        title="Current Balance"
                        value={amrClass.balance
                            ? moneyUtils.money(amrClass.balance)
                            : constants.emptyPlaceholder}
                    />
                    <FormField
                        title="Min Denomination"
                        value={amrClass.minimumDenomination
                            ? moneyUtils.money(amrClass.minimumDenomination)
                            : constants.emptyPlaceholder}
                    />
                    <FormField title="Floater Index" value={amrClass.floaterIndex || constants.emptyPlaceholder} />
                    <FormField
                        title="Current Margin (%)"
                        value={amrClass.margin
                            ? formatUtils.formatMargin(amrClass.margin)
                            : constants.emptyPlaceholder}
                    />
                    <FormField
                        title="Original Margin (%)"
                        value={amrClass.originalMargin
                            ? formatUtils.formatMargin(amrClass.originalMargin)
                            : constants.emptyPlaceholder}
                    />
                    <FormField
                        title="Factor"
                        value={amrClass.factor
                            ? formatUtils.formatFactor(amrClass.factor)
                            : constants.emptyPlaceholder}
                    />
                    <FormField
                        title="Status"
                        value={amrClass.status
                            ? amrTrancheStatuses.getStatusText(amrClass.status)
                            : constants.emptyPlaceholder}
                    />

                    <table className="table-output-data">
                        <thead>
                            <tr>
                                <th></th>
                                <th>S&amp;P</th>
                                <th>Moody’s</th>
                                <th>Fitch</th>
                                <th>KBRA</th>
                                <th>DBRS</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>Curr.</th>
                                <td>
                                    <div className="form-item">
                                        <span>{amrClass.ratingSnP || constants.emptyPlaceholder}</span>
                                    </div>
                                </td>
                                <td>
                                    <div className="form-item">
                                        <span>{amrClass.ratingMoodys || constants.emptyPlaceholder}</span>
                                    </div>
                                </td>
                                <td>
                                    <div className="form-item">
                                        <span>{amrClass.ratingFitch || constants.emptyPlaceholder}</span>
                                    </div>
                                </td>
                                <td>
                                    <div className="form-item">
                                        <span>{amrClass.ratingKbra || constants.emptyPlaceholder}</span>
                                    </div>
                                </td>
                                <td>
                                    <div className="form-item">
                                        <span>{amrClass.ratingDbrs || constants.emptyPlaceholder}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>Orig.</th>
                                <td>
                                    <div className="form-item">
                                        <span>{amrClass.originalRatingSnP || constants.emptyPlaceholder}</span>
                                    </div>
                                </td>
                                <td>
                                    <div className="form-item">
                                        <span>{amrClass.originalRatingMoodys || constants.emptyPlaceholder}</span>
                                    </div>
                                </td>
                                <td>
                                    <div className="form-item">
                                        <span>{amrClass.originalRatingFitch || constants.emptyPlaceholder}</span>
                                    </div>
                                </td>
                                <td>
                                    <div className="form-item">
                                        <span>{amrClass.originalRatingKbra || constants.emptyPlaceholder}</span>
                                    </div>
                                </td>
                                <td>
                                    <div className="form-item">
                                        <span>{amrClass.originalRatingDbrs || constants.emptyPlaceholder}</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </SectionContent>
        </TabBody>
    );
}
