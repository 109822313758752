import React from 'react';
import { Difference, DifferenceType, ObjectArrayItemDifference } from '../../../../utils/differ/types';

export type Formatter<T> = (value?: any, initialObject?: T) => string | null | undefined;

export enum TableRenderMode {
    ItemIndicator = 'item-indicator',
    IndicatorItem = 'indicator-item',
}

export interface TableRenderOptions {
    withTitle?: boolean;
    mode: TableRenderMode;
}

export interface ItemIndicators<T, K> {
    item: ObjectArrayItemDifference<K>;
    indicators: TableIndicator<T, K>[];
}

export interface IndicatorItems<T, K> {
    indicator: TableIndicator<T, K>;
    items: ObjectArrayItemDifference<K>[];
}

/**
 * Represents a section with a header and a list of indicators
 */
export interface IIndicatorSection<T> {
    // List of indicators
    indicators: Indicator<T>[]

    // Determines whether there are any changes in indicators within this section
    hasChanges: (difference: Difference<T>) => boolean;

    // Renders sections with indicators
    render: (searchTerm: string[], difference: Difference<T>) => React.ReactNode;
}

/**
 * Represents a section with a header and a list of indicators
 */
 export interface ITableSection<T> {
    // List of indicators
    indicators: Indicator<T>[]

    // Determines whether there are any changes in indicators within this section
    hasChanges: (difference: Difference<T>) => boolean;

    // Renders sections with indicators
    render: (searchTerm: string[], difference: Difference<T>) => React.ReactNode;

    isMatchSearchTerm?: (searchTerm: string[], difference: Difference<T>) => boolean;
}

/**
 * Represents a single indicator
 */
export interface Indicator<T> {
    title: string;
    hasChanges: (difference: Difference<T>) => boolean;
    matchesSearchTerm: (searchTerms: string[], difference: Difference<T>) => boolean;
}

/**
 * Simple indicator is an indicator for Primitive fields, or object fields
 * which represent only that the object was added or removed
 */
export interface SimpleIndicator<T> extends Indicator<T> {
    uid: string;
    render: (searchTerm: string[], difference: Difference<T>) => React.ReactNode;
}

/**
 * Table indicator is an indicator that represents a diff structure of object array.
 * `render` method should render the whole row with title and changed values.
 * later used in TableIndicatorSection in pivoted view.
 */
export interface TableIndicator<T, K> extends Indicator<T> {
    render: (
        searchTerm: string[] | undefined,
        arrayItem: ObjectArrayItemDifference<K>,
        options?: TableRenderOptions,
    ) => React.ReactNode;
    diffTypeInItem: (item: ObjectArrayItemDifference<K>) => DifferenceType;
    hasChangesInItem: (item: ObjectArrayItemDifference<K>) => boolean;
    arrayItemDifferenceMatchesSearchTerm: (searchTerms: string[], arrayItem: ObjectArrayItemDifference<K>) => boolean;
}
