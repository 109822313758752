import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { amrPipelineDetailedActions, imUserConfigActions } from '../../../actions/';
import { AppState } from '../../../types/state/AppState';
import { PipelineDetailedState } from '../../../types/state/PipelineDetailedState';
import { TransactionsSelectionPanel } from './TransactionsSelectionPanel';
import {
    canViewTransactionDetails,
    hasIOIsAccess,
} from '../../../utils/amr-pipeline.utils';
import TransactionContext from '../transactionContext';
import { PipelineDetailedTabTypes } from '../types/PipelineDetailedTabTypes';
import { useAppDispatch } from '../../../effects/useAppDispatch';
import { SubscribeLink } from '../../access/BlockedFeatureText';
import { RequiredFeature } from '../../access/RequiredFeature';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';

export const PipelineDetailedLimitedAccess = () => {
    const dispatch = useAppDispatch();
    const {
        transactions,
        isTransactionsRequesting,
        searchTerm,
        numberOfHiddenTransactions,
    } = useSelector<AppState, PipelineDetailedState>((state: AppState) => state.issuanceMonitor.amrPipelineDetailed);
    const { userCompany } = useSelector((state: AppState) => state.issuanceMonitor.amrPipelineCommon);
    const [selectionPanelTab, setSelectionPanelTab] = useState(PipelineDetailedTabTypes.all);

    const transactionsLoaded = transactions != null;

    const contextValue = useMemo(
        () => ({
            canView: canViewTransactionDetails(),
            limitedAccess: false,
            accessTracking: true,
            analyticsAccess: false,
            invitedClientsAccess: false,
            ioisAccess: hasIOIsAccess(undefined, userCompany),
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }),
        [userCompany],
    );

    React.useEffect(() => {
        dispatch(imUserConfigActions.getUserConfig());

        return () => {
            dispatch(amrPipelineDetailedActions.reset());
        };
    }, [dispatch]);

    React.useEffect(() => {
        if (!userCompany || transactionsLoaded || isTransactionsRequesting) {
            return;
        }

        dispatch(amrPipelineDetailedActions.loadTransactions());
    }, [dispatch, userCompany, transactionsLoaded, isTransactionsRequesting]);

    if (!transactions) {
        return null;
    }

    return (
        <TransactionContext.Provider value={contextValue}>
            <div className="container">
                <div className="container-flex container-sidebar">
                    <TransactionsSelectionPanel
                        initialTransaction={undefined}
                        isLoading={isTransactionsRequesting}
                        isSubmittingIoIs={false}
                        selectedClassReferenceName={undefined}
                        searchTerm={searchTerm}
                        transactions={transactions}
                        selectedTransaction={undefined}
                        hasMoreTransactions={false}
                        activeTab={selectionPanelTab}
                        onTabChange={setSelectionPanelTab}
                        numberOfHiddenTransactions={numberOfHiddenTransactions}
                    />
                    <div className="container-flex content-part-sidebar container-new-issue">
                        <RequiredFeature
                            inline
                            className="empty-placeholder width-a"
                            feature={SubscriptionFeature.IssuanceMonitorFullAccess}
                            text={
                                <>
                                    <SubscribeLink text="Subscribe to the Pro plan" />
                                    &nbsp;to see the deal details.
                                </>
                            }
                        >
                            &nbsp;
                        </RequiredFeature>
                    </div>
                </div>
            </div>
        </TransactionContext.Provider>
    );
};
