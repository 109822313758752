import { useSelector } from 'react-redux';
import IconSVG from '../../../../styles/svg-icons';
import { AlertOption } from '../../../../types/email-preferences/EmailPreferences';
import { AppState } from '../../../../types/state/AppState';
import { OnHoverTooltip } from '../../../common';
import classNames from 'classnames';
import { SummaryDailyAlertConfig, TransactionAlertConfig } from '../../../../types/user-config/UserConfig';
import { user } from '../../../../user';
import { SubscriptionFeature } from '../../../../types/billing/SubscriptionFeature';
import { Transaction } from '../../../../types/amr-pipeline/models/Transaction';
import { hasAnalyticsAlertsAccess } from '../../../../utils/amr-pipeline.utils';

interface Props {
    transaction: Transaction;
    withLabel?: boolean;
    disabled?: boolean;
    onClick?: () => void;
}

export const TransactionAlertsButton = ({
    transaction,
    withLabel,
    disabled,
    onClick,
}: Props) => {
    const transactionAlertConfig = useSelector<AppState, TransactionAlertConfig>(
        (state: AppState) => state.imUserConfig.transactionAlertConfig,
    );
    const analyticAlertConfig = useSelector<AppState, SummaryDailyAlertConfig>(
        (state: AppState) => state.imUserConfig.summaryDailyAlertConfig,
    );
    const userCompany = useSelector((state: AppState) => state.issuanceMonitor.amrPipelineCommon.userCompany);
    
    const { referenceName } = transaction;

    const withAnalyticsAlertsAccess = hasAnalyticsAlertsAccess(transaction, userCompany);


    const hasIMFullAccess = user.hasFeatures(SubscriptionFeature.IssuanceMonitorFullAccess);

    const selectedDealUpdatesOption =
        (hasIMFullAccess &&
            transactionAlertConfig.value.find(
                (c) => c.transactionReferenceName === referenceName
            )?.alertOption) || AlertOption.Never;
    const selectedSummaryDailyOption =
        (hasIMFullAccess &&
            analyticAlertConfig.value.find(
                (c) => c.transactionReferenceName === referenceName
            )?.alertOption) || AlertOption.Never;

    const isAlertOff = withAnalyticsAlertsAccess
        ? selectedDealUpdatesOption === AlertOption.Never && selectedSummaryDailyOption === AlertOption.Never
        : selectedDealUpdatesOption === AlertOption.Never;

    return (
        <div className="email-alert-select">
            {withLabel ? (
                <>
                    <span
                        className={classNames('email-alert-btn transition', {
                            disabled: disabled,
                            'alert-off': isAlertOff,
                        })}
                        onClick={onClick}
                    >
                        <IconSVG
                            name={isAlertOff ? 'alert-off' : 'alert'}
                            width="16"
                            height="16"
                            className="email-alert-icon"
                        />
                        {withAnalyticsAlertsAccess ? (
                            <div className="email-alert-label-info">
                                <span>Transaction Update:</span>
                                <span> {selectedDealUpdatesOption}</span> /<span> Analytics:</span>
                                <span> {selectedSummaryDailyOption}</span>
                            </div>
                        ) : (
                            <span className="email-alert-label-info">
                                <span>{selectedDealUpdatesOption}</span>
                            </span>
                        )}
                    </span>
                </>
            ) : (
                <OnHoverTooltip overlay="Transaction Alerts">
                    <button className="btn-link" disabled={disabled} onClick={onClick}>
                        <IconSVG
                            name={isAlertOff ? 'alert-off' : 'alert'}
                            width="16"
                            height="16"
                            className="email-alert-icon"
                        />
                    </button>
                </OnHoverTooltip>
            )}
        </div>
    );
};
