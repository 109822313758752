import React from 'react';
import classNames from 'classnames';

export function TabBody({ children, className = '' }) {
    return (
        <div className={classNames('tabs-data', className)}>
            {children}
        </div>
    );
}
