import * as React from 'react';
import { TabBody } from "../../../common/tabs/TabBody";
import { AmrTrancheStatus } from '../../../../../types/amr-pipeline/enums/AmrTrancheStatus';
import { Table } from "../../../../bidding/common/table";
import { EmptySectionContent } from '../../../common/section/EmptySectionContent';
import { auctionsColumns } from './auctions-column';
import { AmrClass } from '../../../../../types/amr-pipeline/models/AmrClass';
import { Preloader } from '../../../../common';
import { SORT } from '../../../../../constants';

interface AuctionsProps {
    amrClass: AmrClass,
    isAuctionServiceProvider: boolean;
}

export function Auctions({ amrClass, isAuctionServiceProvider }: AuctionsProps) {
    if (!amrClass.auctions) {
        return <Preloader inProgress={true} />;
    }

    return (
        <TabBody className="tabs-data-padding component-tabs-deal-auctions">
            {amrClass.auctions.length
                ?
                    <Table
                        className="data-list-striped"
                        defaultSortBy="pricingDate"
                        defaultSortByDirection={SORT.DESC}
                        dataItems={amrClass.auctions.filter(
                            (a) => a.status !== AmrTrancheStatus.Initiated
                        )}
                        columns={auctionsColumns()}
                        createSecurityCustomArgs={() => ({
                            actualAuction: amrClass.actualAuction,
                            isAuctionServiceProvider,
                        })}
                    />
                : <EmptySectionContent text="There no auctions yet"/>
            }
        </TabBody>
    );
}
