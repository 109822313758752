import { ComponentType, useContext, useEffect } from 'react';
import { amrPipelineDetailedActions } from '../../../../actions';
import { TransactionAccessType } from '../../../../types/amr-pipeline/enums/TransactionAccessType';
import TransactionContext from '../../transactionContext';
import { TransactionTabProps } from './types';
import { useAppDispatch } from '../../../../effects/useAppDispatch';

export const withTabTracking = (tabAccessType: TransactionAccessType) => <T extends TransactionTabProps,>(Component: ComponentType<T>) => (props: T) => {
    const { accessTracking } = useContext(TransactionContext);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!accessTracking) {
            return;
        }

        const { dealReferenceName, referenceName } = props.transaction;
        dispatch(amrPipelineDetailedActions.logTabAccess(dealReferenceName, referenceName, tabAccessType));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Component {...props} />;
};
