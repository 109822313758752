import { useDispatch, useSelector } from 'react-redux';
import { OriginatingTransaction } from '../../../../../types/amr-pipeline/models/OriginatingTransaction';
import { AppState } from '../../../../../types/state/AppState';
import { dateRangeFilterOptions } from '../../../../../constants/date-range.filter';
import { useEffect } from 'react';
import { TreeSelectOption } from '../../../../controls/TreeSelect';
import { TransactionAnalyticsFilter } from '../../../../../types/analytics/AnalyticsFilter';
import { Action } from 'typesafe-actions';
import { AnalyticWidgetState } from '../../../../../types/state/Analytics';

interface AnalyticsComponentProps {
    transaction: OriginatingTransaction;
}

interface BaseComponentProps extends AnalyticsComponentProps {
    isLoaded: boolean;
    users: TreeSelectOption<string>[];
}

export const withAnalyticsRequest = (
    getAction: (dealReferenceName: string, transactionReferenceName: string, filter: TransactionAnalyticsFilter) => Action,
    selector: (state: AppState) => AnalyticWidgetState<unknown, TransactionAnalyticsFilter>
) =>
    (Component: React.ComponentType<BaseComponentProps>) =>
    ({ transaction }: AnalyticsComponentProps) => {
        const dispatch = useDispatch();
        const { filter, data, isLoading } = useSelector(selector);

        const { dealReferenceName, referenceName: transactionReferenceName } = transaction;

        const { isLoading: initialLoading, users } = useSelector(
            (state: AppState) => state.issuanceMonitor.amrPipelineDetailed.analytics,
        );

        const isDataLoaded = !!data.length && !isLoading;

        useEffect(() => {
            // Do not reload data, if Custom date option is set, and any of both
            // dates is not selected
            if (
                filter.dateOption?.key === dateRangeFilterOptions.Custom.key &&
                (!filter.date.dateFrom || !filter.date.dateTo)
            ) {
                return;
            }

            // Do not make request on initial load of Analytics tab
            if (initialLoading) {
                return;
            }

            dispatch(getAction(dealReferenceName, transactionReferenceName, filter));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [
            dispatch,
            initialLoading,
            filter.dateOption?.key,
            dealReferenceName,
            transactionReferenceName,
            filter.date.dateFrom,
            filter.date.dateTo,
        ]);

        return <Component transaction={transaction} isLoaded={isDataLoaded} users={users} />;
    };
