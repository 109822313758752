import { startCase } from "lodash";
import moment from 'moment';
import { Link } from 'react-router-dom';
import { constants, roles, routes } from '../../../../../constants';
import { collateralTypes } from '../../../../../constants/collateral-types';
import { AmrTransaction } from '../../../../../types/amr-pipeline/models/AmrTransaction';
import { Deal } from '../../../../../types/amr-pipeline/models/Deal';
import { formatUtils, moneyUtils, numericUtils } from '../../../../../utils';
import { FormField } from '../../../common/FormField';
import { SectionContent } from '../../../common/section/SectionContent';
import { TabBody } from '../../../common/tabs/TabBody';
import { renderMarkdownOrEmpty } from '../../../../common/markdown-editor';
import IconSVG from '../../../../../styles/svg-icons';
import StatusLabel from "../../../StatusLabel";
import { ManagerLink } from '../../../common/ManagerLink';
import { ImSubscriptionActionBlocker } from "../../../subscription/ImSubscriptionActionBlocker";
import { OnHoverTooltip } from "../../../../common";
import { ShowFor } from "../../../../access";

interface DetailsProps {
    transaction: AmrTransaction,
    deal: Deal
}

export function Details({ transaction, deal }: DetailsProps) {
    const dateOrDefault = (date?: Date): string => date ? moment(date).format(constants.dateFormat) : constants.emptyPlaceholder;

    const renderTrusteeField = () => {
        if (!deal.trustee) {
            return constants.emptyPlaceholder;
        }

        return (
            <div className="name-with-link">
                <div className="text-ellipsis name-box">
                    <OnHoverTooltip overlay={deal.trustee ? deal.trustee.legalName : null}>
                        {deal.trustee.legalName}
                    </OnHoverTooltip>
                </div>

                <ImSubscriptionActionBlocker>
                    {blocked =>
                        blocked
                            ? <button className="btn-link" disabled>
                                <IconSVG name="go-to" width={16} height={16} />
                              </button>
                            : (
                                <OnHoverTooltip overlay="All Trustee’s Transactions">
                                    <Link
                                        target="_blank"
                                        to={`${routes.AMRPipeline}/?trustees=${deal.trustee?.referenceName}`}
                                        className="btn-link"
                                    >
                                        <IconSVG name="go-to" width={16} height={16} />
                                    </Link>
                                </OnHoverTooltip>
                            )
                    }
                </ImSubscriptionActionBlocker>
            </div>
        );
    };

    const renderCollateralManagerField = () => {
        if (!transaction.collateralManager) {
            return constants.emptyPlaceholder;
        }

        return (
            <div className="name-with-link">
                <div className="text-ellipsis name-box">
                    <ManagerLink
                        referenceName={transaction.collateralManager.referenceName}
                        legalName={transaction.collateralManager.legalName}
                    />
                </div>
                <ShowFor roles={[...roles.seller(), ...roles.bd(), roles.Administrator, roles.DataEntry, roles.Media]}>
                    <ImSubscriptionActionBlocker>
                        {blocked =>
                            blocked ? (
                                <button className="btn-link" disabled>
                                    <IconSVG name="go-to" width={16} height={16} />
                                </button>
                            ) : (
                                <OnHoverTooltip overlay="All Manager’s Transactions">
                                    <Link
                                        target="_blank"
                                        to={`${routes.AMRPipeline}/?collateralManagers=${transaction.collateralManager.referenceName}`}
                                        className="btn-link"
                                    >
                                        <IconSVG name="go-to" width={16} height={16} />
                                    </Link>
                                </OnHoverTooltip>
                            )
                        }
                    </ImSubscriptionActionBlocker>
                </ShowFor>
            </div>
        );
    };

    return (
        <TabBody className="tabs-data-padding component-tabs-deal-details">
            <SectionContent
                title="Information"
                className="data-item-general-information data-item-general-cols"
            >
                <div className="data-item-row-col">
                    <FormField title="Deal Name" value={transaction.dealLegalName} className="data-item-row-full-height" />
                    <FormField title="Ticker" value={transaction.dealTicker || constants.emptyPlaceholder} className="data-item-row-full-height" />
                    <FormField
                        title="Trans. Type & Status"
                        value={
                            <>
                                <span className="transaction-type-label">{startCase(transaction.type)}</span>
                                <StatusLabel status={transaction.status} />
                            </>
                        }
                        className="data-item-row-full-height"
                    />
                    <FormField title="Currency" value={transaction.currency ? transaction.currency.name : constants.emptyPlaceholder} className="data-item-row-full-height" />
                    <FormField title="Country" value={transaction.country ? transaction.country.name : constants.emptyPlaceholder} className="data-item-row-full-height" />
                    <FormField title="Collateral Type" value={transaction.collateralType ? collateralTypes[transaction.collateralType] : constants.emptyPlaceholder} className="data-item-row-full-height" />
                    <FormField title="Original Balance" value={moneyUtils.money(transaction.dealBalance) || constants.emptyPlaceholder} className="data-item-row-full-height" />
                    <FormField title="Current Balance" value={moneyUtils.money(transaction.dealBalance) || constants.emptyPlaceholder} className="data-item-row-full-height" />
                </div>
                <div className="data-item-row-col">
                    <FormField
                        title="Issuer"
                        tooltip={
                            deal.issuer
                                ? deal.issuer.legalName
                                : constants.emptyPlaceholder
                        }
                        className="data-item-row-full-height"
                    >
                        {
                            deal.issuer
                                ? deal.issuer.legalName
                                : constants.emptyPlaceholder
                        }
                    </FormField>
                    <FormField
                        title="Co-Issuer"
                        tooltip={
                            deal.coIssuer
                                ? deal.coIssuer.legalName
                                : constants.emptyPlaceholder
                        }
                        className="data-item-row-full-height"
                    >
                        {
                            deal.coIssuer
                                ? deal.coIssuer.legalName
                                : constants.emptyPlaceholder
                        }
                    </FormField>
                    <FormField title="Collateral Manager" className="data-item-row-full-height">{renderCollateralManagerField()}</FormField>
                    <FormField
                        title="Trustee"
                        className="data-item-row-full-height"
                    >
                        {renderTrusteeField()}
                    </FormField>
                    <FormField
                        title="AMR Agent"
                        tooltip={
                            deal.amrAgent
                                ? deal.amrAgent.legalName
                                : constants.emptyPlaceholder
                        }
                        className="data-item-row-full-height"
                    >
                        {
                            deal.amrAgent
                                ? deal.amrAgent.legalName
                                : constants.emptyPlaceholder
                        }
                    </FormField>
                    <FormField
                        title="Majority Equity"
                        tooltip={
                            deal.majorityEquity
                                ? deal.majorityEquity.legalName
                                : constants.emptyPlaceholder
                        }
                        className="data-item-row-full-height"
                    >
                        {
                            deal.majorityEquity
                                ? deal.majorityEquity.legalName
                                : constants.emptyPlaceholder
                        }
                    </FormField>
                </div>

            </SectionContent>

            <SectionContent
                title="Key Dates / Target Dates"
                className="data-item-general-information data-item-general-cols"
            >
                <div className="data-item-row-col">
                    <FormField title="Closing Date" value={dateOrDefault(transaction.closingDate)} className="data-item-row-full-height" />
                    <FormField title="First Payment Date" value={dateOrDefault(transaction.firstPaymentDate)} className="data-item-row-full-height" />
                    <FormField title="Second Payment Date" value={dateOrDefault(transaction.secondPaymentDate)} className="data-item-row-full-height" />
                    <FormField title="Quarterly" value={transaction.quarterlyDay ? `each ${numericUtils.toOrdinal(transaction.quarterlyDay)} for ${transaction.quarterlyMonths}` : constants.emptyPlaceholder} className="data-item-row-full-height" />
                </div>
                <div className="data-item-row-col">
                    <FormField title="Non-call End" value={dateOrDefault(transaction.nonCallPeriodEnd)} className="data-item-row-full-height" />
                    <FormField title="Reinvestment End" value={dateOrDefault(transaction.reinvestmentPeriodEnd)} className="data-item-row-full-height" />
                    <FormField title="Last Refinancing" value={dateOrDefault(transaction.lastRefinancingDate)} className="data-item-row-full-height" />
                    <FormField title="Maturity" value={dateOrDefault(transaction.finalMaturity)} className="data-item-row-full-height" />
                </div>
            </SectionContent>

            <SectionContent title="Additional information" className="data-item-payment-dates">
                <FormField
                    title="EU Compliance"
                    className="text-capitalize data-item-row-full-height"
                    value={formatUtils.formatBoolean(transaction.euCompliance)}
                />
                <FormField
                    title="EU Risk Retention Requirements"
                    className="data-item-row-full-height"
                >
                    {renderMarkdownOrEmpty(transaction.euRiskRetentionRequirements)}
                </FormField>
                <FormField
                    className="data-item-row-full-height"
                    title="EU Disclosure Requirements"
                >
                    {renderMarkdownOrEmpty(transaction.euDisclosureRequirements)}
                </FormField>
                <FormField title="ESG" className="text-capitalize data-item-row-full-height" value={formatUtils.formatBoolean(transaction.esg)} />
                <FormField
                    title="ESG Comment"
                    className="data-item-row-full-height"
                >
                    {renderMarkdownOrEmpty(transaction.esgComment)}
                </FormField>
                {/* Temporary Enhanced CLO hiding */}
                {/* <FormField title="Enhanced CLO" className="text-capitalize" value={formatUtils.formatBoolean(transaction.enhancedCLO)} /> */}
                <FormField title="Static Deal" className="text-capitalize data-item-row-full-height" value={formatUtils.formatBoolean(transaction.staticDeal)} />
            </SectionContent>
        </TabBody>
    );
}
