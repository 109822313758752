import { constants } from "../../../../../../../constants";
import { Contact } from "../../../../../../../types/amr-pipeline/models/Contact";
import { ColumnBuilder } from "../../../../../../bidding/common/table/columns/column-builder/ColumnBuilder";
import { IColumnDefinition } from "../../../../../../bidding/common/table/types/ColumnDefinition";
import { OnHoverTooltip } from '../../../../../../common';
import IconSVG from '../../../../../../../styles/svg-icons';
import { nameUtils } from "../../../../../../../utils";

const memberColumnsDefinitions: IColumnDefinition<Contact>[] = [
    {
        columnKey: 'fullName',
        renderColumnHeaderContent: () => 'Full Name',
        renderColumnContent: ({ firstName, lastName }) => {
            const fullName = nameUtils.getFullName(firstName, lastName);

            return (
                <div className="overflow-ellipsis">
                    <OnHoverTooltip overlay={fullName} disabled={!(firstName || lastName)}>
                        <span>{fullName}</span>
                    </OnHoverTooltip>
                </div>
            );
        },
        headerClassName: 'data-list-cell-xl',
        bodyClassName: 'data-list-cell-xl',
    },
    {
        columnKey: 'iconContact',
        renderColumnHeaderContent: () => null,
        renderColumnContent: (member, { primaryContact }) =>
            <>
                {member.email === primaryContact.email && (
                    <OnHoverTooltip overlay="Primary contact">
                        <IconSVG name="primary-contact" width={16} height={16} />
                    </OnHoverTooltip>
                )}
            </>,
        headerClassName: 'data-list-cell-xxs padding-l-0 text-center',
        bodyClassName: 'data-list-cell-xxs padding-l-0 text-center',
    },
    {
        columnKey: 'email',
        renderColumnHeaderContent: () => 'Email',
        renderColumnContent: member => (
            <div className="overflow-ellipsis">
                <OnHoverTooltip overlay={member.email}>
                    <a href={`mailto:${member.email}`}>
                        {member.email}
                    </a>
                </OnHoverTooltip>
            </div>
        ),
        headerClassName: 'data-list-cell-xl-xxl',
        bodyClassName: 'data-list-cell-xl-xxl',
    },
    {
        columnKey: 'phone',
        renderColumnHeaderContent: () => 'Phone',
        renderColumnContent: member => member.mobilePhoneNumber || constants.emptyPlaceholder,
        headerClassName: 'data-list-cell-md',
        bodyClassName: 'data-list-cell-md',
    },
];

export const memberColumns = memberColumnsDefinitions.map(c => new ColumnBuilder(c));
