import { ClassIndicators } from '../../../types/amr-pipeline/models/ClassIndicators';
import { routes } from '../../../constants';
import { SecurityBwicLink } from './SecurityBwicLink';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { Currency } from '../../../types/amr-pipeline/models/Currency';

type Props = {
    identifier?: string;
    identifiers?: ClassIndicators;
    className?: string;
    disabled?: boolean;
    secondaryPlaceholderColor?: boolean;
    currency?: Currency;
};

export function BwicInventoryLink({ identifiers, identifier, currency, className, secondaryPlaceholderColor }: Props) {
    return (
        <SecurityBwicLink
            identifier={identifier}
            identifiers={identifiers}
            className={className}
            currency={currency}
            iconName="inventory"
            tooltipText="View Dealer Inventory"
            placeholderIfEmpty
            route={routes.inventory}
            secondaryPlaceholderColor={secondaryPlaceholderColor}
            requiredFeatures={[SubscriptionFeature.InventoryHistoricalData]}
        />
    );
}
