import { IColumnDefinition } from '../../../../bidding/common/table/types/ColumnDefinition';
import { ColumnBuilder } from '../../../../bidding/common/table/columns/column-builder/ColumnBuilder';
import { constants, SORTING_TYPE } from '../../../../../constants';
import { IOI } from '../../../../../types/amr-pipeline/models/IOI';
import { amrFormatUtils, formatUtils, moneyUtils } from '../../../../../utils';
import { OnHoverTooltip } from '../../../../common';
import moment from 'moment';
import { OriginatingTransactionClass } from '../../../../../types/amr-pipeline/models/OriginatingTransactionClass';

const className: IColumnDefinition<IOI> = {
    columnKey: 'className',
    renderColumnHeaderContent: () => 'Class',
    renderColumnContent: (ioi, context: {classes: OriginatingTransactionClass[]}) => {
        const classStatus = amrFormatUtils.formatClassStatus(
            context.classes.find(c => c.referenceName === ioi.classReferenceName)?.originatingTransactionClassStatus,
        );

        return ioi.isDeleted
            ? <OnHoverTooltip overlay="Class removed">{ioi.classReferenceName} {classStatus}</OnHoverTooltip>
            : `${ioi.classReferenceName} ${classStatus ? classStatus : ''}`
    },
    bodyClassName: 'data-list-cell-ioi-class',
    sortingField: 'classReferenceName',
    sortingType: SORTING_TYPE.string,
};

const balance: IColumnDefinition<IOI> = {
    columnKey: 'balance',
    renderColumnHeaderContent: () => 'Balance',
    renderColumnContent: ioi => (ioi.balance ? moneyUtils.money(ioi.balance) : constants.emptyPlaceholder),
    headerClassName: 'data-list-cell-sm text-right',
    bodyClassName: 'data-list-cell-sm text-right',
    sortingField: 'balance',
    sortingType: SORTING_TYPE.number,
};

const ioiSize: IColumnDefinition<IOI> = {
    columnKey: 'size',
    renderColumnHeaderContent: () => 'IOI Size',
    renderColumnContent: ioi => (ioi.size ? moneyUtils.money(ioi.size) : constants.emptyPlaceholder),
    headerClassName: 'data-list-cell-sm text-right',
    bodyClassName: 'data-list-cell-sm text-right',
    sortingField: 'size',
    sortingType: SORTING_TYPE.number,
};

const ioiSizePercent: IColumnDefinition<IOI> = {
    columnKey: 'sizePercent',
    renderColumnHeaderContent: () => 'IOI Size, %',
    renderColumnContent: ioi =>
        ioi.sizePercent ? formatUtils.formatDecimal(ioi.sizePercent, 2) : constants.emptyPlaceholder,
    headerClassName: 'data-list-cell-sm text-right',
    bodyClassName: 'data-list-cell-sm text-right',
    sortingField: 'sizePercent',
    sortingType: SORTING_TYPE.number,
};

const commonColumns: IColumnDefinition<IOI>[] = [
    {
        columnKey: 'dm',
        renderColumnHeaderContent: () => 'DM, bps',
        renderColumnContent: ioi => ioi.dm ?? constants.emptyPlaceholder,
        headerClassName: 'data-list-cell text-right',
        bodyClassName: 'data-list-cell text-right',
        sortingField: 'dm',
        sortingType: SORTING_TYPE.number,
    },
    {
        columnKey: 'oid',
        renderColumnHeaderContent: () => 'OID',
        renderColumnContent: ioi => (ioi.oid ? formatUtils.formatDecimal(ioi.oid, 4) : constants.emptyPlaceholder),
        headerClassName: 'data-list-cell text-right',
        bodyClassName: 'data-list-cell text-right',
        sortingField: 'oid',
        sortingType: SORTING_TYPE.number,
    },
    {
        columnKey: 'floaterIndex',
        renderColumnHeaderContent: () => 'Floater Index',
        renderColumnContent: ioi => ioi.floaterIndex,
        headerClassName: 'data-list-cell-sm',
        bodyClassName: 'data-list-cell-sm',
        sortingField: 'floaterIndex',
        sortingType: SORTING_TYPE.string,
    },
    {
        columnKey: 'margin',
        renderColumnHeaderContent: () => 'Margin, %',
        renderColumnContent: ioi => (ioi.margin ? formatUtils.formatDecimal(ioi.margin) : constants.emptyPlaceholder),
        headerClassName: 'data-list-cell-sm text-right',
        bodyClassName: 'data-list-cell-sm text-right',
        sortingField: 'margin',
        sortingType: SORTING_TYPE.number,
    },
    {
        columnKey: 'comment',
        renderColumnHeaderContent: () => 'Comment',
        renderColumnContent: ioi => {
            return (
                <>
                    {ioi.comment ? (
                        <OnHoverTooltip overlay={ioi.comment}>{ioi.comment}</OnHoverTooltip>
                    ) : (
                        constants.emptyPlaceholder
                    )}
                </>
            );
        },
        headerClassName: 'data-list-cell-lg',
        bodyClassName: 'data-list-cell-lg',
    },
    {
        columnKey: 'dateSubmitted',
        renderColumnHeaderContent: () => 'Date Submitted',
        renderColumnContent: ioi =>
            ioi.submitDate ? moment(ioi.submitDate).format(constants.dateTimeFormat) : constants.emptyPlaceholder,
        headerClassName: 'text-right data-list-cell-lg',
        bodyClassName: 'text-right data-list-cell-lg',
        sortingField: 'submitDate',
        sortingType: SORTING_TYPE.date,
    },
    balance,
    {
        columnKey: 'rating',
        renderColumnHeaderContent: () => 'Rtg (M/S/F/K/D)',
        renderColumnContent: ioi =>
            `${ioi.ratingMoodys || constants.emptyPlaceholder}
                /${ioi.ratingSnP || constants.emptyPlaceholder}
                /${ioi.ratingFitch || constants.emptyPlaceholder}
                /${ioi.ratingKbra || constants.emptyPlaceholder}
                /${ioi.ratingDbrs || constants.emptyPlaceholder}`,
        headerClassName: 'data-list-cell-xl',
        bodyClassName: 'data-list-cell-xl',
        sortingField: 'rating',
        sortingType: SORTING_TYPE.rating,
    },
    {
        columnKey: 'guidance',
        renderColumnHeaderContent: () => 'Guidance',
        renderColumnContent: ioi => ioi.guidance
            ? <OnHoverTooltip overlay={ioi.guidance}>{ioi.guidance}</OnHoverTooltip>
            : constants.emptyPlaceholder,
        headerClassName: 'data-list-cell-sm',
        bodyClassName: 'data-list-cell-sm',
    },
    {
        columnKey: 'subscription',
        renderColumnHeaderContent: () => 'Subscription',
        renderColumnContent: ioi => ioi.subscription
            ? <OnHoverTooltip overlay={ioi.subscription}>{ioi.subscription}</OnHoverTooltip>
            : constants.emptyPlaceholder,
        headerClassName: 'data-list-cell-sm',
        bodyClassName: 'data-list-cell-sm',
    },
];

export const investorColumns = () => {
    const ioiColumnsDefinition: IColumnDefinition<IOI>[] = [className, ioiSize, ...commonColumns];
    return ioiColumnsDefinition.map(c => {
        return new ColumnBuilder(c);
    });
};

export const arrangerColumns = () => {
    const ioiColumnsDefinition: IColumnDefinition<IOI>[] = [
        className,
        {
            columnKey: 'investor',
            renderColumnHeaderContent: () => 'Investor',
            renderColumnContent: ioi => {
                return ioi.investor ? (
                    <OnHoverTooltip overlay={ioi.investor.legalName}>
                        <span>{ioi.investor.legalName}</span>
                    </OnHoverTooltip>
                ) : (
                    constants.emptyPlaceholder
                );
            },
            headerClassName: 'data-list-cell-xl',
            bodyClassName: 'data-list-cell-xl',
            sortingField: 'investor',
            sortingType: SORTING_TYPE.company,
        },
        ioiSize,
        ioiSizePercent,
        balance,
        ...commonColumns.filter(c => c.columnKey !== balance.columnKey),
    ];
    return ioiColumnsDefinition.map(c => {
        return new ColumnBuilder(c);
    });
};
