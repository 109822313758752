import { amrPipelineDetailedActions } from "../../../../../actions";
import IconSVG from "../../../../../styles/svg-icons";
import { OriginatingTransactionPortfolioItem } from "../../../../../types/amr-pipeline/models/OriginatingTransactionPortfolioItem";
import { TransactionAccessType } from '../../../../../types/amr-pipeline/enums/TransactionAccessType';
import { Table } from "../../../../bidding/common/table";
import { ColumnBuilder } from "../../../../bidding/common/table/columns/column-builder/ColumnBuilder";
import { Preloader, EmptyPlaceholder } from "../../../../common";
import { SectionContent } from "../../../common/section/SectionContent";
import { TabBody } from "../../../common/tabs/TabBody";
import { TableDifferenceLabel } from '../DifferenceLabel';
import { TransactionTabProps } from '../types';
import { withTabTracking } from '../withTabTracking';
import { targetPortfolioColumns } from "./portfolio-columns";
import { TotalRow } from "./TotalRow";
import { RequiredFeature } from '../../../../access/RequiredFeature';
import { SubscriptionFeature } from '../../../../../types/billing/SubscriptionFeature';
import { hasArrangerAccess } from '../../../../../utils/amr-pipeline.utils';
import { BlockedFeatureContent, SubscribeLink } from "../../../../../components/access/BlockedFeatureText";
import { useAppDispatch } from "../../../../../effects/useAppDispatch";

interface TargetPortfolioTabProps extends TransactionTabProps {
    isLoading: boolean;
}

const TargetPortfolioTab = ({ transaction, isLoading, difference }: TargetPortfolioTabProps) => {
    const dispatch = useAppDispatch();

    return (
        <TabBody className="tabs-data-padding component-tabs-target-portfolio">
            <RequiredFeature
                feature={SubscriptionFeature.IssuanceMonitorFullAccess}
                overrideRequiredFeatures={hasArrangerAccess(transaction)}
                renderBlockedContent={() => (
                    <BlockedFeatureContent
                        inline
                        className="empty-placeholder"
                        text={<><SubscribeLink /> to see Target Portfolio.</>}
                    />
                )}
            >
                {isLoading && <Preloader inProgress={true} />}
                <SectionContent
                    title=""
                    actionBtn={
                        <div className="flex-row">
                            <TableDifferenceLabel difference={difference?.portfolio} prefix="Portfolio" />
                            {
                                (transaction.portfolio?.length && !difference)
                                ?   <button
                                        onClick={() =>{
                                            dispatch(amrPipelineDetailedActions.exportPortfolio(transaction.dealReferenceName, transaction.referenceName, transaction.dealLegalName))
                                        }}
                                        className="btn-link flex-item-right"
                                    >
                                        <IconSVG name="export" width="16" height="16" />
                                        <span className="align-middle">Export</span>
                                    </button>
                                : null
                            }
                        </div>
                    }
                >
                    {transaction.portfolio?.length ?
                        <>
                            <Table
                                className="data-list-striped"
                                dataItems={transaction.portfolio}
                                columns={targetPortfolioColumns()}
                                renderFooterRow={(columns: ColumnBuilder<OriginatingTransactionPortfolioItem>[]) => (
                                    <TotalRow
                                        portfolio={
                                            transaction.portfolio
                                        }
                                        columns={columns}
                                    />
                                )}
                            />
                        </>
                        : <EmptyPlaceholder text="There is no portfolio to display."/>
                    }
                </SectionContent>
            </RequiredFeature>
        </TabBody>
    );
}

export const TargetPortfolio = withTabTracking(TransactionAccessType.TargetPortfolio)(TargetPortfolioTab);
