import React from 'react';
import classNames from 'classnames';

interface Props {
    actionBtn?: React.ReactNode
    title?: React.ReactNode;
    className?: string
    renderActions?: () => React.ReactNode;
    children: React.ReactNode
}

export function SectionContent({ actionBtn = null, title, renderActions = undefined, children, className=''} : Props) {
    return (
        <div className={classNames("data-item", className)}>
            <div className={classNames("data-item-head", { "data-item-head-without-title": !title })}>
                {title &&
                    <h2 className="data-item-title">{title}</h2>
                }
                {actionBtn}
            </div>
            <div className="data-item-content">
                {children}
                {
                    typeof renderActions === 'function' &&
                    <div className="flex-item-right">{renderActions()}</div>
                }
            </div>
        </div>
    );
}

