import { compareStrings } from '../../../../utils/compare.utils';
import {
    Difference,
    DifferenceType,
    ObjectArrayDifference,
    ObjectArrayItemDifference,
    PrimitiveDifference
} from '../../../../utils/differ/types';
import { IndicatorItems, ITableSection, ItemIndicators, TableIndicator, TableRenderMode } from './types';
import { indicatorMatchesSearchTerm } from './utils';
import { TableSectionWrapper } from './TableSectionWrapper';
import Highlighter from '../../common/Highlighter';

/**
 * This section renders changes in item-indicators view.
 * Item-indicators view presents it in a way that Item A has changes in indicators B, C and D.
 */
export class TableItemIndicatorsSection<T, K extends unknown> implements ITableSection<T> {
    constructor(
        public title: string,
        public entityTitle: string,
        protected getItemsDifference: (difference: Difference<T>) => ObjectArrayDifference<K[]> | undefined,
        protected getEntityDifference: (itemDifference: Difference<K>) => PrimitiveDifference<unknown> | undefined,
        public indicators: TableIndicator<T, K>[],
        protected entityTitleFormatter?: (value: unknown) => string,
    ) {
        this.isMatchSearchTerm = this.isMatchSearchTerm.bind(this)
    }

    hasChanges(difference: Difference<T>) {
        const itemsDifference = this.getItemsDifference(difference);

        const entityHasChanges = !!itemsDifference?.reduce((acc: boolean, item) => {
            // Preserve 'true' value
            if (acc) {
                return acc;
            }

            if (!item.difference) {
                return false;
            }

            const entityDifference = this.getEntityDifference(item.difference);

            return entityDifference?.type !== DifferenceType.Unchanged;
        }, false);

        return this.indicators.some(indicator => indicator.hasChanges(difference)) || entityHasChanges;
    }

    renderHeader(item: ObjectArrayItemDifference<K>, indicatorsWithChanges: TableIndicator<T, K>[]) {
        // Do not render `From To` header for added items
        if (item.type === DifferenceType.Added) {
            return null;
        }

        const itemDifferenceTypes = indicatorsWithChanges.map(x => x.diffTypeInItem(item));

        // Do not render `From To` header if no indicators were changed
        if (itemDifferenceTypes.every(x => x === DifferenceType.Unchanged)) {
            return null;
        }

        return (
            <div className="history-row history-row-header">
                <span></span>
                <span>From</span>
                <span>To</span>
            </div>
        );
    }

    renderItem(
        searchTerms: string[],
        item: ObjectArrayItemDifference<K>,
        indicators: TableIndicator<T, K>[],
        difference: Difference<T>,
    ) {
        const entityDifference = item.difference && this.getEntityDifference(item.difference);
        const isEntityRemoved = entityDifference?.type === DifferenceType.Removed;
        const entity = this.entityTitleFormatter
            ? this.entityTitleFormatter(entityDifference?.derivedValue)
            : entityDifference?.derivedValue;

        const itemEntityWithTitleMatchSearchTerm = searchTerms.some(s =>
            `${this.entityTitle} ${entity}`.toLowerCase().includes(s.toLowerCase()),
        );

        const indicatorsWithChanges = itemEntityWithTitleMatchSearchTerm ? indicators : indicators.filter(indicatorMatchesSearchTerm(searchTerms, difference));

        return (
            <li key={`${item.id}${item.type}`}>
                <Highlighter
                    searchWords={searchTerms}
                    autoEscape={true}
                    textToHighlight={`${this.entityTitle} ${entity} ${item.type}`}
                    className="title"
                />
                {!isEntityRemoved && (
                    <div className="history-table">
                        {this.renderHeader(item, indicatorsWithChanges)}
                        {indicatorsWithChanges.map(x => (
                            <div className="history-row">
                                {x.render(searchTerms, item, { withTitle: item.type !== DifferenceType.Removed, mode: TableRenderMode.ItemIndicator })}
                            </div>
                        ))}
                    </div>
                )}
            </li>
        );
    }

    getEntitySearchValue(title: string) {
        return this.entityTitleFormatter
            ? this.entityTitleFormatter(String(title))
            : `${this.entityTitle} ${String(title)}`;
    }

    renderItemsView(searchTerms: string[], itemIndicators: ItemIndicators<T, K>[], difference: Difference<T>) {
        return itemIndicators.map(({ item, indicators }) => this.renderItem(searchTerms, item, indicators, difference));
    }

    renderSection(
        searchTerms: string[] = [],
        difference: Difference<T>,
        excludedIndicatorItems: IndicatorItems<T, K>[] = [],
    ) {
        if (!this.hasChanges(difference)) {
            return null;
        }

        // Just to not do excess job in next steps
        if (
            searchTerms.some(s => !!s.length) &&
            !this.isMatchSearchTerm(searchTerms, difference) &&
            !this.indicators.some(indicatorMatchesSearchTerm(searchTerms, difference))
        ) {
            return null;
        }

        const itemsDifference = this.getItemsDifference(difference);

        if (!itemsDifference) {
            return null;
        }

        // Collect changed items (Items View)
        const itemIndicators = itemsDifference.reduce((acc: ItemIndicators<T, K>[], item) => {
            const searchValue = this.getEntitySearchValue(String(item.id));

            const searchTermsMatchTitle = searchTerms.some(s => searchValue.toLowerCase() === s.toLowerCase());

            // if search term matched for entity id, show only this entity
            if (
                searchTerms.some(s => !!s.length) &&
                !searchTermsMatchTitle &&
                !this.indicators.some(indicatorMatchesSearchTerm(searchTerms, difference, item))
            ) {
                return acc;
            }

            // Get indicators, which changed in current item
            const indicators = this.indicators.filter(x => x.hasChangesInItem(item));

            // No changed indicators — no changed items
            // Note that even for deleted items, its indicators will be also deleted
            // so this condition doesn't affect deleted items
            if (!indicators.length) {
                if ([DifferenceType.Removed, DifferenceType.Added].includes(item.type)) {
                    return [...acc, { item, indicators }];
                }

                return acc;
            }

            // Some indicators have already been collected in previous mode (Indicators View).
            // Thus in this case we should exclude them, but preserve others.
            // So first we collect all indicator items, which include the same item in them,
            // and then do a map to return only indicators themselve
            const indicatorItemsWithCurrentItem = excludedIndicatorItems
                .filter(({ items }) => items.some(i => i.id === item.id))
                .map(x => x.indicator);

            // Filter out previously found indicators, if they include any of
            // already used indicator in `Indicators View`
            // Matc by title, as there is no other unique identifier
            const filteredIndicators = indicators.filter(
                indicator => !indicatorItemsWithCurrentItem.some(x => x.title === indicator.title),
            );

            // If after this procedure nothing is left, return accumulator
            if (!filteredIndicators.length) {
                return acc;
            }

            return [...acc, { item, indicators: filteredIndicators }];
        }, []);

        // Pefrorm sorting by title/id of indicator/item, so present it more accurately
        // indicatorItems.sort((a, b) => compareStrings(a.indicator.title, b.indicator.title));
        itemIndicators.sort((a, b) => compareStrings(a.item.id, b.item.id));
        return this.renderItemsView(searchTerms, itemIndicators, difference);
    }

    isMatchSearchTerm(searchTerms: string[], difference: Difference<T>) {
        if (this.hasChanges(difference) && searchTerms.some(s => this.entityTitle.toLowerCase().includes(s.toLowerCase()))) {
            return true;
        }

        const itemsDifference = this.getItemsDifference(difference)?.filter(d => d.type !== DifferenceType.Unchanged);

        const isUpdatedItemMatchSearchTerm = !!itemsDifference?.filter(d => d.type === DifferenceType.Updated).reduce((acc: boolean, item) => {
            if (acc) {
                return acc
            }

            if (!item.difference) {
                return false;
            }

            return searchTerms.some(s =>
                this.getEntitySearchValue(String(item.id)).toLowerCase().includes(s.toLowerCase()),
            );
        }, false);

        const entityMatchSearchTerm = itemsDifference?.reduce((acc: boolean, item) => {
            // Preserve 'true' value
            if (acc) {
                return acc;
            }

            if (!item.difference) {
                return false;
            }

            const entityDifference = this.getEntityDifference(item.difference);
            const searchValue = this.getEntitySearchValue(String(entityDifference?.derivedValue));
            const isSearchTermMatchTitle = searchTerms.some(s => searchValue.toLowerCase() === s.toLowerCase());

            return entityDifference?.type !== DifferenceType.Unchanged && isSearchTermMatchTitle;
        }, false);

        return entityMatchSearchTerm || isUpdatedItemMatchSearchTerm;
    }

    render(searchTerms: string[] = [], difference: Difference<T>) {
        const section = this.renderSection(searchTerms, difference);

        if (!section) {
            return null;
        }

        return <TableSectionWrapper title={this.title}>{section}</TableSectionWrapper>;
    }
}
