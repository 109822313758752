import * as React from 'react';
import classNames from 'classnames';
import { AmrTrancheStatus } from '../../../types/amr-pipeline/enums/AmrTrancheStatus';
import { amrTrancheStatuses } from '../../../constants/amr.tranche.statuses';

interface AmrClassStatusLabelProps {
    status?: AmrTrancheStatus
}

export function AmrClassStatusLabel({ status }: AmrClassStatusLabelProps) {
    if (!status) {
        return null;
    }

    const statusText: string = amrTrancheStatuses.getStatusText(status);
    const classStatus = `label-status-${statusText.toLowerCase().replace(' ', '-')}`;

    return <span className={classNames(["label-status", classStatus])}>{statusText}</span>;
}
