import { roles, routes } from '../../../constants';
import { OnHoverTooltip } from '../../common';
import IconSVG from '../../../styles/svg-icons';
import { FeatureLink } from '../../access/FeatureLink';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { RoleAndFeatureBlocker } from '../../access/RoleAndFeatureBlocker';

interface Props {
    dealReferenceName: string;
    dealLegalName: string;
    children?: React.ReactNode;
    iconVisible?: boolean;
    requiredFeatures?: SubscriptionFeature[];
    tooltip?: string;
}

export function ImDealLookupLink({
    dealReferenceName,
    dealLegalName,
    tooltip = "Related Transactions",
    requiredFeatures = [SubscriptionFeature.IssuanceMonitorFullAccess],
    iconVisible = true,
    children }: Props) {
    return (
        <RoleAndFeatureBlocker
            requiredRoles={roles.issuanceMonitorAccess()}
            requiredFeatures={requiredFeatures}>
            {
                blocked =>
                    <OnHoverTooltip overlay={!blocked && tooltip}>
                        <FeatureLink
                            className="btn-link"
                            path={`${routes.AMRPipeline}?dealsReferenceNames=${dealReferenceName}&dealsLegalNames=${dealLegalName}`}
                            target="_blank"
                        >
                            {iconVisible && <IconSVG name="related-transaction" width={16} height={16} />}
                            <>{children}</>
                        </FeatureLink>
                    </OnHoverTooltip>
            }
        </RoleAndFeatureBlocker>
    );
}