import { Difference, DifferenceType, ObjectArrayDifference } from '../../../../utils/differ/types';
import { IIndicatorSection, Indicator } from './types';

/**
 * Represents and renders imprecise table indicators (without previous and current values)
 * Used to sshow that the whole section (array of any items) was just added, updated or removed
 * Template:
 * - Added:     [Section name] added
 * - Updated:   [Section name] updated
 * - Removed:   [Section name] removed
 */
export class ImpreciseTableIndicatorSection<T, K extends unknown> implements IIndicatorSection<T> {
    public indicators: Indicator<unknown>[] = [];
    constructor(
        public title: string,
        protected getItemsDifference: (difference: Difference<T>) => ObjectArrayDifference<K[]> | undefined
    ) {}

    hasChanges(difference: Difference<T>) {
        const items = this.getItemsDifference(difference);

        if (!items) {
            return false;
        }

        return items.some(item => item.type !== DifferenceType.Unchanged);
    }

    getDifferenceType(difference: Difference<T>) {
        const items = this.getItemsDifference(difference);

        if (items?.every(x => x.type === DifferenceType.Added)) {
            return DifferenceType.Added;
        }

        if (items?.every(x => x.type === DifferenceType.Removed)) {
            return DifferenceType.Removed;
        }

        return DifferenceType.Updated;
    }

    render(searchTerms: string[] = [], difference: Difference<T>) {
        if (!this.hasChanges(difference)) {
            return null;
        }

        const differenceType = this.getDifferenceType(difference);

        return (
            <div key={this.title} className="history-element">
                <h2>{this.title} <span className="text-regular"> {differenceType}</span></h2>
            </div>
        );
    }
}
