import React from "react";
import { dealStructureUtils } from "../../../../../../utils/deal-structure.utils";
import { constants } from "../../../../../../constants";
import { formatUtils, moneyUtils } from "../../../../../../utils";
import { OriginatingTransaction } from "../../../../../../types/amr-pipeline/models/OriginatingTransaction";
import { FormField } from "../../../../common/FormField";
import { SectionContent } from "../../../../common/section/SectionContent";
import { Difference } from '../../../../../../utils/differ/types';
import { DifferenceField } from '../../DifferenceField';
import { OriginatingTransactionDiff } from '../../../../../../types/amr-pipeline/models/OriginatingTransactionDiff';
import { ImSubscriptionValue } from '../../../../subscription/ImSubscriptionValue';

const formatToMoney = (val: number) => moneyUtils.money(val, true);
const formatToDecimal = (val: number) => formatUtils.formatDecimal(val);

interface Props {
    selectedTransaction: OriginatingTransaction;
    difference?: Difference<OriginatingTransactionDiff>;
}

export function CollateralAssumptions({ selectedTransaction, difference }: Props) {
    return (
        <SectionContent
            title="Collateral Assumptions"
            className="data-item-collateral-assumptions"
        >
            <FormField title="Target Par">
                <ImSubscriptionValue transaction={selectedTransaction}>
                    <DifferenceField
                        difference={difference?.calculatedTargetPar}
                        formatter={formatToMoney}
                    >
                        {dealStructureUtils.calcTargetPar(selectedTransaction)
                            ? formatToMoney(dealStructureUtils.calcTargetPar(selectedTransaction))
                            : constants.emptyPlaceholder
                        }
                    </DifferenceField>
                </ImSubscriptionValue>
            </FormField>
            <FormField title="Bid Price">
                <ImSubscriptionValue transaction={selectedTransaction}>
                    <DifferenceField
                        difference={difference?.calculatedBidPrice}
                        formatter={formatToMoney}
                    >
                        {dealStructureUtils.calcBidPrice(selectedTransaction)
                            ? formatToMoney(dealStructureUtils.calcBidPrice(selectedTransaction))
                            : constants.emptyPlaceholder
                        }
                    </DifferenceField>
                </ImSubscriptionValue>
            </FormField>
            <FormField title="Ask Price">
                <ImSubscriptionValue transaction={selectedTransaction}>
                    <DifferenceField
                        difference={difference?.calculatedAskPrice}
                        formatter={formatToMoney}
                    >
                        {dealStructureUtils.calcAskPrice(selectedTransaction)
                            ? formatToMoney(dealStructureUtils.calcAskPrice(selectedTransaction))
                            : constants.emptyPlaceholder
                        }
                    </DifferenceField>
                </ImSubscriptionValue>
            </FormField>
            <FormField title="WAS, %">
                <ImSubscriptionValue transaction={selectedTransaction}>
                    <DifferenceField
                        difference={difference?.was}
                        formatter={formatToDecimal}
                    >
                        {formatToDecimal(selectedTransaction.was) || constants.emptyPlaceholder}
                    </DifferenceField>
                </ImSubscriptionValue>
            </FormField>
        </SectionContent>
    );
}
