import { isEqual } from 'lodash';
import { analyticDefaultFilters } from '../../../../constants';
import { dateRangeFilterOptions } from '../../../../constants/date-range.filter';
import { DateFilterOption } from '../../../../types/filters/DateFilterOption';
import { DateRange } from '../../../../types/filters/DateRange';
import { FilterOption } from '../../../../types/filters/FilterOption';
import { FilterDateRange } from '../../../common/filters/FilterDateRange';
import { FilterSelect } from '../../../common/filters/FilterSelect';
import { TreeSelectOption, TreeSelect } from '../../../controls/TreeSelect';
import { FilterClear, FilterPanel, FilterSection } from '../../../filters';
import { AnalyticsFilterBase } from '../../../../types/analytics/AnalyticsFilter';
import { useMemo } from 'react';

interface AnalyticsFilterProps<T extends string> extends AnalyticsFilterBase<T> {
    data: TreeSelectOption<string>[];
    tabDefinitions: { text: string, value: T }[],
    onChange: (value: AnalyticsFilterBase<T>) => void;
    disabled?: boolean;
}

export function AnalyticsFilter<T  extends string, >({
    data,
    tabDefinitions,
    dateOption,
    onChange,
    disabled,
    ...filters
}: AnalyticsFilterProps<T>) {
    const isFilterChanged = !isEqual({ ...filters, dateOption }, analyticDefaultFilters);

    const tabTypes = useMemo(() => tabDefinitions.map(x => ({
        ...x,
        selected: true,
        visible: true,
        disabled: false,
    })), [tabDefinitions]);

    const handleDateClear = () => {
        onChange({
            ...filters,
            dateOption: undefined,
            date: {
            },
        });
    };

    const handleDateOptionChange = (dateOption: DateFilterOption) => {
        if (dateOption?.key === dateRangeFilterOptions.Custom.key) {
            return onChange({
                ...filters,
                dateOption,
                date: {
                    dateFrom: undefined,
                    dateTo: undefined,
                }
            });
        }

        onChange({
            ...filters,
            dateOption,
        });
    };

    const handleDateChange = (dateFilterOption: DateRange) => {
        onChange({
            ...filters,
            date: {
                ...filters.date,
                dateFrom: dateFilterOption.from,
                dateTo: dateFilterOption.to,
            },
        });
    };

    const handleUsersChange = (users: string[]) => {
        onChange({
            ...filters,
            users,
        });
    };

    const handleTabsChange = (filterOption: FilterOption<T>, checked: boolean) => {
        const prevValue = filters.tabs || [];
        const tabs = checked ? [...prevValue, filterOption.value] : prevValue.filter(x => x !== filterOption.value);

        onChange({
            ...filters,
            tabs,
        });
    };

    const handleTabsSelectAll = () => {
        onChange({
            ...filters,
            tabs: tabDefinitions.map(x => x.value),
        });
    };

    const handleTabsClearAll = () => {
        onChange({ ...filters, tabs: [] });
    };

    const handleReset = () => {
        onChange(analyticDefaultFilters);
    }

    return (
        <FilterPanel>
            <FilterSection>
                <TreeSelect
                    options={data}
                    value={filters.users}
                    title="Users"
                    disabled={disabled}
                    withSearch
                    renderMeta={option => <span>{option.meta?.views} views</span>}
                    onChange={handleUsersChange}
                />
            </FilterSection>
            <FilterSection>
                <FilterDateRange
                    buttonView="chart"
                    acceptableOptions={[
                        dateRangeFilterOptions.ThisWeek,
                        dateRangeFilterOptions.LastWeek,
                        dateRangeFilterOptions.ThisMonth,
                        dateRangeFilterOptions.LastMonth,
                        dateRangeFilterOptions.Custom,
                    ]}
                    title="Date"
                    disabled={disabled}
                    onSelectedDateChange={handleDateOptionChange}
                    onCustomDateChange={handleDateChange}
                    onClearAll={handleDateClear}
                    customDateRange={{ from: filters.date.dateFrom, to: filters.date.dateTo }}
                    selectedFilterOption={dateOption}
                    disabledDays={{ after: new Date() }}
                />
            </FilterSection>
            <FilterSection>
                <FilterSelect
                    buttonView="chart"
                    className=""
                    title="Tabs"
                    disabled={disabled}
                    onClearAll={handleTabsClearAll}
                    onSelectAll={handleTabsSelectAll}
                    options={tabTypes.map(x => ({ ...x, selected: filters.tabs?.includes(x.value) }))}
                    onChangeItemSelection={handleTabsChange}
                />
            </FilterSection>
            <FilterClear
                isShown={isFilterChanged}
                isDisabled={disabled}
                onClick={handleReset}
            />
        </FilterPanel>
    );
}
