import { Difference, DifferenceType, PrimitiveDifference } from '../../../../utils/differ/types';
import Highlighter from '../../common/Highlighter';
import { PrimitiveIndicator } from './PrimitiveIndicator';
import { indicatorMatchesSearchTerm } from './utils';

export class BooleanIndicator<T> extends PrimitiveIndicator<T> {
    /**
     * Represents and renders boolean indicators
     * Template:
     * - Added:     [Indicator name] added (when value=Yes)
     * - Updated:   n/a
     * - Removed:   [Indicator name] removed (when value=No)
     */
    constructor(
        public title: string,
        protected getValueDifference: (difference: Difference<T>) => PrimitiveDifference<unknown> | undefined,
    ) {
        super(title, getValueDifference);
    }

    render(searchTerms: string[] = [], difference: Difference<T>) {
        if (!difference) {
            return null;
        }

        const valueDifference = this.getValueDifference(difference);

        if (!valueDifference) {
            return null;
        }

        const { type, currentValue } = valueDifference;

        if (type === DifferenceType.Unchanged) {
            return null;
        }

        if (searchTerms.some(s => !!s.length) && !indicatorMatchesSearchTerm(searchTerms, difference)(this)) {
            return null;
        }

        return (
            <span>
                <Highlighter
                    searchWords={searchTerms}
                    autoEscape={true}
                    textToHighlight={this.title}
                />
                {currentValue ? ' added' : ' removed'}
            </span>
        );
    }
}
