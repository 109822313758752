import { collateralQualityTestValues } from "../../../../../../constants/collateral-quality-test";
import { CollateralQualityTestValue } from "../../../../../../types/amr-pipeline/enums/CollateralQualityTestValue";
import { CollateralQualityTest } from "../../../../../../types/amr-pipeline/models/CollateralQualityTest";
import { formatUtils, moneyUtils } from "../../../../../../utils";
import { Table } from "../../../../../bidding/common/table";
import { ColumnBuilder } from "../../../../../bidding/common/table/columns/column-builder/ColumnBuilder";
import { IColumnDefinition } from "../../../../../bidding/common/table/types/ColumnDefinition";
import { SectionContent } from "../../../../common/section/SectionContent";
import { EmptyPlaceholder } from "../../../../../common/EmptyPlaceholder";
import { Difference } from '../../../../../../utils/differ/types';
import { DifferenceField } from '../../DifferenceField';
import { DifferenceListProps, withDeletedItems } from '../../withDeletedItems';
import { constants } from "../../../../../../constants";
import { ImSubscriptionValue } from '../../../../subscription/ImSubscriptionValue';
import { OriginatingTransaction } from '../../../../../../types/amr-pipeline/models/OriginatingTransaction';
import { isNil } from "lodash";

interface Props extends DifferenceListProps<CollateralQualityTest, CollateralQualityTest> {
    selectedTransaction: OriginatingTransaction;
}

interface ColumnContext {
    difference?: Difference<CollateralQualityTest>;
    transaction: OriginatingTransaction;
}

const valueFormatter = (value?: CollateralQualityTestValue) => value ? collateralQualityTestValues[value] : undefined;

const covenantAndTargetFormatter = (collateralQualityTest: CollateralQualityTest) => (value?: number) => {
    if (isNil(value)) {
        return constants.emptyPlaceholder
    }

    return collateralQualityTest.value === CollateralQualityTestValue.AssetPar ||
        collateralQualityTest.value === CollateralQualityTestValue.MoodysWARF
        ? moneyUtils.money(value, true)
        : formatUtils.formatDecimal(value);
};

const columns: IColumnDefinition<CollateralQualityTest>[] = [
    {
        columnKey: "value",
        renderColumnHeaderContent: () => "Value",
        renderColumnContent: (collateralQualityTest: CollateralQualityTest, { difference }: ColumnContext) =>
            <DifferenceField difference={difference?.value} formatter={valueFormatter}>
                {valueFormatter(collateralQualityTest.value)}
            </DifferenceField>,
        headerClassName: "data-list-cell-xl",
        bodyClassName: "data-list-cell-xl",
    },
    {
        columnKey: 'covenant',
        renderColumnHeaderContent: () => 'Covenant',
        renderColumnContent: (collateralQualityTest: CollateralQualityTest, { difference, transaction }: ColumnContext) => (
            <ImSubscriptionValue transaction={transaction}>
                <DifferenceField
                    difference={difference?.covenant}
                    formatter={covenantAndTargetFormatter(collateralQualityTest)}
                >
                    {covenantAndTargetFormatter(collateralQualityTest)(collateralQualityTest.covenant) ??
                        constants.emptyPlaceholder}
                </DifferenceField>
            </ImSubscriptionValue>
        ),
        headerClassName: 'data-list-cell-lg text-right data-list-cell-change',
        bodyClassName: 'data-list-cell-lg text-right data-list-cell-change',
    },
    {
        columnKey: "target",
        renderColumnHeaderContent: () => "Target",
        renderColumnContent: (collateralQualityTest: CollateralQualityTest, { difference, transaction }: ColumnContext) =>
            <ImSubscriptionValue transaction={transaction}>
                <DifferenceField difference={difference?.target} formatter={covenantAndTargetFormatter(collateralQualityTest)}>
                    {covenantAndTargetFormatter(collateralQualityTest)(collateralQualityTest.target)}
                </DifferenceField>
            </ImSubscriptionValue>,
        headerClassName: "data-list-cell-lg text-right data-list-cell-change",
        bodyClassName: "data-list-cell-lg text-right overflow-visible data-list-cell-change",
    },
];

const collateralQualityTestsColumns = columns.map((c) => new ColumnBuilder(c));

function Component({ selectedTransaction, items, difference }: Props) {
    return (
        <SectionContent
            title="Portfolio Summary"
            className="data-item-collateral-quality-tests"
        >
            {
                !!items.length
                    ? <Table
                        className="data-list-striped"
                        dataItems={items}
                        createSecurityCustomArgs={(item: CollateralQualityTest) => {
                            const arrayItelDifference = difference?.find(({ derivedValue }) => {
                                return derivedValue?.value === item.value;
                            })

                            return { difference: arrayItelDifference?.difference, transaction: selectedTransaction };
                        }}
                        columns={collateralQualityTestsColumns}
                    />
                    : <EmptyPlaceholder text="There is no information to display." />
            }
        </SectionContent>
    );
}

export const CollateralQualityTests = withDeletedItems(Component);
