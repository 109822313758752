import { Company } from '../../../../../../../types/amr-pipeline/models/Company';
import { FormField } from '../../../../../common/FormField';

interface Props {
    investor?: Company;
}

const CompanyTab = ({ investor }: Props) => {
    const { address, city, state, zipCode, country } = investor as Company;
    const cityAndState = city?.name && state?.name ? `${city?.name}, ${state?.name}` : city?.name || state?.name;
    const zipCodeAndCountry = zipCode && country?.name ? `${zipCode}, ${country?.name}` : zipCode || country?.name;
    return (
        <FormField
            className="data-item-row-full-height"
            title="Address"
            value={
                investor ? (
                    <address>
                        <p>{address}</p>
                        <p>{cityAndState}</p>
                        <p>{zipCodeAndCountry}</p>
                    </address>
                ) : null
            }
        />
    );
};

export default CompanyTab;
