import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../../../types/state/AppState';
import { amrTransactionAnalyticsActions } from '../../../../../../actions';
import { ClientsActivityChart } from './ClientsActivityChart';
import { OriginatingTransaction } from '../../../../../../types/amr-pipeline/models/OriginatingTransaction';
import { TransactionAccessType, TransactionAccessTypes } from '../../../../../../types/amr-pipeline/enums/TransactionAccessType';
import { SubscriptionFeature } from '../../../../../../types/billing/SubscriptionFeature';
import { TransactionAnalyticsFilter } from '../../../../../../types/analytics/AnalyticsFilter';
import { AnalyticsWidget } from '../../../../common/analytics/AnalyticsWidget';
import { TreeSelectOption } from '../../../../../controls/TreeSelect';
import { withAnalyticsRequest } from '../withAnalyticsRequest';
import { ChartRequiredFeature } from '../../../../../access/ChartRequiredFeature';

interface ClientsActivityWidgetProps {
    transaction: OriginatingTransaction;
    isLoaded: boolean;
    users: TreeSelectOption<string>[];
}

const selector = (state: AppState) => state.issuanceMonitor.amrPipelineDetailed.analytics.clientsActivity;

const getAction = (dealReferenceName: string, transactionReferenceName: string, filter: TransactionAnalyticsFilter) => {
    return amrTransactionAnalyticsActions.clientsActivityRequest(
        dealReferenceName,
        transactionReferenceName,
        filter.date.dateFrom || undefined,
        filter.date.dateTo || undefined,
    );
};

function ClientsActivityWidgetComponent({ transaction, isLoaded, users }: ClientsActivityWidgetProps) {
    const dispatch = useDispatch();
    const clientsActivity = useSelector(selector);
    const { filter, data } = clientsActivity;

    const { dealReferenceName, referenceName: transactionReferenceName, status, closingDate } = transaction;

    const handleFilterChange = useCallback((filter: TransactionAnalyticsFilter) => {
        dispatch(amrTransactionAnalyticsActions.clientsActivityFilterChange(filter));
    }, [dispatch]);

    const handleBarClick = useCallback((accessType: TransactionAccessType, date: Date) => {
        dispatch(
            amrTransactionAnalyticsActions.transactionViewHistoryFilterByBar(
                dealReferenceName,
                transactionReferenceName,
                accessType,
                date
            )
        );
    }, [dispatch, dealReferenceName, transactionReferenceName]);

    const filteredClientsActivity = useMemo(() => data
        .filter(row => {
            if (filter.users.length && !filter.users.includes(row.userEmail)) {
                return false;
            }

            if (filter.tabs.length && !filter.tabs.some(x => x === row.accessType)) {
                return false;
            }

            return true;
        }), [data, filter.users, filter.tabs]);

    const chart = useMemo(() => <ClientsActivityChart
        onBarClick={handleBarClick}
        filter={filter}
        data={filteredClientsActivity}
        closingDate={closingDate}
        status={status}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    />, [handleBarClick, filter, filteredClientsActivity]);

    return (
        <ChartRequiredFeature
            feature={SubscriptionFeature.IssuanceMonitorFullAccess}
            chartName="Clients Activity"
            blockedClassName="restricted-placeholder-clients-activity"
        >
            <AnalyticsWidget
                title="Clients Activity"
                users={users}
                isLoading={isLoaded}
                onFilterChange={handleFilterChange}
                state={clientsActivity}
                tabDefinitions={TransactionAccessTypes}
            >
                {chart}
            </AnalyticsWidget>
        </ChartRequiredFeature>
    );
}

export const ClientsActivityWidget = withAnalyticsRequest(getAction, selector)(ClientsActivityWidgetComponent);
