import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../../../types/state/AppState';
import { amrTransactionAnalyticsActions } from '../../../../../../actions';
import { TransactionViewHistoryTable } from './TransactionViewHistoryTable';
import { OriginatingTransaction } from '../../../../../../types/amr-pipeline/models/OriginatingTransaction';
import { SubscriptionFeature } from '../../../../../../types/billing/SubscriptionFeature';
import { user } from '../../../../../../user';
import { TransactionAnalyticsFilter } from '../../../../../../types/analytics/AnalyticsFilter';
import { TreeSelectOption } from '../../../../../controls/TreeSelect';
import { withAnalyticsRequest } from '../withAnalyticsRequest';
import { AnalyticsWidget } from '../../../../common/analytics/AnalyticsWidget';
import { TransactionAccessTypes } from '../../../../../../types/amr-pipeline/enums/TransactionAccessType';
import { BlockedFeatureContent, SubscribeLink } from "../../../../../../components/access/BlockedFeatureText";

interface TransactionViewHistoryWidgetProps {
    transaction: OriginatingTransaction;
    isLoaded: boolean;
    users: TreeSelectOption<string>[];
}

const selector = (state: AppState) => state.issuanceMonitor.amrPipelineDetailed.analytics.transactionHistoryViews;

const getAction = (dealReferenceName: string, transactionReferenceName: string, filter: TransactionAnalyticsFilter) => {
    return amrTransactionAnalyticsActions.transactionViewHistoryRequest(
        dealReferenceName,
        transactionReferenceName,
        filter.date.dateFrom || undefined,
        filter.date.dateTo || undefined,
    );
};

function TransactionViewHistoryWidgetComponent({ transaction, isLoaded, users }: TransactionViewHistoryWidgetProps) {
    const dispatch = useDispatch();

    const transactionHistoryViews = useSelector(selector);
    const { filter, data } = transactionHistoryViews;
    const { dealReferenceName, referenceName: transactionReferenceName } = transaction;

    const withImSubscription = user.hasFeatures(SubscriptionFeature.IssuanceMonitorFullAccess);

    const handleFilterChange = (filter: TransactionAnalyticsFilter) => {
        dispatch(amrTransactionAnalyticsActions.transactionViewHistoryFilterChange(filter));
    };

    const dataForTable = withImSubscription ? data : [data[0]];

    return (
        <AnalyticsWidget
            title="Transaction View History"
            isLoading={isLoaded}
            users={users}
            onFilterChange={handleFilterChange}
            state={transactionHistoryViews}
            tabDefinitions={TransactionAccessTypes}
            feature={SubscriptionFeature.IssuanceMonitorFullAccess}
        >
            <>
                <TransactionViewHistoryTable
                    dealReferenceName={dealReferenceName}
                    transactionReferenceName={transactionReferenceName}
                    data={dataForTable}
                    filter={filter}
                />
                {!withImSubscription && (
                    <BlockedFeatureContent
                        inline
                        text={<><SubscribeLink /> to see {data.length > 1 ? data.length - 1 : ''} more transactions view(s).</>}
                    />
                )}
            </>
        </AnalyticsWidget>
    );
}

export const TransactionViewHistoryWidget = withAnalyticsRequest(getAction, selector)(TransactionViewHistoryWidgetComponent);
