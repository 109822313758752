import { useMemo } from 'react';
import { TransactionViewHistory } from '../../../../../../types/amr-pipeline/models/TransactionSession';
import { Table } from '../../../../../bidding/common/table';
import { DashboardNoSelectedFilters } from '../../../../../dashboard/DashboardNoSelectedFilters';
import { transactionViewHistoryColumns } from './transactionViewHistory.columns';
import { TransactionAnalyticsFilter } from '../../../../../../types/analytics/AnalyticsFilter';

interface TransactionViewHistoryTableProps {
    transactionReferenceName: string;
    dealReferenceName: string;
    data: TransactionViewHistory[];
    filter: TransactionAnalyticsFilter;
}

export const TransactionViewHistoryTable = ({ transactionReferenceName, dealReferenceName, data, filter }: TransactionViewHistoryTableProps) => {
    const filteredClientsActivity = useMemo(
        () =>
            data.filter(row => {
                if (filter.users.length && !filter.users.includes(row.userEmail)) {
                    return false;
                }

                if (filter.tabs.length && !filter.tabs.some(accessType => row.views[accessType] > 0)) {
                    return false;
                }

                return true;
            }),
        [data, filter.users, filter.tabs],
    );

    return filteredClientsActivity.length ? (
        <Table
            className="data-list-striped transaction-view-history"
            createSecurityCustomArgs={() => ({ transactionReferenceName, dealReferenceName })}
            dataItems={filteredClientsActivity}
            columns={transactionViewHistoryColumns()}
        />
    ) : (
        <DashboardNoSelectedFilters />
    );
};
