import { Link } from 'react-router-dom';
import { constants, routes, SORTING_TYPE } from '../../../../../../constants';
import { TransactionAccessTypes } from '../../../../../../types/amr-pipeline/enums/TransactionAccessType';
import { TransactionViewHistory } from '../../../../../../types/amr-pipeline/models/TransactionSession';
import { SubscriptionFeature } from '../../../../../../types/billing/SubscriptionFeature';
import { dateTimeUtils } from '../../../../../../utils';
import { RequiredFeature } from '../../../../../access/RequiredFeature';
import { ColumnBuilder } from '../../../../../bidding/common/table/columns/column-builder/ColumnBuilder';
import { IColumnDefinition } from '../../../../../bidding/common/table/types/ColumnDefinition';
import { OnHoverTooltip, Pluralize } from '../../../../../common';
import { TransactionViewHistoryPopover } from './TransactionViewHistoryPopover/TransactionViewHistoryPopover';
import { TransactionViewHistoryTabs } from './TransactionViewHistoryPopover/TransactionViewHistoryTabs';
import { ProportionBar } from '../../../../ProportionBar';
import { getDocumentCountBySession } from '../../../../../../utils/amr-pipeline.utils';
import FollowUp from '../../../../../common/FollowUp';


const transactionViewHistoryColumnsDefinition: IColumnDefinition<TransactionViewHistory>[] = [
    {
        columnKey: 'transactionViews',
        renderColumnHeaderContent: () => 'Transaction Views',
        renderColumnContent: (userSession, { dealReferenceName, transactionReferenceName }) => (
            <TransactionViewHistoryPopover
                dealReferenceName={dealReferenceName}
                transactionReferenceName={transactionReferenceName}
                userSession={userSession}
            />
        ),
        sortingField: 'accessDateTime',
        className: 'data-list-cell-lg',
        sortingType: SORTING_TYPE.date,
    },
    {
        columnKey: 'version',
        renderColumnHeaderContent: () => 'Version',
        renderColumnContent: (userSession, { dealReferenceName, transactionReferenceName }) => {
            const formattedVersionDate = dateTimeUtils.utcToLocalString(userSession.versionDate);

            return (
                <RequiredFeature
                    feature={SubscriptionFeature.IssuanceMonitorFullAccess}
                    renderBlockedContent={() => `V.${userSession.version} ${formattedVersionDate}`}
                >
                    <Link
                        to={routes.transactionHistoryUrl(
                            transactionReferenceName,
                            dealReferenceName,
                            'details',
                            userSession.version,
                        )}
                    >
                        V.{userSession.version} {formattedVersionDate}
                    </Link>
                </RequiredFeature>
            );
        },
        sortingField: 'version',
        className: 'data-list-cell-lg-02',
        sortingType: SORTING_TYPE.number,
    },
    {
        columnKey: 'tabsViewed',
        renderColumnHeaderContent: () => 'Tabs Viewed',
        renderColumnContent: userSession => {
            const views = userSession.views;

            return (
                <ProportionBar
                    title="view(s)"
                    items={TransactionAccessTypes.map(t => ({
                        title: t.text,
                        value: views && userSession.views[t.value],
                        color: t.color
                    }))}
                />
            );
        },
        className: 'data-list-cell-lg-02',
    },
    {
        columnKey: 'docsDownloaded',
        renderColumnHeaderContent: () => 'Docs Downloaded',
        renderColumnContent: (userSession) => {
            const data = getDocumentCountBySession(userSession);

            return (
                <>
                    <ProportionBar
                        items={[
                            {
                                title: 'Deal Documents',
                                value: data.dealDocumentsViews,
                                color: '#FFB400',
                            },
                            {
                                title: 'Disclosure',
                                value: data.disclosureFileViews,
                                color: '#8D40BE',
                            },
                            {
                                title: 'Manager Presentation',
                                value: data.managerPresentationViews,
                                color: '#74B58F',
                            },
                            {
                                title: 'Target Portfolio',
                                value: data.targetPortfolioViews,
                                color: '#487EB2',
                            },
                            {
                                title: 'Intex File',
                                value: data.intexFileViews,
                                color: '#5BBED8',
                            },
                        ]}
                    />
                </>
            );
        },
        className: 'data-list-cell-lg-02',
    },
    {
        columnKey: 'docsDownloadedSize',
        renderColumnHeaderContent: () => '',
        renderColumnContent: (userSession, { dealReferenceName, transactionReferenceName }) => {
            const data = getDocumentCountBySession(userSession);

            const totalViews =
                data.dealDocumentsViews +
                data.managerPresentationViews +
                data.targetPortfolioViews +
                data.intexFileViews +
                data.disclosureFileViews;

            return (
                <>
                    {totalViews ? (
                        <TransactionViewHistoryPopover
                            dealReferenceName={dealReferenceName}
                            transactionReferenceName={
                                transactionReferenceName
                            }
                            userSession={userSession}
                            defaultTab={
                                TransactionViewHistoryTabs.DocsDownloaded
                            }
                        >
                            <Pluralize count={totalViews} singular="doc" />
                        </TransactionViewHistoryPopover>
                    ) : (
                        constants.emptyPlaceholder
                    )}
                </>
            );
        },
        className: 'data-list-cell-xs',
    },
    {
        columnKey: 'fullName',
        renderColumnHeaderContent: () => 'Full Name',
        renderColumnContent: (userSession, { dealReferenceName, transactionReferenceName }) => (
            <TransactionViewHistoryPopover
                dealReferenceName={dealReferenceName}
                transactionReferenceName={transactionReferenceName}
                userSession={userSession}
                defaultTab={TransactionViewHistoryTabs.Contacts}
            >
                {userSession.userFullName}
            </TransactionViewHistoryPopover>
        ),
        sortingField: 'userFullName',
        className: 'data-list-cell-xl',
        sortingType: SORTING_TYPE.string,
    },
    {
        columnKey: 'companyLegalName',
        renderColumnHeaderContent: () => 'Company',
        renderColumnContent: ({ companyLegalName }) => (
            <OnHoverTooltip overlay={companyLegalName}>
                {companyLegalName}
            </OnHoverTooltip>
        ),
        sortingField: 'companyLegalName',
        className: 'data-list-cell-xl-xxl',
        sortingType: SORTING_TYPE.string,
    },
    {
        columnKey: 'follow-up',
        renderColumnHeaderContent: () => '',
        renderColumnContent: (userSession) => <FollowUp email={userSession.userEmail} />,
        className: 'data-list-cell-md-flexible',
    }

];

export const transactionViewHistoryColumns = () => transactionViewHistoryColumnsDefinition.map(c => new ColumnBuilder(c));

