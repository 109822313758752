import { Difference } from '../../../../utils/differ/types';
import { IIndicatorSection, SimpleIndicator } from './types';
import { indicatorMatchesSearchTerm } from './utils';

export class IndicatorSection<T> implements IIndicatorSection<T> {
    constructor(public title: string, public indicators: SimpleIndicator<T>[]) {}

    hasChanges(difference: Difference<T>) {
        return this.indicators.some(indicator => indicator.hasChanges(difference));
    }

    render(searchTerms: string[] = [], difference: Difference<T>) {
        if (!this.hasChanges(difference)) {
            return null;
        }

        return (
            <div key={this.title} className="history-element">
                <h2>{this.title}</h2>
                <ul className="list-bullet text-sm">
                    {this.indicators.map(indicator => {
                        if (
                            searchTerms &&
                            searchTerms.some(s => !!s.length) &&
                            !indicatorMatchesSearchTerm(searchTerms, difference)(indicator)
                        ) {
                            return null;
                        }

                        if (!indicator.hasChanges(difference)) {
                            return null;
                        }

                        return (<li key={indicator.uid}>{indicator.render(searchTerms, difference)}</li>);
                    })}
                </ul>
            </div>
        );
    }
}
