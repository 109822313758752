import { groupBy, sumBy } from "lodash";
import { TransactionClientActivity } from "../../../../../../types/amr-pipeline/models/TransactionSession";
import { TransactionAccessType, TransactionAccessTypes } from "../../../../../../types/amr-pipeline/enums/TransactionAccessType";
import { SectionContent } from "../../../../common/section/SectionContent";
import { PercentageBar } from "../PercentageBar";
import { RequiredFeature } from "../../../../../access/RequiredFeature";
import { SubscriptionFeature } from "../../../../../../types/billing/SubscriptionFeature";
import { BlockedFeatureContent, SubscribeLink } from "../../../../../../components/access/BlockedFeatureText";

interface Props {
    clientsActivity?: TransactionClientActivity[];
}

export function ViewedTabs({ clientsActivity = [] }: Props) {
    const totalViews = sumBy(clientsActivity, (ca) =>
        ca.accessType === TransactionAccessType.Deal ||
        ca.accessType === TransactionAccessType.ExportTargetPortfolio
            ? 0
            : ca.numberOfAccess
    );

    const groupedByAccessType = groupBy(clientsActivity, (x) => x.accessType);

    const viewTabData = TransactionAccessTypes.map((f) => {
        const fieldData = groupedByAccessType[f.value];
        const views = sumBy(fieldData, (ca) =>
            ca.accessType === f.value ? ca.numberOfAccess : 0
        );

        return {
            title: f.text,
            views,
        };
    });

    const renderPercentageBar = () =>
        viewTabData.map(v => (
            <div key={v.title} className="viewed-tabs-item">
                <PercentageBar key={v.title} title={v.title} maxValue={totalViews} value={v.views} />
            </div>
        ));

    const renderBlockedContent = () => (
        <div className="viewed-tabs-item">
            <PercentageBar
                key={viewTabData[0].title}
                title={viewTabData[0].title}
                maxValue={totalViews}
                value={viewTabData[0].views}
            />
            <BlockedFeatureContent
                inline
                className="empty-placeholder analytics-legend"
                text={
                    <>
                        <h3>See {viewTabData.length - 1} more tabs viewed by the clients</h3>
                        <SubscribeLink />
                    </>
                }
            />
        </div>
    );

    return (
        <SectionContent title="Viewed Tabs">
            <RequiredFeature
                feature={SubscriptionFeature.IssuanceMonitorFullAccess}
                renderBlockedContent={renderBlockedContent}
            >
                {renderPercentageBar()}
            </RequiredFeature>
        </SectionContent>
    );
}
