import React from "react";
import { routes } from "../../../constants/routes";
import { OnHoverTooltip } from "../../common";
import { roles } from "../../../constants";
import { user } from "../../../user";

interface Props {
    referenceName: string;
    legalName: string;
    onClick?: () => void;
}

export function ManagerLink({
    referenceName,
    legalName,
    onClick,
}: Props) {

    const isMedia = user.hasRoles(roles.Media);
    const isLinkAvailable =
        !user.hasRoles(roles.Viewer, roles.ArrangersClient) || isMedia;

    const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();

        onClick?.();

        window.open(window.location.origin + routes.manageCloManagerUrl(referenceName));
    };

    return (
        <OnHoverTooltip overlay={legalName}>
            {isLinkAvailable ? (
                <span className="link-filter text-ellipsis" onClick={handleClick}>
                    {legalName}
                </span>
            ) : (
                <span>{legalName}</span>
            )}
        </OnHoverTooltip>
    );
}
