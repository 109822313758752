import React from 'react';
import classNames from 'classnames';
import { OnHoverTooltip } from "../../common";
import IconSVG from "../../../styles/svg-icons";

interface Props {
    title: string | React.ReactNode,
    required?: boolean,
    value?: any,
    error?: string,
    tooltip?: string,
    tooltipLabel?: boolean,
    children?: React.ReactNode,
    className?: string;
    capitalize?: boolean;
    infoTooltip?: string;
}

export function FormField({ title, value = null, error = '', tooltip = '', tooltipLabel = false, children = null, required = false, className = "", capitalize = true, infoTooltip }: Props) {

    function renderTitle() {
        return (
            <div className={`data-item-col data-item-col-title ${capitalize ? "data-item-capitalize" : ""}`}>
                {title}
                {required && <span className="text-red"> *</span>}
                {!!infoTooltip && (
                    <OnHoverTooltip overlay={infoTooltip}>
                        <IconSVG name="info" width={16} height={16} className="title-info-icon" />
                    </OnHoverTooltip>
                )}
            </div>
        );
    }

    function renderDataItem() {
        return (
            <div className={classNames('data-item-col', { error })}>
                {!!value && <div className="data-item-text">{value}</div>}
                {children}
                {!!error && <div className="form-error show">{error}</div>}
            </div>
        );
    }

    return (
        <div className={classNames('data-item-row', className)}>
            {!!tooltip && tooltipLabel
                ? <OnHoverTooltip overlay={tooltip} wrapperClass="data-item-col-title">
                    {renderTitle()}
                </OnHoverTooltip>
                : renderTitle()
            }

            {!!tooltip && !tooltipLabel
                ? <OnHoverTooltip overlay={tooltip} wrapperClass="text-ellipsis">
                    {renderDataItem()}
                </OnHoverTooltip>
                : renderDataItem()
            }
        </div>
    );
}

