import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { imUserConfigActions } from '../../../../actions/';
import { Popup, PopupBody, PopupFooter } from '../../../controls';
import { Transfer } from '../../../common/transfer/Transfer';
import { TabList, TabItem } from '../../../bidding/common/tab-list';
import { PipelineView } from '../../types/PipelineView';
import { TransferOption } from '../../../common/transfer/types';
import { AppState } from '../../../../types/state/AppState';
import { UserConfigState } from '../../../../types/state/UserConfigState';
import { UserConfigType } from '../../../../types/user-config/UserConfigType';
import { UserConfigBase, ColumnsConfig } from '../../../../types/user-config/UserConfig';
import { UserConfigColumn } from '../../../../types/user-config/UserConfigColumn';
import { pipelineColumnLabels } from '../../../../constants';
import { PipelineColumn } from '../../types/PipelineColumn';
import { isEqual } from 'lodash';

interface CustomizeColumnsDialogProps {
    defaultTab: PipelineView;
    onClose: () => void;
}

interface TabState {
    options: TransferOption[];
    selected: TransferOption[];
}

const TabConfigMap = {
    [PipelineView.Class]: UserConfigType.imColumnsClassTab,
    [PipelineView.Deal]: UserConfigType.imColumnsDealTab,
};

export const CustomizeColumnsDialog = ({
    defaultTab,
    onClose,
}: CustomizeColumnsDialogProps) => {
    const dispatch = useDispatch();
    const { isUpdating, isLoadingDefault, columnsConfig, columnsDefaultConfig } = useSelector<AppState, UserConfigState>(state => state.imUserConfig);
    const [activeTab, setActiveTab] = useState(defaultTab);

    const disabled = isUpdating || isLoadingDefault;

    const transferOptionFromColumn = (column: UserConfigColumn) => ({
        key: column.name,
        title: pipelineColumnLabels[column.name as PipelineColumn],
        permanent: column.required,
        fixed: column.fixed,
        order: column.order,
        available: column.available
    } as TransferOption);

    const deriveTabStateFromConfig = (userConfig: ColumnsConfig[], tab: PipelineView) => {
        const { value = [] } = userConfig.find(({ type }) => type === TabConfigMap[tab]) || {};

        const options = value.map(transferOptionFromColumn);

        const selected = value
            .filter(({ displayed }) => displayed)
            .map(transferOptionFromColumn);

        return {
            options,
            selected,
        } as TabState;
    };

    const getDefaultTabState = () => [PipelineView.Class, PipelineView.Deal]
        .reduce((acc, tab) => ({
            ...acc,
            [tab]: deriveTabStateFromConfig(columnsConfig, tab),
        }), {} as Record<PipelineView, TabState>);

    const [tabState, setTabState] = useState<Record<PipelineView, TabState>>(getDefaultTabState());
    const [tabDefaultState, setTabDefaultState] = useState<Record<PipelineView, TabState>>(tabState);

    useEffect(() => {
        // Changed internal state after Reset
        setTabState((prevState) => ({
            ...prevState,
            [activeTab]: deriveTabStateFromConfig(columnsConfig, activeTab),
        }));
    }, [columnsConfig]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(columnsDefaultConfig.length) {
            setTabDefaultState({
                [PipelineView.Class]: deriveTabStateFromConfig(columnsDefaultConfig, PipelineView.Class),
                [PipelineView.Deal]: deriveTabStateFromConfig(columnsDefaultConfig, PipelineView.Deal),
            });
        } else {
            dispatch(imUserConfigActions.getDefaultUserColumnsConfig());
        }
    }, [columnsDefaultConfig]); // eslint-disable-line react-hooks/exhaustive-deps

    const getUserConfig = (options: TransferOption[], selected: TransferOption[]) => {
        const selectedKeys = selected.map(({ key }) => key);

        const selectedColumns = selected
            .reduce((acc: UserConfigColumn[], option: TransferOption, index: number) => ([
                ...acc,
                {
                    name: option.key,
                    order: index,
                    available: option.available,
                    required: option.permanent === true,
                    displayed: true,
                    fixed: option.fixed,
                } as UserConfigColumn
            ]), []);

        return options
            .filter(option => !selectedKeys.includes(option.key))
            .map(option => ({
                name: option.key,
                order: 0,
                required: option.permanent === true,
                displayed: false,
                available: option.available,
                fixed: option.fixed,
            }))
            .concat(selectedColumns);
    };

    const handleSave = () => {
        const userConfig = [PipelineView.Class, PipelineView.Deal]
            .reduce((acc: UserConfigBase[], tab: PipelineView) => (
                tabState ? [
                    ...acc,
                    {
                        type: TabConfigMap[tab],
                        value: getUserConfig(tabState[tab].options, tabState[tab].selected),
                    } as UserConfigBase
                ] : acc
            ), []);

        dispatch(imUserConfigActions.updateUserConfig(userConfig));
    };

    const handleTransferChange = (value: TransferOption[]) => {
        setTabState((prevState) => ({
            ...prevState,
            [activeTab]: {
                ...prevState[activeTab],
                selected: value,
            }
        }));
    };

    const handleReset = () => setTabState((prevState) => ({
        ...prevState,
        [activeTab]: tabDefaultState[activeTab],
    }));

    const checkIsConfigDefault = () => {
        const values = tabState[activeTab].selected.map((v, order) => ({...v, order}));
        const defaultValues = tabDefaultState[activeTab].selected.map((v, order) => ({...v, order}));
        return isEqual(values, defaultValues);
    };

    return (
        <Popup title="Customize Columns" onClose={onClose} modalClass="customize-columns-popup">
            <PopupBody>
                <p className="customize-columns-info">
                    There are default columns in the "Selected Columns" list, which you can't remove. You may adjust the table view by adding or removing additional columns from the lists. Additionally, you may change the order of the columns by dragging and dropping them in the "Selected Columns" list.
                </p>
                <TabList>
                    <TabItem
                        title='Deal View'
                        active={activeTab === PipelineView.Deal}
                        onClick={() => setActiveTab(PipelineView.Deal)}
                    />
                    <TabItem
                        title='Class View'
                        active={activeTab === PipelineView.Class}
                        onClick={() => setActiveTab(PipelineView.Class)}
                    />
                </TabList>
                <Transfer
                    leftColumnTitle="Available Columns"
                    rightColumnTitle="Selected Columns"
                    options={tabState ? tabState[activeTab].options : []}
                    selected={tabState ? tabState[activeTab].selected : []}
                    onChange={handleTransferChange}
                    onReset={handleReset}
                    disabled={disabled}
                    rightActionDisabled={checkIsConfigDefault()}
                />
            </PopupBody>
            <PopupFooter>
                <button
                    type="button"
                    className="btn btn-ghost"
                    onClick={onClose}
                    disabled={disabled}
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    className="btn btn-main"
                    onClick={handleSave}
                    disabled={disabled}
                >
                    Save
                </button>
            </PopupFooter>
        </Popup>
    );
};
