import { OriginatingTransactionClass } from "../../../../../types/amr-pipeline/models/OriginatingTransactionClass";
import { Table } from "../../../../bidding/common/table";
import { ColumnBuilder } from "../../../../bidding/common/table/columns/column-builder/ColumnBuilder";
import { amrColumn, capitalStructureColumns, classIndicatorColumns, coverageClassIndicatorColumns, coverageTestColumns } from "./capital-structure-columns";
import { TotalBalance } from "./TotalBalance";
import { EmptyPlaceholder } from "../../../../common/EmptyPlaceholder";
import { amrFormatUtils } from "../../../../../utils";
import { OriginatingTransactionClassStatus } from "../../../../../types/amr-pipeline/enums/OriginatingTransactionClassStatus";
import { OriginatingTransactionClassDiff } from '../../../../../types/amr-pipeline/models/OriginatingTransactionClassDiff';
import { DifferenceListProps, withDeletedItems } from '../withDeletedItems';
import { ClassIndicators } from "../../../../../types/amr-pipeline/models/ClassIndicators";
import { OriginatingTransaction } from "../../../../../types/amr-pipeline/models/OriginatingTransaction";

interface Props extends DifferenceListProps<OriginatingTransactionClass, OriginatingTransactionClassDiff> {
    transaction: OriginatingTransaction;
    withCoverage?: boolean;
}

export interface OriginatingTransCapitalStructure extends OriginatingTransactionClass, ClassIndicators {
    coupon?: string;
}

function Component({
    transaction,
    items,
    difference,
    withCoverage = false
}: Props) {
    const capitalStructureTransactionClasses = items.map(tc => {
        const { tranche, floaterIndex, margin, ...capitalStructureClass } = tc;
        const coupon = amrFormatUtils.formatInventoryCoupon(floaterIndex, margin);
        return { ...capitalStructureClass, ...tranche, tranche, coupon };
    });

    if (!items.length) {
        return (<EmptyPlaceholder text="There is no information to display." />);
    }

    return (
        <Table
            className="data-list-striped-cap-structure"
            sticky={true}
            dataItems={capitalStructureTransactionClasses}
            columns={
                withCoverage
                    ? [
                        ...capitalStructureColumns,
                        ...coverageTestColumns,
                        ...(difference ? [] : coverageClassIndicatorColumns),
                    ]
                    : [
                        ...capitalStructureColumns,
                        new ColumnBuilder(amrColumn),
                        ...(difference ? [] : classIndicatorColumns),
                    ]
            }
            createSecurityCustomArgs={(item: OriginatingTransactionClass) => {
                const arrayItelDifference = difference?.find(({ derivedValue }) => {
                    return derivedValue?.referenceName === item.referenceName;
                })

                return { difference: arrayItelDifference?.difference, transaction };
            }}
            createSecurityCustomClassName={(c: OriginatingTransactionClass) =>
                c.originatingTransactionClassStatus ===
                OriginatingTransactionClassStatus.NotOffered
                    ? "sold-status"
                    : ""
            }
            createHeaderCustomArgs={() => ({
                transaction,
                withGuidance: !difference && capitalStructureTransactionClasses.some(c => c.guidance),
                withSubscription: !difference && capitalStructureTransactionClasses.some(c => c.subscription),
            })}
            renderFooterRow={(columns: ColumnBuilder<OriginatingTransactionClass>[]) => <TotalBalance classes={items} columns={columns} sticky />}
        />
    );
}

export const CapitalStructure = withDeletedItems(Component);
