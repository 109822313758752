import { General } from "./sections/General";
import { Dates } from "./sections/Dates";
import { AdditionalInfo } from "./sections/AdditionalInfo";
import { SyndicateContacts } from './sections/SyndicateContacts';
import { Intex } from './sections/Intex';
import { TabBody } from '../../../common/tabs/TabBody';
import { withTabTracking } from '../withTabTracking';
import { TransactionTabProps } from '../types';
import { TransactionAccessType } from '../../../../../types/amr-pipeline/enums/TransactionAccessType';
import { ShowFor } from "../../../../access";
import { roles } from "../../../../../constants";

const DealOverviewTab = ({ transaction, difference }: TransactionTabProps) => {
    return (
        <TabBody className="tabs-data-padding component-tabs-deal-details">
            <General selectedTransaction={transaction} difference={difference} />
            <Dates selectedTransaction={transaction} difference={difference} />
            <AdditionalInfo selectedTransaction={transaction} difference={difference} />
            <SyndicateContacts contacts={transaction.syndicateContacts} />
            <ShowFor roles={roles.getAllRolesExclude(roles.Media, roles.Viewer)}>
                <Intex selectedTransaction={transaction} difference={difference} />
            </ShowFor>
        </TabBody>
    );
}

export const DealOverview = withTabTracking(TransactionAccessType.Overview)(DealOverviewTab);
