import * as React from "react";
import { roles, routes } from "../../../../../constants";
import { AmrTransaction } from "../../../../../types/amr-pipeline/models/AmrTransaction";
import { Deal } from "../../../../../types/amr-pipeline/models/Deal";
import { TabList, TabItem } from "../../../../bidding/common/tab-list";
import { Preloader } from "../../../../common";
import { TabContent } from "../../../common/tabs/TabContent";
import { AmrDealTabTypes } from "../../../types/AmrDealTabTypes";
import { Details } from "../transaction-details/Details";
import { Classes } from "./Classes";
import { Documents } from "./Documents";
import { ShowFor } from "../../../../access";


interface DealDetailsProps {
    deal?: Deal | undefined;
    transaction: AmrTransaction;
    transactionReferenceName: string;
    activeTab?: AmrDealTabTypes;
    disabled?: boolean;
}

export function TransactionDetails({
    deal,
    transaction,
    transactionReferenceName,
    activeTab = AmrDealTabTypes.details,
    disabled,
}: DealDetailsProps) {
    const renderTabContent = () => {
        if (deal) {
            switch (activeTab) {
                case AmrDealTabTypes.classes:
                    return <Classes transaction={transaction} />;
                case AmrDealTabTypes.documents:
                    return <Documents transaction={transaction} deal={deal} />;
                default:
                    return <Details transaction={transaction} deal={deal} />;
            }
        }
    };

    return (
        <>
            <TabList className="tabs-left-shift">
                <TabItem
                    active={activeTab === AmrDealTabTypes.details}
                    title="Overview"
                    path={routes.transactionDetailUrl(transactionReferenceName, transaction.dealReferenceName)}
                    disabled={disabled}
                />
                <TabItem
                    active={activeTab === AmrDealTabTypes.classes}
                    title="Classes"
                    path={routes.transactionDetailUrl(
                        transactionReferenceName,
                        transaction.dealReferenceName,
                        AmrDealTabTypes.classes
                    )}
                    disabled={disabled}
                />
                <ShowFor roles={roles.getAllRolesExclude(roles.Media, roles.Viewer)}>
                    <TabItem
                        active={activeTab === AmrDealTabTypes.documents}
                        title="Documents"
                        path={routes.transactionDetailUrl(
                            transactionReferenceName,
                            transaction.dealReferenceName,
                            AmrDealTabTypes.documents
                        )}
                        disabled={disabled}
                    />
                </ShowFor>
            </TabList>
            <TabContent>
                {deal ? renderTabContent() : <Preloader inProgress={true} />}
            </TabContent>
        </>
    );
}
