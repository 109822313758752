import { useState } from "react";
import { Popup, PopupBody, PopupFooter } from "../../../controls";
import { FlexRow, Preloader } from "../../../common";
import { OriginatingTransaction } from "../../../../types/amr-pipeline/models/OriginatingTransaction";
import { MailPreview } from "./MailPreview/MailPreview";
import { AmrTransaction } from "../../../../types/amr-pipeline/models/AmrTransaction";
import { useRequestInviteClients } from "./useRequestInviteClients";
import { StatusMessageSection } from "../../../status-message/StatusMessageSection";
import { StatusMessageSectionType } from "../../../../types/state/NotificationState";
import { validateEmail } from "../../../../utils/validate-email.utils";
import { EmailChipList } from "../../../common/EmailChipList";
import { useRequestTransactionDetails } from './useRequestTransactionDetails';
import { CollateralQualityTest } from "../../../../types/amr-pipeline/models/CollateralQualityTest";

const emailMaxCount = 500;

interface Props {
    transaction: OriginatingTransaction | AmrTransaction;
    onClose: () => void;
    defaultEmailList?: string[];
    withGetTransaction?: boolean;
}

export function InviteClientsTransactionPopup({
    transaction,
    onClose,
    defaultEmailList = [],
    withGetTransaction = false,
}: Props) {
    const [transactionForPreview, setTransactionForPreview] = useState(transaction);
    const [collateralQualityTests, setCollateralQualityTests] = useState((transaction as OriginatingTransaction).collateralQualityTests);
    const [emailList, setEmailList] = useState<string[]>(defaultEmailList);
    const [textContent, setTextContent] = useState("");
    const [sending, setSending] = useState(false);
    const [loading, setLoading] = useState(false);

    const { dealLegalName } = transactionForPreview;

    const handleClose = () => {
        if (!sending) {
            onClose();
        }
    };

    useRequestInviteClients(
        transactionForPreview,
        emailList,
        textContent,
        sending,
        setSending,
        onClose
    );

    useRequestTransactionDetails(
        transactionForPreview,
        setTransactionForPreview,
        withGetTransaction,
        loading,
        setLoading,
        (data: CollateralQualityTest[]) => setCollateralQualityTests(data),
    );

    const isLimitExceeded = emailList.length > emailMaxCount;
    const isDataInvalid = emailList.some((e) => !validateEmail(e));

    const renderInviteForm = () => (
        <>
            {isDataInvalid || isLimitExceeded ? (
                <StatusMessageSection type={StatusMessageSectionType.Error}>
                    {isLimitExceeded
                        ? `Max recipients count is ${emailMaxCount}`
                        : "Please fix invalid email addresses"}
                </StatusMessageSection>
            ) : (
                <StatusMessageSection type={StatusMessageSectionType.Info}>
                    Invite clients or share the deal with your team
                </StatusMessageSection>
            )}
            <EmailChipList
                disabled={sending || !!defaultEmailList.length}
                defaultItems={defaultEmailList}
                onChange={(list: string[]) => setEmailList(list)}
                allowInvalid
                withClearAll
                placeholder="Enter recipients or Ctrl+C / Ctrl+V emails from Excel"
                className="email-chips-list-with-clear"
            />
            <textarea
                value={textContent}
                maxLength={2048}
                onChange={(e) => setTextContent(e.target.value)}
                className="form-control share-transaction-message"
                placeholder="Enter message (optional)"
            />
        </>
    );

    return (
        <Popup
            renderTitle={
                <h2>
                    Share Transaction{" "}
                    <span className="name-tag">{dealLegalName}</span>
                </h2>
            }
            modalClass="modal-share-transaction"
            onClose={handleClose}
        >
            <PopupBody>
                <div className="share-transaction-row">
                    <div className="share-transaction-form">
                        {renderInviteForm()}
                    </div>
                    <div className="share-transaction-preview">
                        <MailPreview
                            transaction={transactionForPreview}
                            collateralQualityTests={collateralQualityTests}
                            text={textContent}
                            loading={loading}
                            setLoading={setLoading}
                        />
                    </div>
                </div>
            </PopupBody>
            <PopupFooter>
                <FlexRow>
                    {sending && (
                        <Preloader
                            inProgress={false}
                            fullScreen={false}
                            small={true}
                        />
                    )}
                    <button
                        type="button"
                        className="btn btn-ghost"
                        onClick={handleClose}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="btn btn-main"
                        onClick={() => setSending(true)}
                        disabled={isDataInvalid || !emailList.length || sending || loading || isLimitExceeded}
                    >
                        Send invitation
                    </button>
                </FlexRow>
            </PopupFooter>
        </Popup>
    );
}
