import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AlertOption } from '../../../../types/email-preferences/EmailPreferences';
import { imUserConfigActions } from '../../../../actions/';
import { AppState } from '../../../../types/state/AppState';
import { Preloader } from '../../../common';
import { UserConfigType } from '../../../../types/user-config/UserConfigType';
import { Popup, PopupBody, PopupFooter } from '../../../controls';
import {
    alertOptionTooltipText,
    alertOptions,
    analyticAlertOptions,
} from '../../../../constants/amr-pipeline/alertOptions';
import { SummaryDailyAlertConfig, TransactionAlertConfig } from '../../../../types/user-config/UserConfig';
import { Transaction } from '../../../../types/amr-pipeline/models/Transaction';
import { hasAnalyticsAlertsAccess } from '../../../../utils/amr-pipeline.utils';
import { EmailPreferencesRadioGroup } from '../../../profile/tabs/email-preferences/tabs/EmailPreferencesRadioGroup';
import { SubscriptionFeature } from '../../../../types/billing/SubscriptionFeature';

interface Props {
    transaction: Transaction;
    onClose: () => void;
}

export const TransactionAlertsPopup = ({ transaction, onClose }: Props) => {
    const dispatch = useDispatch();

    const userCompany= useSelector((state: AppState) => state.issuanceMonitor.amrPipelineCommon.userCompany);
    const {
        referenceName: transactionReferenceName,
        legalNameOnDeal: transactionLegalName,
        dealReferenceName,
    } = transaction;

    const withAnalyticsAlertsAccess = hasAnalyticsAlertsAccess(transaction, userCompany);

    const isUpdating = useSelector((state: AppState) => state.imUserConfig.isUpdating);
    const isDeleting = useSelector((state: AppState) => state.imUserConfig.isDeleting);

    const transactionAlertConfig = useSelector<AppState, TransactionAlertConfig>(
        (state: AppState) => state.imUserConfig.transactionAlertConfig,
    );
    const analyticAlertConfig = useSelector<AppState, SummaryDailyAlertConfig>(
        (state: AppState) => state.imUserConfig.summaryDailyAlertConfig,
    );

    const currentTransactionAlertConfig = useMemo(
        () => transactionAlertConfig.value.find(c => c.transactionReferenceName === transactionReferenceName),
        [transactionAlertConfig, transactionReferenceName]
    );
    const currentAnalyticAlertConfig = useMemo(
        () => analyticAlertConfig.value.find(c => c.transactionReferenceName === transactionReferenceName),
        [analyticAlertConfig, transactionReferenceName]
    );

    const [dealUpdatesAlertConfig, setDealUpdatesAlertConfig] = useState(currentTransactionAlertConfig);
    const [summaryDailyAlertConfig, setSummaryDailyAlertConfig] = useState(currentAnalyticAlertConfig);

    const [saving, setSaving] = useState(false);

    const dealUpdatesOption = currentTransactionAlertConfig?.alertOption || AlertOption.Never;
    const summaryDailyOption = currentAnalyticAlertConfig?.alertOption || AlertOption.Never;

    const selectedDealUpdatesOption = dealUpdatesAlertConfig?.alertOption || AlertOption.Never;
    const selectedSummaryDailyOption = summaryDailyAlertConfig?.alertOption || AlertOption.Never;

    const isInitialAlertsState = 
        dealUpdatesOption === selectedDealUpdatesOption &&
        summaryDailyOption === selectedSummaryDailyOption;

    const inProgress = isUpdating || isDeleting;
    const isSaveDisabled = isInitialAlertsState || inProgress;

    useEffect(() => {
        if (!inProgress && saving) {
            onClose();
            setSaving(false);
        }
    }, [inProgress, saving, onClose]);

    useEffect(() => {
        setDealUpdatesAlertConfig(
            transactionAlertConfig.value.find(c => c.transactionReferenceName === transactionReferenceName),
        );
        setSummaryDailyAlertConfig(
            analyticAlertConfig.value.find(c => c.transactionReferenceName === transactionReferenceName),
        );
    }, [transactionAlertConfig, analyticAlertConfig, transactionReferenceName]);

    const handleSave = () => {
        setSaving(true);

        const configs = [
            ...dealUpdatesAlertConfig ? [{ type: UserConfigType.transactionAlert, value: dealUpdatesAlertConfig }] : [],
            ...withAnalyticsAlertsAccess && summaryDailyAlertConfig ? [{ type: UserConfigType.summaryDailyAlerts, value: summaryDailyAlertConfig }] : [],
        ];

        dispatch(imUserConfigActions.saveTransactionAlerts(configs));
    };

    const renderAlertSection = (
        groupNameIndentifier: string,
        title: string,
        alertOptions: AlertOption[],
        selectedOption: AlertOption,
        onChange: (a: AlertOption) => void,
        description: string,
        alertOptionInfo?: { [key: string]: string },
    ) => (
        <div className="controls-wrap">
            <EmailPreferencesRadioGroup
                value={selectedOption}
                disabled={inProgress}
                alertOptions={alertOptions}
                alertOptionInfo={alertOptionInfo}
                groupNameIndentifier={groupNameIndentifier}
                title={title}
                description={description}
                onChange={onChange}
                requiredFeature={SubscriptionFeature.IssuanceMonitorTransactionsEmailAlerts}
            />
        </div>
    );

    return (
        <Popup
            title={
                <>
                    <span className="title">Transaction Alerts</span>
                    <span className="name-tag text-ellipsis">{transactionLegalName}</span>
                </>
            }
            onClose={onClose}
            modalClass="modal-email-preferences"
        >
            <PopupBody className="notifications-center">
                {renderAlertSection(
                    'tranasction-updates',
                    'Transaction Update',
                    alertOptions,
                    selectedDealUpdatesOption,
                    a =>
                        setDealUpdatesAlertConfig({
                            ...dealUpdatesAlertConfig!,
                            alertOption: a,
                            dealReferenceName,
                            transactionReferenceName,
                        }),
                    'Receive an email when the transaction is updated on Issuance Monitor.',
                    {
                        [AlertOption.Instant]: 'Alerts are sent once transactions are published',
                        [AlertOption.Daily]: alertOptionTooltipText[AlertOption.Daily],
                    },
                )}

                {withAnalyticsAlertsAccess &&
                    renderAlertSection(
                        'tranasction-analytics',
                        'Analytics',
                        analyticAlertOptions,
                        selectedSummaryDailyOption,
                        a =>
                            setSummaryDailyAlertConfig({
                                ...summaryDailyAlertConfig!,
                                alertOption: (a as AlertOption.Daily) || AlertOption.Never,
                                dealReferenceName,
                                transactionReferenceName,
                            }),
                        'Receive an email for deal statistic updates.',
                        {
                            [AlertOption.Daily]: alertOptionTooltipText[AlertOption.Daily],
                        }
                    )}
            </PopupBody>
            <PopupFooter>
                <button className="btn btn-ghost" onClick={onClose}>
                    Cancel
                </button>
                <button className="btn btn-main" onClick={handleSave} disabled={isSaveDisabled}>
                    Save {inProgress && <Preloader small inProgress />}
                </button>
            </PopupFooter>
        </Popup>
    );
};
