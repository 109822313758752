import { Difference, DifferenceType, ObjectArrayDifference, PrimitiveDifference } from '../../../../utils/differ/types';
import { Formatter, IIndicatorSection, SimpleIndicator } from './types';

export class ListIndicatorSection<T, K extends unknown> implements IIndicatorSection<T> {
    indicators: SimpleIndicator<T>[] = [];

    constructor(
        public title: string,
        protected getItemsDifference: (difference: Difference<T>) => ObjectArrayDifference<K[]> | undefined,
        protected getFieldDifference: (itemDifference: Difference<K>) => PrimitiveDifference<unknown> | undefined,
        protected formatter?: Formatter<K>,
    ) {}

    hasChanges(difference: Difference<T>) {
        const itemsDifference = this.getItemsDifference(difference);

        if (!itemsDifference) {
            return false;
        }

        return itemsDifference?.some(item => item.difference ? this.getFieldDifference(item.difference)?.type !== DifferenceType.Unchanged : false);
    }

    render(searchTerms: string[] = [], difference: Difference<T>) {
        if (!this.hasChanges(difference)) {
            return null;
        }

        // Just to not do excess job in next steps
        if (searchTerms.length) {
            return null;
        }

        const itemsDifference = this.getItemsDifference(difference)
            ?.filter(item => item.type !== DifferenceType.Unchanged);

        if (!itemsDifference) {
            return null;
        }

        return (
            <div key={this.title} className="history-element">
                <h2>{this.title}</h2>
                <ul className="list-bullet text-sm">
                   {itemsDifference?.map(item => {
                        const fieldDiff = item.difference ? this.getFieldDifference(item.difference) : null;
                        const derivedValue = fieldDiff?.derivedValue as string;
                        const value = this.formatter ? this.formatter(derivedValue) : derivedValue;

                        return <li key={value}><>{value} {fieldDiff?.type}</></li>
                   })}
                </ul>
            </div>
        );
    }
}
