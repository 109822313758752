import React from 'react';
import classNames from 'classnames';
import { DifferenceType, PrimitiveDifference } from '../../../../utils/differ/types';
import { OnHoverTooltip } from '../../../common';
import { DynamicsIcon } from '../../history/TransactionHistoryNavItem/DynamicsIcon';

interface DifferenceFieldProps {
    difference?: PrimitiveDifference<unknown>;
    formatter?: (...args: any) => React.ReactNode;
    children: React.ReactNode;
}

const getDifferenceType = (difference: PrimitiveDifference<unknown>) => {
    const { type, previousValue, derivedValue } = difference;

    if (typeof(derivedValue) !== 'boolean' || type !== DifferenceType.Updated) {
        return type;
    }

    return previousValue ? DifferenceType.Removed : DifferenceType.Added;
}

export const DifferenceField = ({
    difference,
    formatter = (x) => x,
    children,
}: DifferenceFieldProps) => {
    if (!difference) {
        return <>{children}</>;
    }

    const { previousValue, derivedValue } = difference;
    const isNumeric = typeof derivedValue === 'number'

    const differenceType = getDifferenceType(difference);

    const renderContent = () => (
        <div className={classNames('difference-highlight', differenceType)}>
            <div className="difference-highlight-text">
                {differenceType === DifferenceType.Removed ? (
                    <span>{formatter(derivedValue)}</span>
                ) : (
                    <>{children}</>
                )}
            </div>
        </div>
    );

    if (differenceType !== DifferenceType.Updated) {
        return renderContent();
    }

    return (
        <>
            <OnHoverTooltip overlay={`Updated from ${formatter(previousValue)}`}>
                {renderContent()}
            </OnHoverTooltip>
            {isNumeric && <DynamicsIcon difference={difference} />}
        </>
    )
};
