import React from "react";
import { constants } from "../../../../../../constants";
import { OriginatingTransaction } from "../../../../../../types/amr-pipeline/models/OriginatingTransaction";
import { OriginatingTransactionDiff } from '../../../../../../types/amr-pipeline/models/OriginatingTransactionDiff';
import { moneyUtils } from "../../../../../../utils";
import { Difference } from '../../../../../../utils/differ/types';
import { FormField } from "../../../../common/FormField";
import { SectionContent } from "../../../../common/section/SectionContent";
import { DifferenceField } from '../../DifferenceField';
import { ImSubscriptionValue } from '../../../../subscription/ImSubscriptionValue';

interface Props {
    selectedTransaction: OriginatingTransaction;
    difference?: Difference<OriginatingTransactionDiff>;
}

export function SourceAndUses({ selectedTransaction, difference }: Props) {
    return (
        <SectionContent
            title="Sources and Uses"
            className="data-item-sources-and-uses"
        >
            <FormField title="Source - Rated Notes">
                <ImSubscriptionValue transaction={selectedTransaction}>
                    <DifferenceField difference={difference?.ratedNotes}>
                        {selectedTransaction.ratedNotes
                            ? moneyUtils.money(selectedTransaction.ratedNotes)
                            : constants.emptyPlaceholder
                        }
                    </DifferenceField>
                </ImSubscriptionValue>
            </FormField>
            <FormField title="Source - Sub Notes">
                <ImSubscriptionValue transaction={selectedTransaction}>
                    <DifferenceField difference={difference?.subNotes}>
                        {selectedTransaction.subNotes
                            ? moneyUtils.money(selectedTransaction.subNotes)
                            : constants.emptyPlaceholder
                        }
                    </DifferenceField>
                </ImSubscriptionValue>
            </FormField>
            <FormField title="Uses - Purchase Assets">
                <ImSubscriptionValue transaction={selectedTransaction}>
                    <DifferenceField difference={difference?.purchaseAssets}>
                        {selectedTransaction.purchaseAssets
                            ? moneyUtils.money(selectedTransaction.purchaseAssets)
                            : constants.emptyPlaceholder
                        }
                    </DifferenceField>
                </ImSubscriptionValue>
            </FormField>
            <FormField title="Uses - Upfront Fees & Expenses">
                <ImSubscriptionValue transaction={selectedTransaction}>
                    <DifferenceField difference={difference?.upfrontFeesAndExpenses}>
                        {selectedTransaction.upfrontFeesAndExpenses
                        ? moneyUtils.money(selectedTransaction.upfrontFeesAndExpenses)
                        : constants.emptyPlaceholder
                        }
                    </DifferenceField>
                </ImSubscriptionValue>
            </FormField>
            <FormField title="Uses - Interest Reserves">
                <ImSubscriptionValue transaction={selectedTransaction}>
                    <DifferenceField difference={difference?.interestReserve}>
                        {selectedTransaction.interestReserve
                            ? moneyUtils.money(selectedTransaction.interestReserve)
                            : constants.emptyPlaceholder
                        }
                    </DifferenceField>
                </ImSubscriptionValue>
            </FormField>
        </SectionContent>
    );
}
