import cn from 'classnames';
import { isNil } from 'lodash';
import { Link } from "react-router-dom";
import { routes } from "../../../constants";
import IconSVG from "../../../styles/svg-icons";
import { OnHoverTooltip } from "../../common/OnHoverTooltip";

interface TransactionHistoryProps {
    referenceName: string;
    dealReferenceName: string;
    target?: React.HTMLAttributeAnchorTarget;
    overlayText?: string;
    label?: boolean;
    version?: number;
    disabled?: boolean;
    onClick?: () => void;
}

export const TransactionHistoryLink = ({
    referenceName,
    dealReferenceName,
    target = '_blank',
    overlayText = 'Transaction History',
    label = true,
    disabled = false,
    version,
    onClick,
}: TransactionHistoryProps) =>
    !disabled ? (
        <OnHoverTooltip
            overlay={label ? overlayText : `Transaction History: V.${version}`}
            wrapperClass={cn({ 'history-box': label })}
        >
            <Link
                to={routes.transactionHistoryUrl(referenceName, dealReferenceName)}
                onClick={onClick}
                className="btn-link btn-link-history"
                target={target}
            >
                <IconSVG name="timeline" width={16} height={16} />
                {label && <span>Trans History </span>}
            </Link>
            {label && !isNil(version) && <span className="history-version">V.{version}</span>}
        </OnHoverTooltip>
    ) : (
        <button className="btn-link btn-link-history" disabled>
            <IconSVG name="timeline" width={16} height={16} />
            {label && <span>Trans History </span>}
        </button>
    );
