import { useEffect, useMemo, useState } from "react";
import { EmptyPlaceholder } from "../../common";
import classnames from "classnames";
import { SelectionPanelSearch } from '../common/SelectionPanelSearch';
import { PipelineHistoryItem } from '../../../types/state/PipelineHistoryState';
import { TransactionHistoryNavItem } from './TransactionHistoryNavItem/TransactionHistoryNavItem';
import { getIndicatorsConfig } from './TransactionHistoryNavItem/indicators-config';
import { pipelineHistoryFields } from '../../../constants/amr-pipeline/pipelineHistoryFields';
import { compareStrings } from "../../../utils/compare.utils";
import { AutoCompleteSourceItem } from "../types/AutoCompleteInputSourceItem";
import { TransactionStatus } from "../../../types/amr-pipeline/enums/TransactionStatus";
import { moneyUtils, numericUtils } from "../../../utils";
import { Company } from "../../../types/amr-pipeline/models/Company";
import { hasLimitedAccess } from "../../../utils/amr-pipeline.utils";
import { dealUpdatesIdentifiersTitles } from '../../../types/amr-pipeline/enums/DealUpdateIdentifier';

interface Props {
    history: PipelineHistoryItem[];
    selectedItem: PipelineHistoryItem;
    allHistoryClassesList?: AutoCompleteSourceItem[];
    userCompany?: Company;
    isLoading: boolean;
    onChange: (version: number) => void;
}

export const TransactionHistorySelectionPanel = ({
    history,
    isLoading,
    selectedItem,
    userCompany,
    allHistoryClassesList,
    onChange,
}: Props) => {
    const [collapsed, setCollapsed] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const limitedAccess = hasLimitedAccess(history[history.length - 1].transaction, userCompany);

    const suggestedList = useMemo(() => allHistoryClassesList ? [...pipelineHistoryFields, ...allHistoryClassesList] : pipelineHistoryFields, [allHistoryClassesList]);

    const searchTermValues = useMemo(() => {
        if (!searchTerm) {
            return [];
        }

        const searchValue = suggestedList.find(f => f.text === searchTerm)?.searchTerm || searchTerm;
        const synonyms = suggestedList.reduce((acc: string[], field) => {
            const lowerSearchTerm = searchTerm.toLocaleLowerCase();

            const synonym = field.synonyms?.find(s => s.toLowerCase().includes(lowerSearchTerm));

            if (!synonym) {
                return acc;
            }

            return [...acc, field.searchTerm ? field.searchTerm.toLowerCase() : field.text.toLowerCase()];
        }, []);
        const moneySynonym = numericUtils.isNumber(searchValue) && moneyUtils.money(searchValue);

        return moneySynonym ? [...synonyms, searchValue, moneySynonym] : [...synonyms, searchValue];
    }, [searchTerm, suggestedList]);

    useEffect(() => {
        var customEvent = document.createEvent("Events");
        customEvent.initEvent("resize", true, true);
        window.dispatchEvent(customEvent);
    }, [collapsed]);

    const navItems = history.map(historyItem => {
        const indicatorSections = getIndicatorsConfig(
            historyItem.diff,
            limitedAccess,
            searchTermValues,
        );

        const dealUpdatesMatchesSearchTerms = historyItem.transaction.dealUpdates?.some(d =>
            searchTermValues?.some(s => dealUpdatesIdentifiersTitles[d].toLowerCase().includes(s.toLowerCase())),
        );

        if (!indicatorSections.length && !dealUpdatesMatchesSearchTerms) {
            return undefined;
        }

        const versionClosingDate = historyItem.transaction.status === TransactionStatus.Closed
            && historyItem.transaction.closingDate
                ? historyItem.transaction.closingDate
                : undefined;

        return (
            <TransactionHistoryNavItem
                dealUpdatesIdentifiers={historyItem.transaction.dealUpdates}
                selected={selectedItem.version === historyItem.version}
                searchTerms={searchTermValues}
                onViewClick={onChange}
                indicatorSections={indicatorSections}
                version={historyItem.version}
                versionTitle={historyItem.versionTitle}
                versionDate={historyItem.eventDateTime}
                versionClosingDate={versionClosingDate}
                difference={historyItem.diff}
            />
        );
    }).filter(item => !!item);

    const isEmpty = !navItems.length || (!history.length && !isLoading);

    return (
        <aside className={classnames("sidebar sidebar-nav sidebar-nav-history", { collapsed })}>
            <SelectionPanelSearch
                suggestedList={suggestedList.sort((a,b) => compareStrings(a.text, b.text))}
                searchTerm={searchTerm}
                collapsed={collapsed}
                placeholder="Search by field or value"
                onCollapseClick={setCollapsed}
                onSearch={setSearchTerm}
            />
                <nav className="sidebar-nav-list sidebar-nav-list-mixed">
                    {isEmpty && <EmptyPlaceholder textView />}
                    {navItems}
                </nav>
        </aside>
    );
};
