import { useEffect } from "react";
import { SearchInput, OnHoverTooltip } from "../../common";
import IconSVG from "../../../styles/svg-icons";
import { AutoCompleteInput } from "./AutoCompleteInput";
import { AutoCompleteSourceItem } from "../types/AutoCompleteInputSourceItem";

interface Props {
    searchTerm: string;
    placeholder?: string;
    collapsed: boolean;
    showCollapseButton?: boolean;
    suggestedList?: AutoCompleteSourceItem[];
    prevRoute?: string;
    onSearch: (searchTerm: string) => void;
    onCollapseClick?: (collapsed: boolean) => void;
}

export const SelectionPanelSearch = ({
    searchTerm,
    suggestedList = [],
    placeholder = 'Search',
    collapsed,
    showCollapseButton = true,
    onSearch,
    onCollapseClick,
}: Props) => {

    useEffect(() => {
        var customEvent = document.createEvent('Events');
        customEvent.initEvent('resize', true, true);
        window.dispatchEvent(customEvent);
    }, [collapsed]);

    return (
        <div className="sidebar-nav-header">
            {suggestedList ? (
                <AutoCompleteInput
                    expandOnFocus
                    highlightResults
                    keyboardControl={false}
                    showNoResultsPlaceholder={false}
                    className="form-control form-control-search"
                    maxLength={256}
                    onClear={() => onSearch('')}
                    sourceItems={suggestedList}
                    value={searchTerm}
                    onChange={onSearch}
                    placeholder={placeholder}
                />
            ) : (
                <SearchInput
                    value={searchTerm}
                    onClear={() => onSearch('')}
                    onChange={onSearch}
                    placeholder={placeholder}
                />
            )}
            <div className="sidebar-nav-header-btn flex-none position-relative">
                {showCollapseButton && onCollapseClick && (
                    <OnHoverTooltip overlay={collapsed ? 'Expand menu panel' : 'Collapse menu panel'}>
                        <button
                            className="btn btn-secondary btn-sidebar-expand"
                            onClick={() => onCollapseClick(!collapsed)}
                        >
                            <IconSVG name="collapse-left" width={16} height={16} />
                        </button>
                    </OnHoverTooltip>
                )}
            </div>
        </div>
    );
};
