import React from "react";
import { constants } from "../../../../../../constants";
import { OriginatingTransaction } from "../../../../../../types/amr-pipeline/models/OriginatingTransaction";
import { OriginatingTransactionDiff } from '../../../../../../types/amr-pipeline/models/OriginatingTransactionDiff';
import { formatUtils, moneyUtils } from "../../../../../../utils";
import { Difference } from '../../../../../../utils/differ/types';
import { FormField } from "../../../../common/FormField";
import { SectionContent } from "../../../../common/section/SectionContent";
import { DifferenceField } from '../../DifferenceField';
import { ImSubscriptionValue } from '../../../../subscription/ImSubscriptionValue';

interface Props {
    selectedTransaction: OriginatingTransaction;
    difference?: Difference<OriginatingTransactionDiff>;
}

export function AdministrativeFee({ selectedTransaction, difference }: Props) {
    return (
        <SectionContent
            title="Administrative Fee"
            className="data-item-administrative-fee"
        >
            <FormField title="Admin Expense Cap, bps">
                <ImSubscriptionValue transaction={selectedTransaction}>
                    <DifferenceField difference={difference?.adminExpenseCapPercentage} formatter={formatUtils.formatDecimal}>
                        {formatUtils.formatDecimal(selectedTransaction.adminExpenseCapPercentage) || constants.emptyPlaceholder}
                    </DifferenceField>
                </ImSubscriptionValue>
            </FormField>
            <FormField title="Admin Expense Cap, $">
                <ImSubscriptionValue transaction={selectedTransaction}>
                    <DifferenceField difference={difference?.adminExpenseCap} formatter={moneyUtils.money}>
                        {moneyUtils.money(selectedTransaction.adminExpenseCap) || constants.emptyPlaceholder}
                    </DifferenceField>
                </ImSubscriptionValue>
            </FormField>
            <FormField title="Trustee, bps">
                <ImSubscriptionValue transaction={selectedTransaction}>
                    <DifferenceField difference={difference?.trusteeFee} formatter={formatUtils.formatDecimal}>
                        {formatUtils.formatDecimal(selectedTransaction.trusteeFee) || constants.emptyPlaceholder}
                    </DifferenceField>
                </ImSubscriptionValue>
            </FormField>
        </SectionContent>
    );
}
