import * as uuid from 'uuid';
import { Difference, DifferenceType, PrimitiveDifference } from '../../../../utils/differ/types';
import Highlighter from '../../common/Highlighter';
import { DynamicsIcon } from './DynamicsIcon';
import { Formatter, SimpleIndicator } from './types';
import { defaultFormatter, indicatorMatchesSearchTerm } from './utils';

export class PrimitiveIndicator<T> implements SimpleIndicator<T> {
    public uid = uuid.v1();

    constructor(
        public title: string,
        protected getValueDifference: (difference: Difference<T>) => PrimitiveDifference<unknown> | undefined,
        protected formatter: Formatter<T> = defaultFormatter,
    ) {}

    matchesSearchTerm(searchTerms: string[], difference: Difference<T>) {
        const valueDifference = this.getValueDifference(difference);

        if (!valueDifference) {
            return false;
        }

        const { type, currentValue, previousValue, derivedValue } = valueDifference;
        const formatter = this.formatter;

        switch (type) {
            case DifferenceType.Added:
                return searchTerms.some(
                    s =>
                        String(formatter(currentValue)).toLowerCase().includes(s.toLowerCase()) ||
                        String(currentValue).toLowerCase().includes(s.toLowerCase()),
                );
            case DifferenceType.Updated:
                return searchTerms.some(
                    s =>
                        String(formatter(currentValue)).toLowerCase().includes(s.toLowerCase()) ||
                        String(formatter(previousValue)).toLowerCase().includes(s.toLowerCase()) ||
                        String(currentValue).toLowerCase().includes(s.toLowerCase()) ||
                        String(currentValue).toLowerCase().includes(s.toLowerCase()),
                );
            case DifferenceType.Removed:
                return searchTerms.some(
                    s =>
                        String(formatter(derivedValue)).toLowerCase().includes(s.toLowerCase()) ||
                        String(derivedValue).toLowerCase().includes(s.toLowerCase()),
                );
        }

        return false;
    }

    renderHighlighter(searchTerms: string[], value: unknown) {
        return <Highlighter searchWords={searchTerms} autoEscape={true} textToHighlight={String(value)} />;
    }

    render(searchTerms: string[] = [], difference?: Difference<T>) {
        if (!difference) {
            return null;
        }

        const valueDifference = this.getValueDifference(difference);

        if (!valueDifference) {
            return null;
        }

        if (searchTerms.some(s => !!s.length) && !indicatorMatchesSearchTerm(searchTerms, difference)(this)) {
            return null;
        }

        const { type, currentValue, previousValue } = valueDifference;

        const formatter = this.formatter;

        const title = this.renderHighlighter(searchTerms, this.title);

        switch (type) {
            case DifferenceType.Added:
                return (
                    <span>
                        {title} <strong>{this.renderHighlighter(searchTerms, formatter(currentValue))}</strong> added
                    </span>
                );
            case DifferenceType.Updated:
                return (
                    <span className="update-size">
                        {title} updated from{' '}
                        <strong>{this.renderHighlighter(searchTerms, formatter(previousValue))}</strong> to{' '}
                        <strong className="update-size-to">{this.renderHighlighter(searchTerms, formatter(currentValue))}</strong>
                        <DynamicsIcon difference={valueDifference} />
                    </span>
                );
            case DifferenceType.Removed:
                return <span>{title} removed</span>;
            default:
                return null;
        }
    }

    hasChanges(difference: Difference<T>) {
        const valueDifference = this.getValueDifference(difference);

        if (!valueDifference) {
            return false;
        }

        return valueDifference.type !== DifferenceType.Unchanged;
    }
}
