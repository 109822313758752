import classNames from "classnames";
import moment from "moment";
import { constants } from "../../../../../../constants";
import { TransactionType } from "../../../../../../types/amr-pipeline/enums/TransactionType";
import { OriginatingTransaction } from "../../../../../../types/amr-pipeline/models/OriginatingTransaction";
import { OriginatingTransactionDiff } from '../../../../../../types/amr-pipeline/models/OriginatingTransactionDiff';
import { dateTimeUtils } from "../../../../../../utils";
import { amrFormatUtils } from "../../../../../../utils/amr-format.utils";
import { Difference } from '../../../../../../utils/differ/types';
import { FormField } from "../../../../common/FormField";
import { SectionContent } from "../../../../common/section/SectionContent";
import { DifferenceField } from '../../DifferenceField';
import { ImSubscriptionValue } from '../../../../subscription/ImSubscriptionValue';

interface Props {
    selectedTransaction: OriginatingTransaction;
    difference?: Difference<OriginatingTransactionDiff>;
}

const composeDateWithPeriodLeft = (value: string, periodLeft: string | 0) =>
    value + (periodLeft ? ' [' + periodLeft + ']' : '');

const dateFormatter = (value?: Date) => value
    ? moment(value).format(constants.dateFormat)
    : constants.emptyPlaceholder;

const dateWithPeriodFormatter = (periodLeft: string | 0) => (value?: Date) => {
    if(!value) {
        return constants.emptyPlaceholder;
    }

    const formattedDate = dateFormatter(value)
    return composeDateWithPeriodLeft(formattedDate, periodLeft);
}


const rollerDeadlineByTypeFormatter = (type: TransactionType) => (value?: Date) =>
    type !== TransactionType.newIssue && value
        ? amrFormatUtils.formatRollerDeadlineDate(moment(value))
        : constants.emptyPlaceholder;

const vintageByTypeFormatter = (type: TransactionType, timeLeft: string | 0) => (value?: Date) => {
    if(!value) {
        return constants.emptyPlaceholder;
    }

    const year = moment(value).year().toString();
    return type === TransactionType.refi ? composeDateWithPeriodLeft(year, timeLeft) : year;
}

export function Dates({ selectedTransaction, difference }: Props) {
    const {
        pricingDate,
        rollerDeadline,
        nonCallPeriodEnd,
        closingDate,
        reinvestmentPeriodEnd,
        firstPaymentDate,
        statedMaturity,
        vintage
    } = selectedTransaction;

    const { yrsLeftToDateFrom } = dateTimeUtils;

    const nonCallPeriodEndTimeLeft = yrsLeftToDateFrom(nonCallPeriodEnd, closingDate);
    const reinvestmentPeriodEndTimeLeft = yrsLeftToDateFrom(reinvestmentPeriodEnd, closingDate);
    const firstPaymentDateTimeLeft = yrsLeftToDateFrom(firstPaymentDate, closingDate);
    const statedMaturityTimeLeft = yrsLeftToDateFrom(statedMaturity, closingDate);
    const vintageTimeLeft = yrsLeftToDateFrom(closingDate, vintage);

    const rollerDeadlineFormatter = rollerDeadlineByTypeFormatter(selectedTransaction.type);
    const nonCallPeriodEndFormatter = dateWithPeriodFormatter(nonCallPeriodEndTimeLeft);
    const reinvestmentPeriodEndFormatter = dateWithPeriodFormatter(reinvestmentPeriodEndTimeLeft);
    const firstPaymentDateFormatter = dateWithPeriodFormatter(firstPaymentDateTimeLeft);
    const statedMaturityFormatter = dateWithPeriodFormatter(statedMaturityTimeLeft);
    const vintageFormatter = vintageByTypeFormatter(selectedTransaction.type, vintageTimeLeft);

    return (
        <SectionContent
            title="Key Dates / Target Dates"
            className="data-item-general-information data-item-general-cols"
        >
            <div className="data-item-row-col">
                <FormField
                    className={classNames('data-item-row-full-height', {today: dateTimeUtils.isToday(rollerDeadline)})}
                    title="Roller Deadline (EST)"
                >
                    <DifferenceField difference={difference?.rollerDeadline} formatter={rollerDeadlineFormatter}>
                        {rollerDeadlineFormatter(rollerDeadline)}
                    </DifferenceField>
                </FormField>
                <FormField title="Target Pricing" className="data-item-row-full-height">
                    <DifferenceField difference={difference?.pricingDate} formatter={dateFormatter}>
                        {dateFormatter(pricingDate)}
                    </DifferenceField>
                </FormField>
                <FormField title="Closing Date" className="data-item-row-full-height">
                    <DifferenceField difference={difference?.closingDate} formatter={dateFormatter}>
                        {dateFormatter(closingDate)}
                    </DifferenceField>
                </FormField>
                <FormField title="First Payment Date" className="data-item-row-full-height">
                    <DifferenceField difference={difference?.firstPaymentDate} formatter={firstPaymentDateFormatter}>
                        {firstPaymentDateFormatter(firstPaymentDate)}
                    </DifferenceField>
                </FormField>
            </div>
            <div className="data-item-row-col">
                <FormField title="Non-Call End" className="data-item-row-full-height">
                    <DifferenceField difference={difference?.nonCallPeriodEnd} formatter={nonCallPeriodEndFormatter}>
                        {nonCallPeriodEndFormatter(nonCallPeriodEnd)}
                    </DifferenceField>
                </FormField>
                <FormField title="Reinvestment End" className="data-item-row-full-height">
                    <DifferenceField difference={difference?.reinvestmentPeriodEnd} formatter={reinvestmentPeriodEndFormatter}>
                        {reinvestmentPeriodEndFormatter(reinvestmentPeriodEnd)}
                    </DifferenceField>
                </FormField>

                <FormField title="Maturity" className="data-item-row-full-height">
                    <DifferenceField difference={difference?.statedMaturity} formatter={statedMaturityFormatter}>
                        {statedMaturityFormatter(statedMaturity)}
                    </DifferenceField>
                </FormField>

                {!difference && (
                    <FormField title="Vintage" className="data-item-row-full-height">
                        <ImSubscriptionValue transaction={selectedTransaction}>
                            {vintageFormatter(vintage)}
                        </ImSubscriptionValue>
                    </FormField>
                )}
            </div>
        </SectionContent>
    );
}
