import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { ActionBlocker } from '../../access/ActionBlocker';

type TRenderChildren = (blocked: boolean) => React.ReactNode;

export const ImSubscriptionActionBlocker = ({
    children,
    overrideRequiredFeatures,
}: {
    children: React.ReactNode | TRenderChildren;
    overrideRequiredFeatures?: boolean;
}) => (
    <ActionBlocker
        overrideRequiredFeatures={overrideRequiredFeatures}
        feature={SubscriptionFeature.IssuanceMonitorFullAccess}
    >
        {children}
    </ActionBlocker>
);
