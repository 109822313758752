import * as React from 'react';
import { constants } from '../../../constants/constants';
import { Pluralize } from '../../common';


interface NonAmrPeriodProps {
    period?: number,
    emptyValue?: string
}

export function NonAmrPeriod({ period, emptyValue = constants.emptyPlaceholder }: NonAmrPeriodProps) {
    if (!period) {
        return <>{emptyValue}</>;
    }

    return (
        <><Pluralize count={period} singular="month" /></>
    );
}
