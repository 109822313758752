import IconSVG from "../../../styles/svg-icons";
import cn from "classnames";
import { constants } from "../../../constants";
import { ImSubscriptionActionBlocker } from "../../amrPipeline/subscription/ImSubscriptionActionBlocker";
import { OnHoverTooltip } from "../../common";
import { TransactionStatus } from "../../../types/amr-pipeline/enums/TransactionStatus";
import {
    Tooltip,
    TooltipPlacement,
    TooltipTriggerType,
} from "../../common/Tooltip";
import { useMemo, useState } from "react";
import { ContextMenuItem } from "../../controls/ContextMenuItem";
import { useDispatch } from "react-redux";
import { AddToPortfolioPopup } from "../../portfolio/add-to-portfolio/AddToPortfolioPopup";
import { addToPortfolioActions } from "../../../actions/add-to-portfolio.actions";
import { Transaction } from "../../../types/amr-pipeline/models/Transaction";
import { Currency } from "../../../types/enums/Currency";
import { OriginatingTransactionClass } from "../../../types/amr-pipeline/models/OriginatingTransactionClass";
import { usePortfolios } from "../../../effects/usePortfolios";
import { AmrClass } from "../../../types/amr-pipeline/models/AmrClass";
import { TransactionType } from "../../../types/amr-pipeline/enums/TransactionType";
import { PortfolioSecurity } from "../../../types/portfolio/PortfolioSecurity";
import { Rating } from "../../../types/enums/Rating";

const AddToPortfolioIcon = () => (
    <IconSVG name="add-to-portfolio" width={16} height={16} />
);

interface Identifier {
    isinCusip: string;
    ticker: string;
}

interface IdentifierList extends Identifier {
    label: string;
}

interface Props {
    wrapperClassName?: string;
    className?: string;
    disabled?: boolean;
    checkSubscription?: boolean;
    transaction: Transaction;
    transactionClass: OriginatingTransactionClass | AmrClass;
    withText?: boolean;
    withEmptyPlaceholder?: boolean;
}
export function AddToPortfolioLink({
    wrapperClassName,
    className,
    disabled = false,
    checkSubscription = false,
    transaction,
    transactionClass,
    withText,
    withEmptyPlaceholder = true,
}: Props) {
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const [selectedIdentifier, setSelectedIdentifier] =
        useState<Identifier | null>(null);
    const { status, currency, type } = transaction;
    const { rating } = transactionClass;
    const tranche =
        type === TransactionType.amr
            ? (transactionClass as AmrClass).class
            : (transactionClass as OriginatingTransactionClass).tranche;

    const isEuro = currency && currency.code === Currency.EUR;

    const cusiP144A = tranche?.cusiP144A;
    const cusipRegS = tranche?.cusipRegS;
    const cusipAccdInvCertif = tranche?.cusipAccdInvCertif;

    const isiN144A = tranche?.isiN144A;
    const isinRegS = tranche?.isinRegS;
    const isinAccdInvCertif = tranche?.isinAccdInvCertif;

    const ticker144A = tranche?.ticker144A;
    const tickerRegS = tranche?.tickerRegS;
    const tickerAccdInvCertif = tranche?.tickerAccdInvCertif;

    const identifier144A = isEuro ? isiN144A : cusiP144A;
    const identifierRegS = isEuro ? isinRegS : cusipRegS;
    const identifierAccdInvCertif = isEuro ? isinAccdInvCertif : cusipAccdInvCertif;

    const dispatch = useDispatch();
    const { items: portfolios } = usePortfolios(true);

    const identifierList = [
        { isinCusip: identifier144A, ticker: ticker144A, label: "144A" },
        { isinCusip: identifierRegS, ticker: tickerRegS, label: "Reg S" },
        {
            isinCusip: identifierAccdInvCertif,
            ticker: tickerAccdInvCertif,
            label: "Acc’d Inv./Certif.",
        },
    ].filter((c) => !!c.isinCusip && !!c.ticker) as IdentifierList[];

    const withButton = !!(
        status === TransactionStatus.Closed &&
        identifierList.length &&
        rating
    );

    const security = useMemo(() => {
        let result: PortfolioSecurity | undefined;
        if (withButton) {
            for (let i = 0; i < portfolios.length; i++) {
                result = portfolios[i].securities.find(
                    (s) =>
                        s.isinCusip === selectedIdentifier?.isinCusip ||
                        s.isinCusip === identifier144A ||
                        s.isinCusip === identifierRegS ||
                        s.isinCusip === identifierAccdInvCertif
                );
                if (!!result) {
                    break;
                }
            }
        }
        return result;
    }, [
        selectedIdentifier?.isinCusip,
        identifier144A,
        identifierRegS,
        identifierAccdInvCertif,
        portfolios,
        withButton,
    ]);

    if (!withButton) {
        return withEmptyPlaceholder ? (
            <span className="action-empty-placeholder">
                {constants.emptyPlaceholder}
            </span>
        ) : null;
    }

    if (disabled) {
        return (
            <button className={cn("btn-link", className)} disabled={disabled}>
                <AddToPortfolioIcon />
            </button>
        );
    }

    const handleVisibilityChange = (isVisible: boolean) =>
        setIsTooltipVisible(isVisible);

    const handleClose = () => {
        setIsPopupVisible(false);
        setSelectedIdentifier(null);
        dispatch(addToPortfolioActions.reset());
    };

    const renderBlockedView = () => (
        <button className={cn("btn-link", className)} disabled>
            <AddToPortfolioIcon />
        </button>
    );
    const getMenuItems = () =>
        identifierList.map(({ isinCusip, ticker, label }) => (
            <ContextMenuItem
                onClick={() => {
                    setIsTooltipVisible(false);
                    setIsPopupVisible(true);
                    setSelectedIdentifier({ isinCusip, ticker });
                }}
                key={label}
            >
                {isinCusip} <span>({label})</span>
            </ContextMenuItem>
        ));

    const renderTooltip = () => {
        const withAddedItem = !!security;
        return (
            <Tooltip
                overlayClassName={cn(
                    "kp-popover add-portfolio-popover",
                    className
                )}
                placement={TooltipPlacement.BottomRight}
                onVisibleChange={handleVisibilityChange}
                trigger={TooltipTriggerType.Click}
                visible={isTooltipVisible}
                overlay={() =>
                    withAddedItem ? null : (
                        <div className="options-list">
                            <ul>{getMenuItems()}</ul>
                        </div>
                    )
                }
            >
                <span onMouseEnter={(e) => e.stopPropagation()}>
                    <OnHoverTooltip
                        overlay={
                            withAddedItem
                                ? "Added to Portfolio"
                                : "Add to Portfolio"
                        }
                        disabled={isTooltipVisible}
                    >
                        <button
                            className="btn-link"
                            onClick={
                                withAddedItem
                                    ? () => setIsPopupVisible(true)
                                    : undefined
                            }
                        >
                            {withAddedItem ? (
                                <IconSVG
                                    name="added-to-portfolio"
                                    width={16}
                                    height={16}
                                />
                            ) : (
                                <AddToPortfolioIcon />
                            )}
                            {withText &&
                                (withAddedItem ? (
                                    <span>Added to Portfolio</span>
                                ) : (
                                    <span>Add to Portfolio</span>
                                ))}
                        </button>
                    </OnHoverTooltip>
                </span>
            </Tooltip>
        );
    };

    const renderPopup = () => {
        const isinCusip = security?.isinCusip || selectedIdentifier?.isinCusip;
        const ticker = security?.ticker || selectedIdentifier?.ticker;
        if (!isPopupVisible || !isinCusip || !ticker) {
            return null;
        }
        const positionCurrency = security?.currency || currency?.code;
        const position = {
            isinCusip,
            securityId: security?.securityId,
            size: security?.size || 0,
            ticker,
            currency: positionCurrency
                ? (positionCurrency as Currency)
                : Currency.USD,
            rating: (security?.rating || rating) as Rating,
        };

        return (
            <AddToPortfolioPopup onClose={handleClose} position={position} />
        );
    };

    return (
        <ImSubscriptionActionBlocker
            overrideRequiredFeatures={!checkSubscription}
        >
            {(blocked) => (
                <div className={wrapperClassName}>
                    {blocked ? (
                        renderBlockedView()
                    ) : (
                        <>
                            {renderTooltip()}
                            {renderPopup()}
                        </>
                    )}
                </div>
            )}
        </ImSubscriptionActionBlocker>
    );
}
