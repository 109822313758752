import { TransactionStatus } from "../../../types/amr-pipeline/enums/TransactionStatus";
import { OriginatingTransaction } from "../../../types/amr-pipeline/models/OriginatingTransaction";
import { Transaction } from "../../../types/amr-pipeline/models/Transaction";
import { OnHoverTooltip } from "../../common/OnHoverTooltip";

interface NewAmrDebutLabelProps {
    transaction: Transaction;
    ignoreDebut?: boolean;
}

const labelClassName = "state-new";

export const NewDebutAmrLabel = ({ transaction, ignoreDebut }: NewAmrDebutLabelProps) => {
    const { isNew, status } = transaction;
    const { isDebut, amr } = transaction as OriginatingTransaction;

    const showClosedAmr = amr && status === TransactionStatus.Closed;
    const showDebut = isDebut && !ignoreDebut; 

    if (isNew) {
        return <div className={labelClassName}>new</div>;
    }

    if (showDebut && showClosedAmr) {
        return (
            <OnHoverTooltip
                overlay="AMR, Debut Manager’s CLO"
                wrapperClass={labelClassName}
            >
                AMR(+1)
            </OnHoverTooltip>
        );
    }

    if (showClosedAmr) {
        return (
            <OnHoverTooltip overlay="Deal with AMR" wrapperClass={labelClassName}>
                AMR
            </OnHoverTooltip>
        );
    }

    if (showDebut) {
        return (
            <OnHoverTooltip
                overlay="Debut Manager's CLO"
                wrapperClass={labelClassName}
            >
                debut
            </OnHoverTooltip>
        );
    }

    return null;
};
