import React, { useState, ReactNode } from 'react';
import classNames from 'classnames';
import IconSVG from '../../../styles/svg-icons';

interface ShowMoreProps {
    defaultExpanded?: boolean;
    showMoreText?: string;
    showLessText?: string;
    className?: string;
    renderMore: () => ReactNode;
    renderLess: () => ReactNode;
}

export const ShowMore = ({
    defaultExpanded = false,
    showMoreText = 'Show More',
    showLessText = 'Show Less',
    className = "btn btn-ghost btn-ghost-secondary btn-sm",
    renderMore,
    renderLess,
}: ShowMoreProps) => {
    const [expanded, setExpanded] = useState(defaultExpanded);

    return (
        <>
            {expanded && renderMore()}
            {!expanded && renderLess()}
            <div className="show-more-wrap">
                <button
                    className={className}
                    onClick={() => setExpanded(prev => !prev)}
                >
                    {expanded ? showLessText : showMoreText}
                    <IconSVG className={classNames({ 'is-open': expanded })} name="icon-expand" width={16} height={16} />
                </button>
            </div>
        </>
    )
};
