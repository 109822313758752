import { Difference, DifferenceType, PrimitiveDifference } from '../../../../utils/differ/types';
import Highlighter from '../../common/Highlighter';
import { PrimitiveIndicator } from './PrimitiveIndicator';
import { indicatorMatchesSearchTerm} from './utils';

/**
 * Represents and renders imprecise indicators (without previous and current values)
 * This is used for strings with long values (comments, descriptions, etc.)
 * Template:
 * - Added:     [Indicator name] added
 * - Updated:   [Indicator name] updated
 * - Removed:   [Indicator name] removed
 */
export class ImpreciseIndicator<T> extends PrimitiveIndicator<T> {
    constructor(
        public title: string,
        protected getValueDifference: (difference: Difference<T>) => PrimitiveDifference<unknown> | undefined,
    ) {
        super(title, getValueDifference);
    }

    render(searchTerms: string[] = [], difference: Difference<T>) {
        const valueDifference = this.getValueDifference(difference);

        if (!valueDifference) {
            return null;
        }

        if (searchTerms.some(s => !!s.length) && !indicatorMatchesSearchTerm(searchTerms, difference)(this)) {
            return null;
        }

        const { type } = valueDifference;

        if (type === DifferenceType.Unchanged) {
            return null;
        }

        const title = <Highlighter searchWords={searchTerms} autoEscape={true} textToHighlight={this.title} />;

        return (
            <span>
                {title} {type}
            </span>
        );
    }
}
