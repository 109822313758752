import * as React from "react";
import { AmrTrancheStatus } from "../../../../../types/amr-pipeline/enums/AmrTrancheStatus";
import { Table } from "../../../../bidding/common/table";
import { TabBody } from "../../../common/tabs/TabBody";
import { classesColumns } from "./classes-columns";
import { AmrTransaction } from "../../../../../types/amr-pipeline/models/AmrTransaction";

interface ClassesProps {
    transaction: AmrTransaction;
}

export function Classes({ transaction }: ClassesProps) {
    return (
        <TabBody className="tabs-data-padding component-tabs-deal-classes">
            <Table
                sticky
                className="data-list-striped"
                dataItems={transaction.classes.filter(c => c.status !== AmrTrancheStatus.Initiated)}
                columns={classesColumns()}
                createSecurityCustomArgs={() => ({ transaction })}
            />
        </TabBody>
    );
}
