import { capitalize, castArray } from 'lodash';
import classNames from 'classnames';
import { DifferenceType, ObjectArrayDifference, PrimitiveDifference } from '../../../../utils/differ/types';

interface BaseDifferenceLabelProps {
    prefix?: string;
}

interface DifferenceLabelProps extends BaseDifferenceLabelProps {
    differenceType: DifferenceType;
}

interface FieldDifferenceLabelProps extends BaseDifferenceLabelProps {
    difference?: PrimitiveDifference<unknown> | (PrimitiveDifference<unknown> | undefined)[];
}

interface TableDifferenceLabelProps extends BaseDifferenceLabelProps {
    difference?: ObjectArrayDifference<unknown[]>;
}

const withPrimitiveDifference = (Component: React.ComponentType<DifferenceLabelProps>) =>
    ({ difference, prefix }: FieldDifferenceLabelProps) => {
        const differenceValues = castArray(difference);

        // Haschanges if not all values are unchanged
        const hasChanges = !differenceValues
            .filter(diff => !!diff)
            .every(diff => diff?.type === DifferenceType.Unchanged);

        if (!hasChanges || !differenceValues.length) {
            return null;
        }

        let [firstDifferenceValue] = differenceValues;

        const differenceType = differenceValues.length > 1
            ? DifferenceType.Updated
            : firstDifferenceValue?.type;

        // Difference values can be undefined
        if (!differenceType) {
            return null;
        }

        return <Component prefix={prefix} differenceType={differenceType} />;
    };

const withObjectArrayDifference = (Component: React.ComponentType<DifferenceLabelProps>) =>
    ({ difference, prefix }: TableDifferenceLabelProps) => {
        const differenceValues = castArray(difference);

        // Haschanges if not all values are unchanged
        const hasChanges = !differenceValues
            .filter(diff => !!diff)
            .every(diff => diff?.type === DifferenceType.Unchanged);

        if (!hasChanges || !differenceValues.length) {
            return null;
        }

        let differenceType: DifferenceType = DifferenceType.Updated;

        if (difference?.every(x => x.type === DifferenceType.Removed)) {
            differenceType = DifferenceType.Removed;
        }

        if (difference?.every(x => x.type === DifferenceType.Added)) {
            differenceType = DifferenceType.Added;
        }

        return <Component prefix={prefix} differenceType={differenceType} />;
    };

export const DifferenceLabel = ({ prefix, differenceType }: DifferenceLabelProps) => {
    const title = prefix ? `${prefix} ${differenceType}` : capitalize(differenceType);

    return (
        <span className={classNames('difference-highlight difference-highlight-label', differenceType)}>{title}</span>
    );
}

/**
 * Show difference for Primitive fields
 */
export const FieldDifferenceLabel = withPrimitiveDifference(DifferenceLabel);

/**
 * Show difference in lists (Object Arrays)
 * Used particularly for Portfolio section
 */
export const TableDifferenceLabel = withObjectArrayDifference(DifferenceLabel);
