import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { constants } from '../../../constants/';
import IconSVG from '../../../styles/svg-icons';
import { EmptyPlaceholder } from '../../common';
import { AutoCompleteSourceItem } from '../types/AutoCompleteInputSourceItem';
import Highlighter from './Highlighter';

interface Props {
    sourceItems: AutoCompleteSourceItem[];
    value: string;
    searchTermAcceptedLength?: number;
    required?: boolean;
    maxLength?: number;
    emptyMessage?: string;
    className?: string;
    placeholder?: string;
    disabled?: boolean;
    pattern?: string;
    keyboardControl?: boolean;
    showNoResultsPlaceholder?: boolean;
    expandOnFocus?: boolean;
    highlightResults?: boolean;
    listName?: string;
    onChange?: (value: string) => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onClear?: () => void;
}

export const AutoCompleteInput = ({
    sourceItems,
    pattern,
    required = false,
    searchTermAcceptedLength = 0,
    maxLength = 256,
    className = 'form-control-search',
    value,
    highlightResults = false,
    placeholder = '',
    expandOnFocus = false,
    disabled = false,
    keyboardControl = true,
    showNoResultsPlaceholder = true,
    listName = 'Suggested searches',
    onChange,
    onBlur,
    onClear
}: Props) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const refScrollableContainer = useRef<HTMLDivElement>(null);
    const refActiveItem = useRef<HTMLTableRowElement | null>(null);
    const [expanded, setExpanded] = useState(false);
    const [index, setIndex] = useState(0);
    const [filteredItems, setFilteredItems] = useState(sourceItems);
    const [focusInput, setFocusInput] = useState(false);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expanded]);

    useEffect(() => {
        if (refScrollableContainer.current && refActiveItem.current && filteredItems.length) {
            const container = refScrollableContainer.current.getBoundingClientRect();
            const item = refActiveItem.current.getBoundingClientRect();
            if (item.top < container.top || item.bottom > container.bottom) {
                refActiveItem.current.scrollIntoView(false);
            }
        }
    }, [filteredItems, index]);

    useEffect(() => {
        if (value) setFilteredItems(getFilteredItems(value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sourceItems]);

    function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        const value = e.target.value;
        setIndex(0);
        setFilteredItems(getFilteredItems(value));
        setExpanded(true);
        document.addEventListener('mousedown', handleClickOutside);

        if (onChange) onChange(value);
    }

    function handleItemSelected(item: AutoCompleteSourceItem) {
        setIndex(0);
        inputRef.current && inputRef.current.focus();
        setExpanded(false);
        onChange && onChange(item.text);
    }

    function handleOnBlur(e: React.FocusEvent<HTMLInputElement>) {
        if (onBlur) onBlur(e);
        setFocusInput(false);
    }

    const handleOnFocus = () => {
        setFocusInput(true);

        if (expandOnFocus) {
          setFilteredItems(value ? getFilteredItems(value) : sourceItems);
          setExpanded(true);
        }
    };

    function getFilteredItems(value: string) {
        const valueLowerCase = value.trim().toLowerCase();

        return sourceItems.filter(i => i.text.toLowerCase().includes(valueLowerCase) || i.synonyms?.some(s => s.toLowerCase().includes(valueLowerCase)));
    }

    function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
      if (!keyboardControl) {
        if (e.keyCode === 13) {
            // enter
            setExpanded(false);
            return;
        }
      }

        if (filteredItems.length) {
            if (e.keyCode === 13) {
                // enter
                handleItemSelected(filteredItems[index]);
            } else if (e.keyCode === 38) {
                // move up
                e.preventDefault();
                if (index > 0) {
                    setIndex(index - 1);
                }
            } else if (e.keyCode === 40 && index < filteredItems.length - 1) {
                // move down
                setIndex(index + 1);
            }
        }
    }

    function renderLookupItem(item: AutoCompleteSourceItem, i: number) {
        if (item.hidden) {
            return null;
        }

        return (
            <tr
                key={i}
                ref={node => (refActiveItem.current = index === i ? node : refActiveItem.current)}
                onClick={() => handleItemSelected(item)}
            >
                <td className="col-main">
                    {highlightResults ? (
                        <Highlighter
                            autoEscape={true}
                            className="title"
                            searchWords={[value]}
                            textToHighlight={item.text}
                        />
                    ) : (
                        item.text
                    )}
                </td>
            </tr>
        );
    }

    function handleClickOutside(e: globalThis.MouseEvent) {
        if (
            expanded &&
            refScrollableContainer.current &&
            !refScrollableContainer.current.contains(e.target as Element) &&
            inputRef.current &&
            inputRef.current !== e.target
        ) {
            setExpanded(false);
        }
    }

    function handleClear() {
        if (inputRef.current) {
            inputRef.current.focus();
        }
        setFilteredItems(sourceItems);
        onClear && onClear();
    }

    return (
        <div className={classNames('control-search-wrapper control-search-autocomplete', { 'control-search-wrapper-focused': focusInput })}>
            <div className="form-control-search-btn flex-none">
                <i className="icon icon-search"></i>
            </div>
            <input
                className={classNames('form-control', className)}
                ref={inputRef}
                type="text"
                onKeyDown={handleKeyDown}
                onBlur={handleOnBlur}
                onFocus={handleOnFocus}
                required={required}
                pattern={pattern || constants.nonWhitespace.source}
                value={value}
                maxLength={maxLength}
                onChange={handleInputChange}
                autoComplete="off"
                placeholder={placeholder}
                disabled={disabled}
            />
            {!!value &&
                <button className="btn-close" onClick={handleClear}>
                    <IconSVG name="close" width={16} height={16} />
                </button>
            }
            {((value && value.length > searchTermAcceptedLength) || expandOnFocus) && expanded && (
                <div
                    ref={refScrollableContainer}
                    className={classNames(
                        "search-lookup",
                        { 'search-lookup-hide': filteredItems.length === 0 && !showNoResultsPlaceholder }
                    )}
                >
                    <table>
                        {filteredItems.length ? (
                            <>
                                <thead>
                                    <tr>
                                        <td className="suggested-search">{listName}</td>
                                    </tr>
                                </thead>
                                <tbody>{filteredItems.map((i, index) => renderLookupItem(i, index))}</tbody>
                            </>
                        ) : (
                            showNoResultsPlaceholder && <EmptyPlaceholder />
                        )}
                    </table>
                </div>
            )}
        </div>
    );
};
