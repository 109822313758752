import { PrimitiveDifference, Dynamics } from '../../../../utils/differ/types';
import IconSVG from '../../../../styles/svg-icons';

interface Props {
    difference?: PrimitiveDifference<unknown>;
}

export const DynamicsIcon = ({ difference }: Props) => {
    if (!difference || !difference.dynamics) {
        return null;
    }

    return difference.dynamics === Dynamics.Decreased
        ? <IconSVG name="arrow-decrease" className="decrease-value" width={12} height={12} />
        : <IconSVG name="arrow-increase" className="increase-value" width={12} height={12} />;
}
