import { useContext, useEffect, useState } from 'react';
import { InfiniteScrollLoader, EmptyPlaceholder } from '../../common';
import classnames from 'classnames';
import { TransactionType } from '../../../types/amr-pipeline/enums/TransactionType';
import { AmrTransaction } from '../../../types/amr-pipeline/models/AmrTransaction';
import { OriginatingTransaction } from '../../../types/amr-pipeline/models/OriginatingTransaction';
import { amrPipelineDetailedActions } from '../../../actions';
import InfiniteScroll from 'react-infinite-scroller';
import { AmrTransactionNavItem } from './amr/AmrTransactionNavItem';
import { OriginatingTransactionNavItem } from './new-issue/OriginatingTransactionNavItem';
import { SelectionPanelSearch } from '../common/SelectionPanelSearch';
import { PipelineDetailedTabTypes } from '../types/PipelineDetailedTabTypes';
import { PipelineTabs, Tab } from '../aggregated/PipelineTabs';
import TransactionContext from '../transactionContext';
import { hasIOIsAccess, withSearchedClassIndicator } from '../../../utils/amr-pipeline.utils';
import { AmrClass } from '../../../types/amr-pipeline/models/AmrClass';
import { OriginatingTransactionClass } from '../../../types/amr-pipeline/models/OriginatingTransactionClass';
import { useDebounce } from '../../../effects/useDebounce';
import { user } from '../../../user';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { BlockedFeatureContent, SubscribeLink } from "../../../components/access/BlockedFeatureText";
import { useAppDispatch } from '../../../effects/useAppDispatch';

interface Props {
    transactions: (OriginatingTransaction | AmrTransaction)[];
    initialTransaction?: OriginatingTransaction | AmrTransaction;
    selectedTransaction?: OriginatingTransaction | AmrTransaction;
    isLoading: boolean;
    isSubmittingIoIs: boolean;
    activeTab: PipelineDetailedTabTypes;
    onTabChange: (tab: PipelineDetailedTabTypes) => void;
    selectedClassReferenceName?: string;
    searchTerm: string;
    hasMoreTransactions?: boolean;
    numberOfHiddenTransactions?: number;
}

export const TransactionsSelectionPanel = ({
    transactions,
    hasMoreTransactions = true,
    isLoading,
    selectedTransaction,
    activeTab,
    isSubmittingIoIs,
    onTabChange,
    initialTransaction,
    searchTerm,
    selectedClassReferenceName,
    numberOfHiddenTransactions,
}: Props) => {
    const { canView } = useContext(TransactionContext);
    const [collapsed, setCollapsed] = useState(!canView);

    const debouncedSearch = useDebounce(searchTerm, 500);

    const dispatch = useAppDispatch();
    const ioisAccess = hasIOIsAccess();

    const withImSubscription = user.hasFeatures(SubscriptionFeature.IssuanceMonitorFullAccess);
    const hasInvisibleDeals = !hasMoreTransactions && !!(numberOfHiddenTransactions && numberOfHiddenTransactions > 0);

    useEffect(() => {
        var customEvent = document.createEvent('Events');
        customEvent.initEvent('resize', true, true);
        window.dispatchEvent(customEvent);
    }, [collapsed]);

    useEffect(() => {
        if (debouncedSearch.length > 2 || debouncedSearch.length === 0) {
            dispatch(amrPipelineDetailedActions.transactionsSearchTermChange(activeTab));
        }
        // eslint-disable-next-line
    }, [debouncedSearch]);

    function handleLoading() {
        if (!isLoading) dispatch(amrPipelineDetailedActions.loadTransactions(activeTab));
    }

    function handleSearchTermChange(value: string) {
        dispatch(amrPipelineDetailedActions.searchTermChange(value));

        if (!value) {
            dispatch(amrPipelineDetailedActions.transactionsSearchTermChange(activeTab));
        }
    }

    function handleChangeTab(tab: PipelineDetailedTabTypes) {
        dispatch(amrPipelineDetailedActions.searchTermChange(''));
        dispatch(amrPipelineDetailedActions.transactionsSearchTermChange(tab));
        onTabChange(tab);
    }

    function renderEmptyPlaceHolder() {
        return <EmptyPlaceholder text={searchTerm.length ? "No search results found" : "No transactions with submitted IOIs yet"} textView />;
    }

    function renderInitialTransaction() {
        if (!initialTransaction) {
            return;
        }


        const isAmr = initialTransaction?.type === TransactionType.amr;

        const shouldInitialTransactionShow =
            !searchTerm.length ||
            initialTransaction?.dealLegalName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            initialTransaction?.dealTicker?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            initialTransaction?.classes.some(
                c => withSearchedClassIndicator(searchTerm, isAmr? (c as AmrClass).class : (c as OriginatingTransactionClass).tranche)
            )

        if (isLoading || !shouldInitialTransactionShow) {
            return;
        }

        if (activeTab === PipelineDetailedTabTypes.ioi && !(initialTransaction as OriginatingTransaction).iois?.length) {
            return;
        }

        if (isAmr) {
            return (
                <AmrTransactionNavItem
                    selectedClassReferenceName={selectedClassReferenceName}
                    selected={initialTransaction.referenceName === selectedTransaction?.referenceName}
                    transaction={initialTransaction as AmrTransaction}
                    searchTerm={searchTerm}
                />
            );
        }

        return (
            <OriginatingTransactionNavItem
                selected={initialTransaction?.referenceName === selectedTransaction?.referenceName}
                transaction={initialTransaction as OriginatingTransaction}
                activeTab={activeTab}
                searchTerm={searchTerm}
            />
        );
    }

    function renderTransactions(transaction: OriginatingTransaction | AmrTransaction) {
        const isInitialTransaction = transaction.referenceName === initialTransaction?.referenceName;

        if (isInitialTransaction) {
            return;
        }

        const key = transaction.referenceName + transaction.dealReferenceName;
        const isSelected =
            transaction.referenceName === selectedTransaction?.referenceName &&
            transaction.dealReferenceName === selectedTransaction?.dealReferenceName;

        if (transaction.type === TransactionType.amr) {
            return (
                <AmrTransactionNavItem
                    key={key}
                    selectedClassReferenceName={selectedClassReferenceName}
                    selected={isSelected}
                    transaction={transaction as AmrTransaction}
                    searchTerm={searchTerm}
                />
            );
        }

        return (
            <OriginatingTransactionNavItem
                key={key}
                selected={isSelected}
                transaction={transaction as OriginatingTransaction}
                searchTerm={searchTerm}
                activeTab={activeTab}
            />
        );

    }

    const renderLastRow = () => {
        if (!hasInvisibleDeals || withImSubscription || isLoading) {
            return null;
        }

        return (
            <BlockedFeatureContent
                inline
                className="invisible-data-placeholder"
                text={<><SubscribeLink /> to see {numberOfHiddenTransactions} more deal(s).</>}
            />
        );
    }

    return (
        <aside className={classnames('sidebar sidebar-nav', { collapsed })}>
            <SelectionPanelSearch
                searchTerm={searchTerm}
                collapsed={collapsed}
                onCollapseClick={setCollapsed}
                showCollapseButton={canView}
                onSearch={handleSearchTermChange}
            />
            {canView && !collapsed && (
                <>
                    {ioisAccess &&
                        <PipelineTabs value={activeTab} disabled={isLoading || isSubmittingIoIs} onChange={handleChangeTab} className="tabs-left-shift-sm">
                            <Tab title="All" key={PipelineDetailedTabTypes.all} value={PipelineDetailedTabTypes.all} />
                            <Tab title="IOIs" key={PipelineDetailedTabTypes.ioi} value={PipelineDetailedTabTypes.ioi} />
                        </PipelineTabs>
                    }
                    <nav className="sidebar-nav-list sidebar-nav-list-mixed">
                        {!transactions.length && !isLoading && renderEmptyPlaceHolder()}

                        {
                            <InfiniteScroll
                                hasMore={hasMoreTransactions}
                                useWindow={false}
                                loadMore={handleLoading}
                                loader={<InfiniteScrollLoader key={1} isNextPageRequesting={isLoading} />}
                                initialLoad={false}
                            >
                                {renderInitialTransaction()}
                                {transactions.map(renderTransactions)}
                                {renderLastRow()}
                            </InfiniteScroll>
                        }
                    </nav>
                </>
            )}
        </aside>
    );
};
