import React from "react";
import { routes } from "../../../constants/routes";
import {
    createAmrPipelineActions,
    createSearchTransactionActions,
} from "../../../actions";
import { TopFilterTypes } from "../../../types/dashboard/RedirectFilterTypes";
import { OnHoverTooltip } from "../../common";
import { roles } from "../../../constants";
import { user } from "../../../user";
import { PipelineType } from '../../../types/amr-pipeline/enums/PipelineType';
import { createFilterActions } from "../../../actions/filter.actions";
import { useAppDispatch } from "../../../effects/useAppDispatch";
import { useHistory } from "react-router";

export interface FilterLinkBaseProps {
    referenceName: string;
    legalName: string;
    code?: string;
    tooltip?: string;
    filter: TopFilterTypes;
    disabled?: boolean;
    onClick?: () => void;
}

interface FilterLinkProps extends FilterLinkBaseProps {
    renderContent: (isLinkAvailable: boolean, onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) => React.ReactNode;
}

export function FilterLink({
    referenceName,
    legalName,
    filter,
    code,
    tooltip,
    disabled = false,
    onClick,
    renderContent,
}: FilterLinkProps) {
    const dispatch = useAppDispatch();
    const history = useHistory();

    // Manager link should change state in Deals tab only
    const amrPipelineActions = createAmrPipelineActions(PipelineType.Deals);
    const searchTransactionsActions = createSearchTransactionActions(PipelineType.Deals);
    const filterActions = createFilterActions(PipelineType.Deals);

    const isMedia = user.hasRoles(roles.Media);
    const isLinkAvailable = (!user.hasRoles(roles.Viewer) || isMedia) && !disabled;

    const apply = () => {
        dispatch(searchTransactionsActions.reset());
        dispatch(filterActions.redirectWithNoFilter());

        dispatch(
            filterActions.setRedirectFilters({
                [filter]: [{
                    value: referenceName,
                    text: legalName + (code ? " (" + code + ")" : ""),
                }],
            })
        );
    };

    const handleClick = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault();

        if (!isLinkAvailable) {
            return;
        }

        if (history.location.pathname === routes.AMRPipeline) {
            if (onClick) {
                onClick();
            }

            dispatch(amrPipelineActions.reset());
            apply();
            dispatch(amrPipelineActions.init());
        } else {
            apply();
        }

        history.push(routes.AMRPipeline);
    };

    return (
        <OnHoverTooltip overlay={disabled ? undefined : tooltip}>
            {renderContent(isLinkAvailable, handleClick)}
        </OnHoverTooltip>
    );
}
