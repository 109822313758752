import { useContext } from 'react';
import { FileIcon } from '../../../../../controls';
import { constants, roles } from '../../../../../../constants';
import { formatUtils } from '../../../../../../utils';
import { OriginatingTransaction } from '../../../../../../types/amr-pipeline/models/OriginatingTransaction';
import { SectionContent } from '../../../../common/section/SectionContent';
import { FormField } from '../../../../common/FormField';
import DownloadDocument from '../../../../common/documents/DownloadDocument';
import { renderMarkdownOrEmpty } from '../../../../../common/markdown-editor';
import { Difference, DifferenceType } from '../../../../../../utils/differ/types';
import { DifferenceField } from '../../DifferenceField';
import { OriginatingTransactionDiff } from '../../../../../../types/amr-pipeline/models/OriginatingTransactionDiff';
import { DifferenceLabel, FieldDifferenceLabel } from '../../DifferenceLabel';
import TransactionContext from '../../../../transactionContext';
import IconSVG from '../../../../../../styles/svg-icons';
import { OriginatingTransactionDocument } from '../../../../../../types/amr-pipeline/models/OriginatingTransactionDocument';
import { ShowFor } from '../../../../../access';
import { ImSubscriptionValue } from '../../../../subscription/ImSubscriptionValue';
import { ValueBlocker } from '../../../../../access/ValueBlocker';
import { hasArrangerAccess } from '../../../../../../utils/amr-pipeline.utils';
import { SubscriptionFeature } from '../../../../../../types/billing/SubscriptionFeature';
import { useDownloadDocument } from '../../../../../../effects/useDownloadDocument';
import { amrPipelineService } from '../../../../../../services/amr-pipeline.service';

interface Props {
    selectedTransaction: OriginatingTransaction;
    difference?: Difference<OriginatingTransactionDiff>;
}

export function AdditionalInfo({ selectedTransaction, difference }: Props) {
    const { limitedAccess } = useContext(TransactionContext);

    const { handleDownload: downloadManagerPresentationFile, isDownloading: isManagerPresentationDownloading } =
        useDownloadDocument({
            downloadRequest: amrPipelineService.downloadManagerPresentationFile,
            requestArgs: [selectedTransaction.dealReferenceName, selectedTransaction.referenceName],
            fileNameForSave: selectedTransaction.managerPresentationFile?.name || null,
        });

    const { handleDownload: downloadDisclosureFile, isDownloading: isDisclosureDownloading } = useDownloadDocument({
        downloadRequest: amrPipelineService.downloadDisclosureFile,
        requestArgs: [selectedTransaction.dealReferenceName, selectedTransaction.referenceName],
        fileNameForSave: selectedTransaction.disclosureFile?.name || null,
    });

    const renderDocument = (
        downloadFile: () => void,
        document?: OriginatingTransactionDocument,
        isLoading?: boolean,
    ) => {
        if (!document) {
            return constants.emptyPlaceholder;
        }

        return (
            <DownloadDocument
                onClick={downloadFile}
                document={document}
                isLoading={isLoading}
                loadingText
                secondary
            >
                <FileIcon filename={document.name} />
                {document.name}
            </DownloadDocument>
        );
    };

    const renderPlainOrDifference = (
        downloadFile: () => void,
        document?: OriginatingTransactionDocument,
        isLoading?: boolean,
        diffDocument?: Difference<OriginatingTransactionDocument>,
    ) => {
        // If no difference object, render as usual
        if (!diffDocument) {
            return renderDocument(downloadFile, document, isLoading);
        }

        const { referenceName, name } = diffDocument;

        // If file was deleted, render just icon, file name and difference label
        if (referenceName?.type === DifferenceType.Removed) {
            return (
                <div className="file-name-wrap">
                    <div className="btn-download-file removed">
                        <IconSVG name="basket" width="16" height="16" />
                        {name?.previousValue}
                    </div>
                    <FieldDifferenceLabel difference={name} />
                </div>
            );
        }

        // Render file with download link and difference type (here is only added/updated)
        return (
            <div className="file-name-wrap">
                {renderDocument(downloadFile, document)}
                {referenceName?.type === DifferenceType.Updated ? (
                    <DifferenceLabel differenceType={DifferenceType.Updated} />
                ) : (
                    <FieldDifferenceLabel difference={referenceName} />
                )}
            </div>
        );
    };

    return (
        <SectionContent title="Additional Information" className="data-item-additional-information">
            <ShowFor roles={roles.getAllRolesExclude(roles.Media, roles.Viewer)}>
                <FormField className="data-item-row-document data-item-row-full-height" title="Disclosure">
                    <ValueBlocker
                        overrideRequiredFeatures={hasArrangerAccess(selectedTransaction)}
                        feature={SubscriptionFeature.IssuanceMonitorStandardizedDetailsTabs}
                    >
                        {renderPlainOrDifference(
                            downloadDisclosureFile,
                            selectedTransaction.disclosureFile,
                            isDisclosureDownloading,
                            difference?.disclosureFile,
                        )}
                    </ValueBlocker>
                </FormField>
            </ShowFor>
            <FormField title="Transaction Overview" className="data-item-row-full-height">
                <ImSubscriptionValue transaction={selectedTransaction}>
                    <DifferenceField difference={difference?.dealOverview}>
                        {renderMarkdownOrEmpty(selectedTransaction.dealOverview)}
                    </DifferenceField>
                </ImSubscriptionValue>
            </FormField>
            <FormField title="Manager Highlights" className="data-item-row-full-height">
                <ImSubscriptionValue transaction={selectedTransaction}>
                    <DifferenceField difference={difference?.managerHighlights}>
                        {renderMarkdownOrEmpty(selectedTransaction.managerHighlights)}
                    </DifferenceField>
                </ImSubscriptionValue>
            </FormField>
            {!limitedAccess && (
                <ShowFor roles={roles.getAllRolesExclude(roles.Media, roles.Viewer)}>
                    <FormField
                        className="data-item-row-document data-item-row-full-height"
                        title="Manager Presentation"
                    >
                        <ValueBlocker
                            overrideRequiredFeatures={hasArrangerAccess(selectedTransaction)}
                            feature={SubscriptionFeature.IssuanceMonitorStandardizedDetailsTabs}
                        >
                            {renderPlainOrDifference(
                                downloadManagerPresentationFile,
                                selectedTransaction.managerPresentationFile,
                                isManagerPresentationDownloading,
                                difference?.managerPresentationFile,
                            )}
                        </ValueBlocker>
                    </FormField>
                </ShowFor>
            )}
            <FormField title="EU Compliance" className="data-item-row-full-height text-capitalize">
                <ImSubscriptionValue transaction={selectedTransaction}>
                    <DifferenceField difference={difference?.euCompliance} formatter={formatUtils.formatBoolean}>
                        {formatUtils.formatBoolean(selectedTransaction.euCompliance)}
                    </DifferenceField>
                </ImSubscriptionValue>
            </FormField>
            <FormField title="EU Risk Retention Requirements" className="data-item-row-full-height">
                <ImSubscriptionValue transaction={selectedTransaction}>
                    <DifferenceField difference={difference?.euRiskRetentionRequirements}>
                        {renderMarkdownOrEmpty(selectedTransaction.euRiskRetentionRequirements)}
                    </DifferenceField>
                </ImSubscriptionValue>
            </FormField>
            <FormField title="EU Disclosure Requirements" className="data-item-row-full-height">
                <ImSubscriptionValue transaction={selectedTransaction}>
                    <DifferenceField difference={difference?.euDisclosureRequirements}>
                        {renderMarkdownOrEmpty(selectedTransaction.euDisclosureRequirements)}
                    </DifferenceField>
                </ImSubscriptionValue>
            </FormField>
            <FormField title="ESG" className="text-capitalize data-item-row-full-height">
                <ImSubscriptionValue transaction={selectedTransaction}>
                    <DifferenceField difference={difference?.esg} formatter={formatUtils.formatBoolean}>
                        {formatUtils.formatBoolean(selectedTransaction.esg)}
                    </DifferenceField>
                </ImSubscriptionValue>
            </FormField>
            <FormField title="ESG Comment" className="data-item-row-full-height">
                <ImSubscriptionValue transaction={selectedTransaction}>
                    <DifferenceField difference={difference?.esgComment}>
                        {renderMarkdownOrEmpty(selectedTransaction.esgComment)}
                    </DifferenceField>
                </ImSubscriptionValue>
            </FormField>
            {/* Temporary Enhanced CLO hiding */}
            {/* <FormField
                title="Enhanced CLO"
                className="text-capitalize"
                value={formatUtils.formatBoolean(selectedTransaction.enhancedCLO)}
            /> */}
            <FormField title="Static Deal" className="text-capitalize data-item-row-full-height">
                <ImSubscriptionValue transaction={selectedTransaction}>
                    <DifferenceField difference={difference?.staticDeal} formatter={formatUtils.formatBoolean}>
                        {formatUtils.formatBoolean(selectedTransaction.staticDeal)}
                    </DifferenceField>
                </ImSubscriptionValue>
            </FormField>

            <FormField title="Offering Type" className="data-item-row-full-height">
                <DifferenceField difference={difference?.offeringType}>
                    {selectedTransaction.offeringType || constants.emptyPlaceholder}
                </DifferenceField>
            </FormField>
            <FormField title="Deal with AMR" className="data-item-row-full-height" capitalize={false}>
                <DifferenceField difference={difference?.amr} formatter={formatUtils.formatBoolean}>
                    <span className="text-capitalize">{formatUtils.formatBoolean(selectedTransaction.amr)}</span>
                </DifferenceField>
            </FormField>
        </SectionContent>
    );
}
