import React from "react";
import cn from "classnames";
import { routes } from "../../../constants/routes";
import { Transaction } from "../../../types/amr-pipeline/models/Transaction";
import { useHistory } from "react-router";

interface Props {
    transaction: Transaction;
    disabled?: boolean;
}

export function DetailedPageLink({ transaction, disabled }: Props) {
    const history = useHistory();

    const { dealLegalName, dealReferenceName, referenceName } = transaction;

    const handleClick = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault();
        history.push(routes.transactionDetailUrl(referenceName, dealReferenceName));
    };

    return (
        <span
            className={cn("text-ellipsis", { "link-filter": !disabled })}
            onClick={disabled ? undefined : handleClick}
        >
            {dealLegalName}
        </span>
    );
}
