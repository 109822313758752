import { Difference, ObjectArrayItemDifference } from '../../../../utils/differ/types';
import { Indicator, TableIndicator } from './types';

export const defaultFormatter = (value?: any) => value ? `${value}` : null;

export const indicatorMatchesSearchTerm =
    <T>(searchTerms: string[], difference: Difference<T>, item?: ObjectArrayItemDifference<unknown> ) =>
    (indicator: Indicator<unknown>) => {
        if (!searchTerms.length) {
            return true;
        }

        const indicatorTitleMatchesSearchTerms = searchTerms.some(s => indicator.title.toLocaleLowerCase().includes(s.toLowerCase()));
        let indicatorValueMatchesSearchTerms = false;

        if (item) {
            indicatorValueMatchesSearchTerms = (indicator as TableIndicator<T, unknown>).arrayItemDifferenceMatchesSearchTerm(searchTerms, item);
        } else {
            indicatorValueMatchesSearchTerms = indicator.matchesSearchTerm(searchTerms, difference);
        }

        return indicator.hasChanges(difference) && (indicatorValueMatchesSearchTerms || indicatorTitleMatchesSearchTerms);
    }
