import { startCase } from 'lodash';
import { Link } from 'react-router-dom';
import { constants, roles, routes } from '../../../../../../constants';
import { collateralTypes } from '../../../../../../constants/collateral-types';
import { OriginatingTransaction } from '../../../../../../types/amr-pipeline/models/OriginatingTransaction';
import { moneyUtils } from '../../../../../../utils';
import { FormField } from '../../../../common/FormField';
import { SectionContent } from '../../../../common/section/SectionContent';
import { CapitalStructure } from '../../capital-structure/CapitalStructure';
import { OnHoverTooltip } from '../../../../../common';
import IconSVG from '../../../../../../styles/svg-icons';
import StatusLabel from '../../../../StatusLabel';
import { transactionStatusTitles } from '../../../../../../types/amr-pipeline/enums/TransactionStatus';
import { Difference, DifferenceType } from '../../../../../../utils/differ/types';
import { DifferenceField } from '../../DifferenceField';
import { FieldDifferenceLabel } from '../../DifferenceLabel';
import { OriginatingTransactionDiff } from '../../../../../../types/amr-pipeline/models/OriginatingTransactionDiff';
import { ShowFor } from '../../../../../access';
import { IntexLink } from '../../../../../common/IntexLink';
import { getCurrentIntexPositionIdForTransaction } from '../../../../../../utils/amr-pipeline.utils';
import { ManagerLink } from '../../../../common/ManagerLink';
import { ImSubscriptionValue } from '../../../../subscription/ImSubscriptionValue';
import { SubscriptionFeature } from '../../../../../../types/billing/SubscriptionFeature';
import { ActionBlocker } from '../../../../../access/ActionBlocker';
import { BankLink } from '../../../../common/BankLink';

const formatToStartCase = startCase;
const formatToMoney = moneyUtils.money;

const GoToIcon = () => <IconSVG name="go-to" width={16} height={16} />;

const BlockedGoToButton = () => (
    <button className="btn-link" disabled>
        <GoToIcon />
    </button>
);

interface Props {
    selectedTransaction: OriginatingTransaction;
    difference?: Difference<OriginatingTransactionDiff>;
}

export function General({ selectedTransaction, difference }: Props) {
    const currentIntexPositionId = getCurrentIntexPositionIdForTransaction(selectedTransaction);

    const renderTrusteeField = () => {
        if (!selectedTransaction.trustee) {
            return constants.emptyPlaceholder;
        }

        if (difference?.trustee?.legalName) {
            return <div className="text-ellipsis name-box">{selectedTransaction.trustee.legalName}</div>;
        }

        return (
            <div className="name-with-link">
                <div className="text-ellipsis name-box">
                    <OnHoverTooltip overlay={selectedTransaction.trustee.legalName}>
                        {selectedTransaction.trustee.legalName}
                    </OnHoverTooltip>
                </div>
                <ShowFor roles={[...roles.seller(), ...roles.bd(), roles.Administrator, roles.DataEntry, roles.Media]}>
                    <ActionBlocker feature={SubscriptionFeature.IssuanceMonitorFullAccess}>
                        {blocked =>
                            blocked ? (
                                <BlockedGoToButton />
                            ) : (
                                <OnHoverTooltip overlay="All Trustee’s Transactions">
                                    <Link
                                        target="_blank"
                                        to={`${routes.AMRPipeline}/?trustees=${selectedTransaction.trustee?.referenceName}`}
                                        className="btn-link"
                                    >
                                        <GoToIcon />
                                    </Link>
                                </OnHoverTooltip>
                            )
                        }
                    </ActionBlocker>
                </ShowFor>
            </div>
        );
    }

    const renderCollateralManagerField = () => {
        if (!selectedTransaction.collateralManager) {
            return constants.emptyPlaceholder;
        }

        if (difference?.collateralManager?.legalName) {
            return <div className="text-ellipsis name-box">{selectedTransaction.collateralManager.legalName}</div>;
        }

        return (
            <div className="name-with-link">
                <div className="text-ellipsis name-box">
                    <ManagerLink
                        referenceName={selectedTransaction.collateralManager.referenceName}
                        legalName={selectedTransaction.collateralManager.legalName}
                    />
                </div>
                <ShowFor roles={[...roles.seller(), ...roles.bd(), roles.Administrator, roles.DataEntry, roles.Media]}>
                    <ActionBlocker feature={SubscriptionFeature.IssuanceMonitorFullAccess}>
                        {blocked =>
                            blocked ? (
                                <BlockedGoToButton />
                            ) : (
                                <OnHoverTooltip overlay="All Manager’s Transactions">
                                    <Link
                                        target="_blank"
                                        to={`${routes.AMRPipeline}/?collateralManagers=${selectedTransaction.collateralManager?.referenceName}`}
                                        className="btn-link"
                                    >
                                        <GoToIcon />
                                    </Link>
                                </OnHoverTooltip>
                            )
                        }
                    </ActionBlocker>
                </ShowFor>
            </div>
        );
    }

    const renderArrangerField = () => {
        if (!selectedTransaction.arranger) {
            return constants.emptyPlaceholder;
        }

        if (difference?.arranger?.legalName) {
            return <div className="text-ellipsis name-box">{selectedTransaction.arranger.legalName}</div>;
        }

        return (
            <div className="name-with-link">
                <div className="text-ellipsis name-box">
                    <BankLink
                        legalName={selectedTransaction.arranger.legalName}
                        referenceName={selectedTransaction.arranger.referenceName}
                    />
                </div>
                <ShowFor roles={[...roles.seller(), ...roles.bd(), roles.Administrator, roles.DataEntry, roles.Media]}>
                    <ActionBlocker feature={SubscriptionFeature.IssuanceMonitorFullAccess}>
                        {blocked =>
                            blocked ? (
                                <BlockedGoToButton />
                            ) : (
                                <OnHoverTooltip overlay="All Arranger’s Transactions">
                                    <Link
                                        target="_blank"
                                        to={`${routes.AMRPipeline}/?arrangers=${selectedTransaction.arranger?.referenceName}`}
                                        className="btn-link"
                                    >
                                        <GoToIcon />
                                    </Link>
                                </OnHoverTooltip>
                            )
                        }
                    </ActionBlocker>
                </ShowFor>
            </div>
        );
    }

    return (
        <>
            <SectionContent title="Information" className="data-item-general-information data-item-general-cols">
                <div className="data-item-row-col">
                    <FormField title="Deal Name" className="data-item-row-full-height">
                        <DifferenceField difference={difference?.dealLegalName}>
                            {selectedTransaction.dealLegalName}
                        </DifferenceField>
                    </FormField>
                    <FormField title="Ticker" className="data-item-row-full-height">
                        {difference ? (
                            <DifferenceField difference={difference?.dealTicker}>
                                {selectedTransaction.dealTicker || constants.emptyPlaceholder}
                            </DifferenceField>
                        ) : (
                            <div className="name-with-link">
                                <div className="text-ellipsis name-box">
                                    <OnHoverTooltip overlay={selectedTransaction.dealTicker}>
                                        {selectedTransaction.dealTicker || constants.emptyPlaceholder}
                                    </OnHoverTooltip>
                                </div>
                                {currentIntexPositionId && (
                                    <IntexLink
                                        positionId={currentIntexPositionId}
                                        text="Open Intex"
                                    />
                                )}
                            </div>
                        )}
                    </FormField>
                    <FormField title="Trans. Type & Status" className="transaction-type-status-row data-item-row-full-height">
                        <DifferenceField difference={difference?.type} formatter={formatToStartCase}>
                            <span className="transaction-type-label">{formatToStartCase(selectedTransaction.type)}</span>
                        </DifferenceField>
                        <OnHoverTooltip
                            overlay={
                                difference?.status?.type === DifferenceType.Updated
                                    ? `Updated from ${difference.status.previousValue}`
                                    : null
                            }
                        >
                            <StatusLabel
                                status={transactionStatusTitles[selectedTransaction.status]}
                                className="label-status-type"
                            />
                        </OnHoverTooltip>
                        <FieldDifferenceLabel difference={difference?.status} />
                    </FormField>
                    <FormField title="Transaction Size" className="data-item-row-full-height">
                        <DifferenceField difference={difference?.dealBalance} formatter={formatToMoney}>
                            {formatToMoney(selectedTransaction.dealBalance) || constants.emptyPlaceholder}
                        </DifferenceField>
                    </FormField>
                    <FormField title="Currency" className="data-item-row-full-height">
                        <DifferenceField difference={difference?.currency?.code}>
                            {selectedTransaction.currency?.code || constants.emptyPlaceholder}
                        </DifferenceField>
                    </FormField>
                    <FormField title="Collateral type" className="data-item-row-full-height">
                        <DifferenceField difference={difference?.collateralType}>
                            {collateralTypes[selectedTransaction.collateralType]}
                        </DifferenceField>
                    </FormField>
                </div>
                <div className="data-item-row-col">
                    <FormField title="Issuer" className="data-item-row-full-height">
                        <ImSubscriptionValue transaction={selectedTransaction}>
                            <DifferenceField difference={difference?.issuer?.legalName}>
                                {selectedTransaction.issuer?.legalName ?? constants.emptyPlaceholder}
                            </DifferenceField>
                        </ImSubscriptionValue>
                    </FormField>
                    <FormField title="Co-Issuer" className="data-item-row-full-height">
                        <ImSubscriptionValue transaction={selectedTransaction}>
                            <DifferenceField difference={difference?.coIssuer?.legalName}>
                                {selectedTransaction.coIssuer?.legalName ?? constants.emptyPlaceholder}
                            </DifferenceField>
                        </ImSubscriptionValue>
                    </FormField>
                    <FormField title="Collateral Manager" className="data-item-row-full-height">
                        <DifferenceField difference={difference?.collateralManager?.legalName}>
                            {renderCollateralManagerField()}
                        </DifferenceField>
                    </FormField>
                    <FormField title="Arranger" className="data-item-row-full-height">
                        <DifferenceField difference={difference?.arranger?.legalName}>
                            {renderArrangerField()}
                        </DifferenceField>
                    </FormField>
                    <FormField title="Trustee" className="data-item-row-full-height">
                        <DifferenceField difference={difference?.trustee?.legalName}>
                            {renderTrusteeField()}
                        </DifferenceField>
                    </FormField>
                    <FormField title="Initial Purchaser" className="data-item-row-full-height">
                        <ImSubscriptionValue transaction={selectedTransaction}>
                            <DifferenceField difference={difference?.initialPurchaser}>
                                {selectedTransaction.initialPurchaser || constants.emptyPlaceholder}
                            </DifferenceField>
                        </ImSubscriptionValue>
                    </FormField>
                </div>
            </SectionContent>
            <SectionContent
                title="Capital Structure - Summary"
                className="data-item-general-information data-item-general-cols data-item-cap-structure"
            >
                <CapitalStructure
                    transaction={selectedTransaction}
                    items={selectedTransaction.classes}
                    difference={difference?.classes}
                />
            </SectionContent>
        </>
    );
}
