import { OnHoverTooltip } from '../../common';
import IconSVG from '../../../styles/svg-icons';
import { createAmrPipelineActions } from '../../../actions';
import { PipelineType } from '../../../types/amr-pipeline/enums/PipelineType';
import { useAppDispatch } from '../../../effects/useAppDispatch';

interface Props {
    dealLegalName: string;
    dealReferenceName: string;
    disabled?: boolean;
    onClick?: () => void;
}

export function FilterRelatedTransactionsIcon({
    dealLegalName,
    dealReferenceName,
    disabled = false,
    onClick,
}: Props) {
    const dispatch = useAppDispatch();
    const amrPipelineActions = createAmrPipelineActions(PipelineType.Deals);

    return (
        <OnHoverTooltip overlay={!disabled ? 'Related Transactions' : null}>
            <button
                disabled={disabled}
                onClick={(e) => {
                    e.stopPropagation();
                    onClick && onClick();
                    dispatch(
                        amrPipelineActions.changeDealSelectFromTable(dealLegalName, dealReferenceName)
                    );
                }}
                className="btn-link"
            >
                <IconSVG name="related-transaction" width={16} height={16} />
            </button>
        </OnHoverTooltip>
    );
}
