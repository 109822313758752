import { DifferenceType, ObjectArrayDifference, ObjectArrayItemDifference } from '../../../../utils/differ/types';

export interface DifferenceListProps<TItem, TDiffItem> {
    items: TItem[];
    difference?: ObjectArrayDifference<TDiffItem[]>;
}

export const withDeletedItems = <TItem, TDiffItem extends TItem, TProps extends DifferenceListProps<TItem, TDiffItem>>(Component: React.ComponentType<TProps>) =>
    ({ items, difference, ...props }: TProps) => {
        // Collect deleted classes from difference if it's passed
        // to present them in resulting table
        const filter = (itemDiference: ObjectArrayItemDifference<TDiffItem>) =>
            !!itemDiference.previousValue && itemDiference.type === DifferenceType.Removed;

        const deletedClasses = difference
            ? difference.filter(filter).map((x) => x.previousValue!)
            : [];

        // Concat deleted items if needed
        const withDeleted = [...items, ...deletedClasses];

        return <Component {...props as TProps} difference={difference} items={withDeleted} />;
    };
