import { TransactionViewHistory } from '../../../../../../../types/amr-pipeline/models/TransactionSession';
import { dateTimeUtils } from '../../../../../../../utils';
import { OnHoverTooltip } from '../../../../../../common';
import { Popover } from '../../../../../../controls';
import { TabItem, TabList } from '../../../../../../bidding/common/tab-list';
import { TabContent } from '../../../../../common/tabs/TabContent';
import { TransactionViewHistoryTabs } from './TransactionViewHistoryTabs';
import { useState } from 'react';
import { OverviewTab } from './OverviewTab';
import { ContactsTab } from './ContactsTab';
import { DocsDownloadedTab } from './DocsDownloadedTab';
import { times } from 'lodash';
import { RequiredFeature } from '../../../../../../access/RequiredFeature';
import { SubscriptionFeature } from '../../../../../../../types/billing/SubscriptionFeature';

interface Props {
    transactionReferenceName: string;
    dealReferenceName: string;
    userSession: TransactionViewHistory;
    children?: React.ReactNode;
    defaultTab?: TransactionViewHistoryTabs;
}

export function TransactionViewHistoryPopover({
    transactionReferenceName,
    dealReferenceName,
    userSession,
    children,
    defaultTab = TransactionViewHistoryTabs.Overview,
}: Props) {
    const [activeTab, setActiveTab] = useState(defaultTab);
    const formattedActionDate = dateTimeUtils.utcToLocalString(userSession.accessDateTime);

    const PseudoLink = (
        <span className="pseudo-link">
            <OnHoverTooltip>{children || formattedActionDate}</OnHoverTooltip>
        </span>
    );

    const setDefaultTab = () => setActiveTab(defaultTab);

    const downloadableDocuments = userSession.documentsList.filter(x => !!x.name);
    const notDownloadableDocNames = times(userSession.documents.ExportTargetPortfolio?.length, () => 'Target Portfolio.csv');

    const renderActiveTab = () => {
        switch (activeTab) {
            case TransactionViewHistoryTabs.DocsDownloaded:
                return (
                    <DocsDownloadedTab
                        transactionReferenceName={transactionReferenceName}
                        dealReferenceName={dealReferenceName}
                        documents={downloadableDocuments}
                        notDownloadableDocNames={notDownloadableDocNames}
                    />
                );
            case TransactionViewHistoryTabs.Contacts:
                return <ContactsTab userSession={userSession} />;
            default:
                return (
                    <OverviewTab
                        transactionReferenceName={transactionReferenceName}
                        dealReferenceName={dealReferenceName}
                        userSession={userSession}
                    />
                );
        }
    };

    return (
        <RequiredFeature
            feature={SubscriptionFeature.IssuanceMonitorFullAccess}
            renderBlockedContent={() => <span>{children || formattedActionDate}</span>}
        >
            <Popover
                className="popover-transaction-views"
                title={formattedActionDate}
                label={<span className="name-tag">{userSession.userFullName}</span>}
                actionButton={PseudoLink}
                onOpen={setDefaultTab}
                showCloseButton={true}
            >
                <TabList>
                    <TabItem
                        active={activeTab === TransactionViewHistoryTabs.Overview}
                        title="Overview"
                        onClick={() => setActiveTab(TransactionViewHistoryTabs.Overview)}
                    />
                    <TabItem
                        active={activeTab === TransactionViewHistoryTabs.DocsDownloaded}
                        title="Docs Downloaded"
                        onClick={() => setActiveTab(TransactionViewHistoryTabs.DocsDownloaded)}
                    />
                    <TabItem
                        active={activeTab === TransactionViewHistoryTabs.Contacts}
                        title="Contacts"
                        onClick={() => setActiveTab(TransactionViewHistoryTabs.Contacts)}
                    />
                </TabList>
                <TabContent>{renderActiveTab()}</TabContent>
            </Popover>
        </RequiredFeature>
    );
}
