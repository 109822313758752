import React from 'react';

interface TableSectionWrapperProps {
    title: string;
    children: React.ReactNode | React.ReactNode[];
}

export const TableSectionWrapper = ({ title, children }: TableSectionWrapperProps) => {
    return (
        <div key={title} className="history-element">
            <h2>{title}</h2>
            <ul className="list-bullet text-sm">
                {children}
            </ul>
        </div>
    );
}
