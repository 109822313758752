import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { errorActions } from "../../../../actions";
import { amrPipelineService } from "../../../../services/amr-pipeline.service";
import { CollateralQualityTest } from "../../../../types/amr-pipeline/models/CollateralQualityTest";
import { OriginatingTransaction } from "../../../../types/amr-pipeline/models/OriginatingTransaction";
import { AmrTransaction } from "../../../../types/amr-pipeline/models/AmrTransaction";

type PreviewTransaction = OriginatingTransaction | AmrTransaction;

export const useRequestTransactionDetails = (
    transactionForPreview: PreviewTransaction,
    updateTransactionForPreview: (trans: PreviewTransaction) => void,
    withGetTransaction: boolean,
    loading: boolean,
    setLoading: (isSending: boolean) => void,
    onLoadSuccess: (collateralQualityTest: CollateralQualityTest[]) => void,
) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const request = async () => {
            try {
                let transaction = transactionForPreview;

                if(withGetTransaction) {
                    transaction = await amrPipelineService.getTransaction(transactionForPreview.dealReferenceName, transactionForPreview.referenceName);
                    updateTransactionForPreview(transaction);
                }

                const { dealReferenceName, referenceName } = transaction;
                const collateralQualityTest: CollateralQualityTest[] = await amrPipelineService.getCollateralQualityTest(
                    dealReferenceName,
                    referenceName
                );
                onLoadSuccess(collateralQualityTest);
            } catch (error) {
                dispatch(errorActions.criticalError(error));
            } finally {
                setLoading(false);
            }
        };

        if (loading) {
            request();
        }
        // eslint-disable-next-line
    }, [loading]);
};
