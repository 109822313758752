import { Preloader } from '../../../common';
import { DashboardNoSelectedFilters } from '../../../dashboard/DashboardNoSelectedFilters';
import { AnalyticsFilter } from './AnalyticsFilter';
import { AnalyticsFilterBase } from '../../../../types/analytics/AnalyticsFilter';
import { TreeSelectOption } from '../../../controls/TreeSelect';
import { AnalyticWidgetState } from '../../../../types/state/Analytics';
import { SubscriptionFeature } from '../../../../types/billing/SubscriptionFeature';
import { ActionBlocker } from '../../../access/ActionBlocker';

interface AnalyticWidgetProps<T extends string> {
    title: string;
    users: TreeSelectOption<string>[];
    tabDefinitions: { text: string; value: T }[];
    isLoading: boolean;
    children: React.ReactElement;
    feature?: SubscriptionFeature;
    features?: SubscriptionFeature[];
    state: AnalyticWidgetState<unknown, AnalyticsFilterBase<T>>;
    onFilterChange: (filterState: AnalyticsFilterBase<T>) => void;
}

export function AnalyticsWidget<T extends string>({
    title,
    users,
    tabDefinitions,
    children,
    feature,
    features,
    state,
    onFilterChange,
}: AnalyticWidgetProps<T>) {
    const { data, isLoading, filter } = state;

    const isDataLoaded = !!data.length && !isLoading;

    return (
        <div className="analytics">
            <div className="analytics-heading">
                <h2>{title}</h2>
                <ActionBlocker feature={feature} features={features} requireAllFeatures>
                    {blocked => (
                        <AnalyticsFilter
                            data={users}
                            tabDefinitions={tabDefinitions}
                            dateOption={filter.dateOption}
                            onChange={onFilterChange}
                            disabled={blocked}
                            {...filter}
                        />
                    )}
                </ActionBlocker>
            </div>
            <div className="">
                <Preloader fullScreen={false} inProgress={isLoading}>
                    {isDataLoaded ? children : <DashboardNoSelectedFilters />}
                </Preloader>
            </div>
        </div>
    );
}
