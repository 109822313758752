import { IColumnDefinition } from "../../../../bidding/common/table/types/ColumnDefinition";
import { ColumnBuilder } from "../../../../bidding/common/table/columns/column-builder/ColumnBuilder";
import { constants, SORTING_TYPE } from "../../../../../constants";
import { TransactionInvitedClient } from "../../../../../types/amr-pipeline/models/TransactionInvitedClient";
import moment from "moment";
import { OnHoverTooltip } from "../../../../common";
import IconSVG from "../../../../../styles/svg-icons";

interface InvitedClientsTable extends TransactionInvitedClient {
    lastTransactionView?: Date;
}

const invitedClientsColumnDefinitions: IColumnDefinition<InvitedClientsTable>[] =
    [
        {
            columnKey: "email",
            renderColumnHeaderContent: () => "Client Email",
            renderColumnContent: (transactionClient) => transactionClient.email,
            className: "data-list-cell-xl-xxl",
        },
        {
            columnKey: "invitationDate",
            renderColumnHeaderContent: () => "Invitation Date",
            renderColumnContent: (transactionClient) =>
                moment(transactionClient.invitationDate).format(
                    constants.dateFormat4
                ),
            sortingField: "invitationDate",
            className: "data-list-cell-sm",
            sortingType: SORTING_TYPE.date,
        },
        {
            columnKey: "registrationDate",
            renderColumnHeaderContent: () => "Reg. Date",
            renderColumnContent: (transactionClient) =>
                transactionClient.registrationDate
                    ? moment(transactionClient.registrationDate).format(
                          constants.dateFormat4
                      )
                    : constants.emptyPlaceholder,
            sortingField: "registrationDate",
            className: "data-list-cell-sm",
            sortingType: SORTING_TYPE.date,
        },
        {
            columnKey: "lastTransactionView",
            renderColumnHeaderContent: () => (
                <>
                    <span>Last Transaction View</span>
                    <OnHoverTooltip overlay="Last Transaction View is calculated only from deal announcement to pricing" placement="bottomRight">
                        <IconSVG name="info" width={16} height={16} />
                    </OnHoverTooltip>
                </>
            ),
            renderColumnContent: (transactionClient) =>
                transactionClient.lastTransactionView
                    ? moment(transactionClient.lastTransactionView).format(
                          constants.dateTimeFormat
                    )
                    : constants.emptyPlaceholder,
            sortingField: "lastTransactionView",
            className: "data-list-cell-xl",
            sortingType: SORTING_TYPE.date,
        },
        // AMR-8825 [staging] BWIC:: IM:: Detailed:: Invited clients:: Hide 'Last Login' column for the 5.6 BWIC release
        // {
        //     columnKey: "lastLoginDate",
        //     renderColumnHeaderContent: () => "Last Login",
        //     renderColumnContent: (transactionClient) =>
        //         transactionClient.lastLoginDate
        //             ? moment(transactionClient.lastLoginDate).format(
        //                   constants.dateFormat4
        //               )
        //             : constants.emptyPlaceholder,
        //     sortingField: "lastLoginDate",
        //     className: "data-list-cell-xs-flexible",
        //     sortingType: SORTING_TYPE.date,
        // },
    ];

export const invitedClientsColumns = invitedClientsColumnDefinitions.map((c) => new ColumnBuilder(c));
