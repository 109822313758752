import * as React from 'react';
import { Redirect } from 'react-router';
import { routes } from '../../../../../constants';
import { Deal } from '../../../../../types/amr-pipeline/models/Deal';
import { AmrDealTrancheTabTypes } from '../../../types/AmrDealTrancheTabTypes';
import { Auctions } from './Auctions';
import { Details } from './Details';
import { TabList, TabItem } from "../../../../bidding/common/tab-list";
import { AmrClass } from '../../../../../types/amr-pipeline/models/AmrClass';
import { TabContent } from '../../../common/tabs/TabContent';
import { Preloader } from '../../../../common';

interface TrancheDetailsProps {
    deal?: Deal,
    amrClass: AmrClass,
    activeTab?: AmrDealTrancheTabTypes,
    isAuctionServiceProvider: boolean,
    disabled?: boolean,
    transactionReferenceName: string
}

export function ClassDetails({ deal, amrClass, activeTab = AmrDealTrancheTabTypes.details, disabled, transactionReferenceName, isAuctionServiceProvider = false }: TrancheDetailsProps) {
    if (activeTab === AmrDealTrancheTabTypes.auctions && !amrClass.class.amr) {
        return <Redirect to={routes.notFound} />
    }

    const renderTabContent = () => {
        switch (activeTab) {
            case (AmrDealTrancheTabTypes.auctions): return <Auctions isAuctionServiceProvider={isAuctionServiceProvider} amrClass={amrClass} />;
            default: return <Details amrClass={amrClass} />;
        }
    }

    return (
        <>
            <TabList className="tabs-left-shift">
                <TabItem
                    active={activeTab === AmrDealTrancheTabTypes.details}
                    title="Overview"
                    path={routes.transactionClassUrl(
                        transactionReferenceName,
                        amrClass.dealReferenceName,
                        amrClass.referenceName,
                        AmrDealTrancheTabTypes.details
                    )}
                    disabled={disabled}
                />
                {amrClass.class.amr && (
                    <TabItem
                        active={activeTab === AmrDealTrancheTabTypes.auctions}
                        title="AMR Auctions"
                        path={routes.transactionClassUrl(
                            transactionReferenceName,
                            amrClass.dealReferenceName,
                            amrClass.referenceName,
                            AmrDealTrancheTabTypes.auctions
                        )}
                        disabled={disabled}
                    />
                )}
            </TabList>
            <TabContent>{deal ? renderTabContent() : <Preloader inProgress={true} />}</TabContent>
        </>
    );
}
