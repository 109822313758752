import React from "react";
import { constants } from "../../../../../constants";
import { OriginatingTransactionPortfolioItem } from "../../../../../types/amr-pipeline/models/OriginatingTransactionPortfolioItem";
import { moneyUtils } from "../../../../../utils";
import { SecurityListRow, SecurityListColumn } from "../../../../bidding/common";
import { ColumnBuilder } from "../../../../bidding/common/table/columns/column-builder/ColumnBuilder";
import { rampedPar, targetPar } from "./portfolio-columns";


interface Props {
    portfolio: OriginatingTransactionPortfolioItem[];
    columns: ColumnBuilder<OriginatingTransactionPortfolioItem>[];
}

export function TotalRow({ portfolio, columns }: Props) {
    let totalTargetPar: number = 0;
    let totalRampedPar: number = 0;
    portfolio.map((c) => {
        totalTargetPar = totalTargetPar + (c.targetPar ? c.targetPar : 0);
        totalRampedPar = totalRampedPar + (c.rampedPar ? c.rampedPar : 0);

        return c;
    });

    return (
        <SecurityListRow className="data-list-striped-total">
            {columns.map((c, index: number) => {
                if (index === 0) {
                    return (
                        <SecurityListColumn key={c.column.columnKey} className="data-list-cell-lg">
                            Total:
                        </SecurityListColumn>
                    );
                } else if (c.column.columnKey === targetPar.columnKey) {
                    return (
                        <SecurityListColumn
                            key={c.column.columnKey}
                            className={c.column.bodyClassName}
                        >
                            {
                                totalTargetPar
                                    ? moneyUtils.money(totalTargetPar, true)
                                    : constants.emptyPlaceholder
                            }
                        </SecurityListColumn>
                    );
                } else if (c.column.columnKey === rampedPar.columnKey) {
                    return (
                        <SecurityListColumn
                            key={c.column.columnKey}
                            className={c.column.bodyClassName}
                        >
                            {
                                totalRampedPar
                                    ? moneyUtils.money(totalRampedPar, true)
                                    : constants.emptyPlaceholder
                            }
                        </SecurityListColumn>
                    );
                } else {
                    return (
                        <SecurityListColumn
                            key={c.column.columnKey}
                            className="data-list-cell-md"
                        >
                            {" "}
                        </SecurityListColumn>
                    );
                }
            })}
        </SecurityListRow>
    );
}
