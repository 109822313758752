import { dateTimeUtils, formatUtils, moneyUtils } from "../../../../../utils";
import { FormField } from "../../FormField";
import { OriginatingTransaction } from "../../../../../types/amr-pipeline/models/OriginatingTransaction";
import StatusLabel from "../../../StatusLabel";
import { transactionStatusTitles } from "../../../../../types/amr-pipeline/enums/TransactionStatus";
import { collateralTypes } from "../../../../../constants/collateral-types";
import moment from "moment";
import { startCase } from "lodash";
import { CollateralQualityTest } from "../../../../../types/amr-pipeline/models/CollateralQualityTest";
import { CollateralQualityTestValue } from "../../../../../types/amr-pipeline/enums/CollateralQualityTestValue";
import { constants } from "../../../../../constants";
import { AmrTransaction } from "../../../../../types/amr-pipeline/models/AmrTransaction";

interface Props {
    transaction: OriginatingTransaction | AmrTransaction;
}

function getAssetPar(collateralQualityTests?: CollateralQualityTest[]) {
    const assetPar = collateralQualityTests?.find(
        (cqt) => cqt.value === CollateralQualityTestValue.AssetPar
    );

    return assetPar?.target
        ? moneyUtils.money(assetPar.target, true)
        : constants.emptyPlaceholder;
}

export function GeneralInfo({ transaction }: Props) {
    const formatDateWithPeriod = (transactionDate?: Date) => {
        if (!transactionDate) {
            return constants.emptyPlaceholder;
        }
        const yearsLeft = dateTimeUtils.yrsLeftToDateFrom(
            transactionDate,
            transaction.closingDate
        );

        return `${moment(transactionDate).format(constants.dateFormat)} ${yearsLeft ? "[" + yearsLeft + "]" : ""
            }`;
    };

    return (
        <div className="data-item-general-cols">
            <div className="data-item-content">
                <div className="data-item-row-col">
                    <FormField
                        title="Description"
                        className="data-item-row-title-sm data-item-row-header"
                    >
                        Details
                    </FormField>
                    <FormField
                        title="Ticker"
                        className="data-item-row-title-sm"
                    >
                        {transaction.dealTicker || constants.emptyPlaceholder}
                    </FormField>
                    <FormField
                        title="Manager"
                        className="data-item-row-title-sm"
                    >
                        {transaction.collateralManager?.legalName ||
                            constants.emptyPlaceholder}
                    </FormField>
                    <FormField
                        title="Arranger"
                        className="data-item-row-title-sm"
                    >
                        {transaction.arranger?.legalName ||
                            constants.emptyPlaceholder}
                    </FormField>
                    <FormField
                        title="Transaction Type"
                        className="data-item-row-title-sm"
                    >
                        {startCase(transaction.type)}
                    </FormField>
                    <FormField
                        title="Transaction Status"
                        className="data-item-row-title-sm"
                    >
                        <StatusLabel
                            status={transactionStatusTitles[transaction.status]}
                            className="label-status-type"
                        />
                    </FormField>
                    <FormField
                        title="Collateral Type"
                        className="data-item-row-title-sm"
                    >
                        {collateralTypes[transaction.collateralType]}
                    </FormField>
                    <FormField
                        title="Currency"
                        className="data-item-row-title-sm"
                    >
                        {transaction.currency?.code ||
                            constants.emptyPlaceholder}
                    </FormField>
                    <FormField
                        title="Transaction Size"
                        className="data-item-row-title-sm"
                    >
                        {moneyUtils.money(transaction.dealBalance) ||
                            constants.emptyPlaceholder}
                    </FormField>
                </div>
                <div className="data-item-row-col">
                    <FormField
                        title="Description"
                        className="data-item-row-title-sm data-item-row-header"
                    >
                        Details
                    </FormField>
                    <FormField
                        title="Pricing Date"
                        className="data-item-row-title-sm"
                    >
                        {transaction.pricingDate
                            ? moment(transaction.pricingDate).format(
                                constants.dateFormat
                            )
                            : constants.emptyPlaceholder}
                    </FormField>
                    <FormField
                        title="Closing Date"
                        className="data-item-row-title-sm"
                    >
                        {transaction.closingDate
                            ? moment(transaction.closingDate).format(
                                constants.dateFormat
                            )
                            : constants.emptyPlaceholder}
                    </FormField>
                    <FormField
                        title="Non-Call End"
                        className="data-item-row-title-sm"
                    >
                        {formatDateWithPeriod(transaction.nonCallPeriodEnd)}
                    </FormField>

                    <FormField
                        title="Reinvestment End"
                        className="data-item-row-title-sm"
                    >
                        {formatDateWithPeriod(
                            transaction.reinvestmentPeriodEnd
                        )}
                    </FormField>
                    <FormField
                        title="Maturity"
                        className="data-item-row-title-sm"
                    >
                        {formatDateWithPeriod(
                            (transaction as OriginatingTransaction)
                                .statedMaturity
                        )}
                    </FormField>
                    <FormField
                        title="Asset Par"
                        className="data-item-row-title-sm"
                    >
                        {getAssetPar(
                            (transaction as OriginatingTransaction)
                                .collateralQualityTests
                        )}
                    </FormField>
                    <FormField
                        title="WAS, %"
                        className="data-item-row-title-sm"
                    >
                        {formatUtils.formatDecimal(
                            (transaction as OriginatingTransaction).was
                        ) || constants.emptyPlaceholder}
                    </FormField>

                    <FormField
                        title="WARF"
                        className="data-item-row-title-sm"
                    >
                        {formatUtils.formatDecimal(
                            (transaction as OriginatingTransaction).warf
                        ) || constants.emptyPlaceholder}
                    </FormField>

                    <FormField
                        title="DS"
                        className="data-item-row-title-sm"
                    >
                        {formatUtils.formatDecimal(
                            (transaction as OriginatingTransaction).ds
                        ) || constants.emptyPlaceholder}
                    </FormField>
                </div>
            </div>
        </div>
    );
}
