import { useEffect, useMemo, useState } from "react";
import { TabBody } from "../../../common/tabs/TabBody";
import { EmptyPlaceholder } from "../../../../common/EmptyPlaceholder";
import { OriginatingTransaction } from "../../../../../types/amr-pipeline/models/OriginatingTransaction";
import { Preloader } from "../../../../common";
import { Table } from "../../../../bidding/common/table";
import { invitedClientsColumns } from "./invited-clients-columns";
import { user } from "../../../../../user";
import { roles } from "../../../../../constants";
import { InviteClientsTransactionPopup } from "../../../common/InviteClientsTransactionPopup/InviteClientsTransactionPopup";
import { amrTransactionAnalyticsActions } from "../../../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../types/state/AppState";

interface Props {
    transaction: OriginatingTransaction;
    isLoading: boolean;
}

export const InvitedClients = ({ transaction, isLoading }: Props) => {
    const dispatch = useDispatch();
    const [emailToResend, setEmailToResend] = useState<string | null>(null);
    const { invitedClients } = transaction;

    const isAdmin = user.hasRoles(...roles.admin());

    const transactionHistoryViews = useSelector(
        (state: AppState) =>
            state.issuanceMonitor.amrPipelineDetailed.analytics.transactionHistoryViews
    );

    const historyViewsData = transactionHistoryViews.data;

    const dataItems = useMemo(() => {
        return invitedClients?.map((client) => ({
            ...client,
            lastTransactionView: historyViewsData.find(
                (view) => client.email === view.userEmail
            )?.accessDateTime,
        }));
    }, [historyViewsData, invitedClients]);

    useEffect(() => {
        if (transaction.invitedClients?.length) {
            dispatch(
                amrTransactionAnalyticsActions.transactionViewHistoryRequest(
                    transaction.dealReferenceName,
                    transaction.referenceName,
                )
            );
        } else {
            dispatch(amrTransactionAnalyticsActions.transactionViewHistoryResponse([]));
        }

        return () => {
            dispatch(amrTransactionAnalyticsActions.reset());
        };
    }, [transaction, dispatch]);

    if (isLoading || transactionHistoryViews.isLoading) {
        return <Preloader inProgress={true} />;
    }

    return (
        <TabBody className="tabs-data-padding component-tabs-analytics">
            {invitedClients && invitedClients.length ? (
                <>
                    <Table
                        className="data-list-striped"
                        dataItems={dataItems}
                        columns={invitedClientsColumns}
                        createSecurityCustomArgs={() => ({
                            setEmailToResend,
                        })}
                    />
                    {emailToResend && (
                        <InviteClientsTransactionPopup
                            transaction={transaction}
                            defaultEmailList={[emailToResend]}
                            onClose={() => setEmailToResend(null)}
                        />
                    )}
                </>
            ) : (
                <EmptyPlaceholder text="">
                    <p>
                        There are no invited clients yet. <br />{" "}
                        {!isAdmin &&
                            "To share the transaction with clients, click ‘Share’ button above."}
                    </p>
                </EmptyPlaceholder>
            )}
        </TabBody>
    );
};
