import React from 'react';

interface TransactionContextProps {
    canView: boolean;
    limitedAccess: boolean;
    accessTracking: boolean;
    analyticsAccess: boolean;
    invitedClientsAccess: boolean;
    ioisAccess: boolean;
}

const TransactionContext = React.createContext<TransactionContextProps>({
    canView: false,
    limitedAccess: false,
    accessTracking: true,
    analyticsAccess: false,
    invitedClientsAccess: false,
    ioisAccess: false,
});

export default TransactionContext;
