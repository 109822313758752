import { isNil, stubFalse } from 'lodash';
import moment from 'moment';
import { constants, roles } from '../../../../constants';
import { collateralQualityTestValues } from '../../../../constants/collateral-quality-test';
import { collateralTypes } from '../../../../constants/collateral-types';
import { CollateralQualityTestValue } from '../../../../types/amr-pipeline/enums/CollateralQualityTestValue';
import { CollateralType } from '../../../../types/amr-pipeline/enums/CollateralType';
import { OriginatingTransactionClassStatus, transactionClassStatusTitles } from '../../../../types/amr-pipeline/enums/OriginatingTransactionClassStatus';
import { TransactionStatus, transactionStatusTitles } from '../../../../types/amr-pipeline/enums/TransactionStatus';
import { TransactionType, TransactionTypes } from '../../../../types/amr-pipeline/enums/TransactionType';
import { CollateralQualityTest } from '../../../../types/amr-pipeline/models/CollateralQualityTest';
import { OriginatingTransactionClassDiff } from '../../../../types/amr-pipeline/models/OriginatingTransactionClassDiff';
import { OriginatingTransactionDiff } from '../../../../types/amr-pipeline/models/OriginatingTransactionDiff';
import { OriginatingTransactionDocument } from '../../../../types/amr-pipeline/models/OriginatingTransactionDocument';
import { OriginatingTransactionPortfolioItem } from '../../../../types/amr-pipeline/models/OriginatingTransactionPortfolioItem';
import { amrFormatUtils, formatUtils, moneyUtils } from '../../../../utils';
import { Difference } from '../../../../utils/differ/types';
import { BooleanIndicator } from './BooleanIndicator';
import { DeletedFileIndicator } from './DeletedFileIndicator';
import { FileIndicator } from './FileIndicator';
import { ImpreciseIndicator } from './ImpreciseIndicator';
import { ImpreciseTableIndicatorSection } from './ImpreciseTableIndicatorSection';
import { IndicatorSection } from './IndicatorSection';
import { ListIndicatorSection } from './ListIndicatorSection';
import { PrimitiveIndicator } from './PrimitiveIndicator';
import { TableIndicatorSection } from './TableIndicatorSection';
import { TableItemIndicatorsSection } from './TableItemIndicatorsSection';
import { TablePrimitiveIndicator } from './TablePrimitiveIndicator';
import { ITableSection, SimpleIndicator, TableIndicator } from './types';
import { indicatorMatchesSearchTerm } from './utils';
import { user } from '../../../../user';
import { ListBuilder } from '../../../../utils/ListBuilder';

const transactionTypeFormatter = (value?: TransactionType) => TransactionTypes.find((t) => t.value === value)?.text;
const transactionStatusFormatter = (value?: TransactionStatus) => value ? transactionStatusTitles[value] : null;
const collateralTypesFormatter = (value?: CollateralType) => value ? collateralTypes[value] : null;
const rollerDeadlineFormatter = (value?: Date) => value ? amrFormatUtils.formatRollerDeadlineDate(moment(value)) : null;
const defaultDateFormatter = (value?: Date) => value ? moment(value).format(constants.dateFormat) : null;
const moneyFormatter = (value?: number) => isNil(value) ? null : moneyUtils.money(value);
const decimalFormatter = (value?: number) => isNil(value) ? null : formatUtils.formatDecimal(value);
const transactionClassStatusFormatter = (value?: OriginatingTransactionClassStatus) => isNil(value) ? null : transactionClassStatusTitles[value];
const quotesFormatter = (value: string) => `'${value}'`;

const collateralQualityTestFormatter = (value?: number, collateralQualityTest?: CollateralQualityTest) => {
    return collateralQualityTest?.value === CollateralQualityTestValue.AssetPar && value
        ? moneyUtils.money(value, true)
        : formatUtils.formatDecimal(value);
};

const isMedia = user.hasRoles(roles.Media);

const overviewIndicators = new ListBuilder<SimpleIndicator<OriginatingTransactionDiff>>()
    .add(new PrimitiveIndicator('Transaction Size', x => x.dealBalance, moneyFormatter))
    .add(new PrimitiveIndicator('Deal Name', x => x.dealLegalName))
    .add(new PrimitiveIndicator('Ticker', x => x.dealTicker))
    .add(new PrimitiveIndicator('Transaction Type', x => x.type, transactionTypeFormatter))
    .add(new PrimitiveIndicator('Transaction Status', x => x.status, transactionStatusFormatter))
    .add(new PrimitiveIndicator('Currency', x => x.currency?.name))
    .add(new PrimitiveIndicator('Collateral Type', x => x.collateralType, collateralTypesFormatter))
    .add(new BooleanIndicator('Debut Manager’s CLO', x => x.isDebut))
    .add(new PrimitiveIndicator('Issuer', x => x.issuer?.legalName))
    .add(new PrimitiveIndicator('Co-Issuer', x => x.coIssuer?.legalName))
    .add(new PrimitiveIndicator('Collateral Manager', x => x.collateralManager?.legalName))
    .add(new PrimitiveIndicator('Arranger', x => x.arranger?.legalName))
    .add(new PrimitiveIndicator('Trustee', x => x.trustee?.legalName))
    .add(new PrimitiveIndicator('Initial Purchaser', x => x.initialPurchaser))
    .add(new ImpreciseIndicator('Transaction overview', x => x.dealOverview))
    .add(new ImpreciseIndicator('Manager Highlights', x => x.managerHighlights))
    .add(new ImpreciseIndicator('EU Risk Retention Requirements', x => x.euRiskRetentionRequirements))
    .add(new ImpreciseIndicator('EU Disclosure Requirements', x => x.euDisclosureRequirements))
    .add(new ImpreciseIndicator('ESG Comment', x => x.esgComment))
    .add(new ImpreciseIndicator('Offering Type', x => x.offeringType))
    .add(new PrimitiveIndicator('Intex Deal Name', x => x.intexName))
    .add(new PrimitiveIndicator('Intex Password', x => x.intexPassword))
    .add(new BooleanIndicator('EU Compliance', x => x.euCompliance))
    .add(new BooleanIndicator('ESG', x => x.esg))
    .add(new BooleanIndicator('Static Deal', x => x.staticDeal))
    .add(new BooleanIndicator('AMR Deal', x => x.amr))
    .add(new BooleanIndicator('Enhanced CLO', x => x.enhancedCLO))
    .add(new PrimitiveIndicator('Roller Deadline', x => x.rollerDeadline, rollerDeadlineFormatter))
    .add(new PrimitiveIndicator('Target Pricing', x => x.pricingDate, defaultDateFormatter))
    .add(new PrimitiveIndicator('Closing Date', x => x.closingDate, defaultDateFormatter))
    .add(new PrimitiveIndicator('First Payment Date', x => x.firstPaymentDate, defaultDateFormatter))
    .add(new PrimitiveIndicator('Non-Call End', x => x.nonCallPeriodEnd, defaultDateFormatter))
    .add(new PrimitiveIndicator('Reinvestment End', x => x.reinvestmentPeriodEnd, defaultDateFormatter))
    .add(new PrimitiveIndicator('Maturity', x => x.statedMaturity, defaultDateFormatter))
    .addWhen(() => !isMedia, new FileIndicator('Disclosure', x => x.disclosureFile?.name, quotesFormatter))
    .addWhen(() => !isMedia, new DeletedFileIndicator('Disclosure', x => x.disclosureFile?.name, quotesFormatter))
    .addWhen(() => !isMedia, new ImpreciseIndicator('Manager Presentation', x => x.managerPresentationFile?.name))
    .addWhen(() => !isMedia, new FileIndicator('Intex File', x => x.intexFile?.name, quotesFormatter))
    .addWhen(() => !isMedia, new DeletedFileIndicator('Intex File', x => x.intexFile?.name, quotesFormatter))
    .result();

const collateralAssumptionsIndicators: SimpleIndicator<OriginatingTransactionDiff>[] = [
    new PrimitiveIndicator('Target Par', x => x.calculatedTargetPar, moneyFormatter),
    new PrimitiveIndicator('Bid Price', x => x.calculatedBidPrice, decimalFormatter),
    new PrimitiveIndicator('Ask Price', x => x.calculatedAskPrice, decimalFormatter),
    new PrimitiveIndicator('WAS (%)', x => x.was, decimalFormatter)
];

const sourcesAndUsesIndicators: SimpleIndicator<OriginatingTransactionDiff>[] = [
    new PrimitiveIndicator('Source - Rated Notes', x => x.ratedNotes, moneyFormatter),
    new PrimitiveIndicator('Source - Sub Notes', x => x.subNotes, moneyFormatter),
    new PrimitiveIndicator('Uses - Purchase Assets', x => x.purchaseAssets, moneyFormatter),
    new PrimitiveIndicator('Uses - Upfront Fees & Expenses', x => x.upfrontFeesAndExpenses, moneyFormatter),
    new PrimitiveIndicator('Uses - Interest Reserve', x => x.interestReserve, moneyFormatter),
];

const managementFeesIndicators: SimpleIndicator<OriginatingTransactionDiff>[] = [
    new PrimitiveIndicator('Senior Management Fee, bps', x => x.seniorManagementFee, decimalFormatter),
    new PrimitiveIndicator('Sub Management Fee, bps', x => x.subManagementFee, decimalFormatter),
    new PrimitiveIndicator('Incentive Management Fee, %', x => x.incentiveManagementFee, decimalFormatter),
    new PrimitiveIndicator('Admin Expense Cap, bps', x => x.adminExpenseCapPercentage, decimalFormatter),
    new PrimitiveIndicator('Admin Expense Cap, $', x => x.adminExpenseCap, moneyFormatter),
    new PrimitiveIndicator('Trustee, bps', x => x.trusteeFee, decimalFormatter),
];

const collateralQualityTestsIndicators: TableIndicator<OriginatingTransactionDiff, CollateralQualityTest>[] = [
    new TablePrimitiveIndicator('Target', x => x.collateralQualityTests, x => x.target, collateralQualityTestFormatter),
    new TablePrimitiveIndicator('Covenant', x => x.collateralQualityTests, x => x.covenant, collateralQualityTestFormatter),
];

const structureIndicators: TableIndicator<OriginatingTransactionDiff, OriginatingTransactionClassDiff>[] = [
    new TablePrimitiveIndicator('Balance', x => x.classes, x => x.balance, moneyFormatter),
    new TablePrimitiveIndicator('C/E, %', x => x.classes, x => x.parSubordination, decimalFormatter),
    new TablePrimitiveIndicator('% of Cap Structure', x => x.classes, x => x.percentOfCapStructure, decimalFormatter),
    new TablePrimitiveIndicator('MVOC, %', x => x.classes, x => x.mvoc, decimalFormatter),
    new TablePrimitiveIndicator('WAL', x => x.classes, x => x.wal, decimalFormatter),
    new TablePrimitiveIndicator('DM, bps', x => x.classes, x => x.dm),
    new TablePrimitiveIndicator('Price', x => x.classes, x => x.priceNumber, decimalFormatter),
    new TablePrimitiveIndicator('O/C Trigger, %', x => x.classes, x => x.ocTrigger, decimalFormatter),
    new TablePrimitiveIndicator('O/C Target, %', x => x.classes, x => x.ocTarget, decimalFormatter),
    new TablePrimitiveIndicator('O/C Cushion, %', x => x.classes, x => x.ocCushion, decimalFormatter),
    new TablePrimitiveIndicator('I/C Trigger, %', x => x.classes, x => x.icTrigger, decimalFormatter),
    new TablePrimitiveIndicator('Rtg (M/S/F/K/D)',x => x.classes, x => x.ratingString, decimalFormatter),
    new TablePrimitiveIndicator('Coupon', x => x.classes, x => x.coupon),
    new TablePrimitiveIndicator('Guidance', x => x.classes, x => x.guidance),
    new TablePrimitiveIndicator('Subscription', x => x.classes, x => x.subscription),
    new TablePrimitiveIndicator('AMR', x => x.classes, x => x.amr, formatUtils.formatBoolean),
    new TablePrimitiveIndicator('Class Status', x => x.classes, x => x.originatingTransactionClassStatus, transactionClassStatusFormatter),
];

type IndicatorsType =
    | IndicatorSection<OriginatingTransactionDiff>
    | TableItemIndicatorsSection<OriginatingTransactionDiff, CollateralQualityTest>
    | TableIndicatorSection<OriginatingTransactionDiff, OriginatingTransactionClassDiff>
    | ListIndicatorSection<OriginatingTransactionDiff, OriginatingTransactionDocument>
    | ImpreciseTableIndicatorSection<OriginatingTransactionDiff, OriginatingTransactionPortfolioItem>;


const getIndicatorSections = (hasLimitedAccess: boolean) =>
    new ListBuilder<IndicatorsType>()
        .add(new IndicatorSection('Overview', overviewIndicators))
        .addWhen(() => !hasLimitedAccess, new IndicatorSection('Collateral Assumptions', collateralAssumptionsIndicators))
        .add(new IndicatorSection('Sources and Uses', sourcesAndUsesIndicators))
        .add(new IndicatorSection('Management Fees', managementFeesIndicators))
        .add(
            new TableItemIndicatorsSection<OriginatingTransactionDiff, CollateralQualityTest>(
                'Portfolio Summary',
                '',
                x => x.collateralQualityTests,
                x => x.value,
                collateralQualityTestsIndicators,
                (value: unknown) => collateralQualityTestValues[value as CollateralQualityTestValue] || '',
            ),
        )
        .add(
            new TableIndicatorSection<OriginatingTransactionDiff, OriginatingTransactionClassDiff>(
                'Structure',
                'Class',
                x => x.classes,
                x => x.name,
                structureIndicators,
            ),
        )
        .addWhen(() => !isMedia,
            new ListIndicatorSection<OriginatingTransactionDiff, OriginatingTransactionDocument>(
                'Documents',
                x => x.documents,
                x => x.name,
                quotesFormatter,
            ),
        )
        .addWhen(() => !isMedia,
            new ImpreciseTableIndicatorSection<OriginatingTransactionDiff, OriginatingTransactionPortfolioItem>(
                'Target Portfolio',
                x => x.portfolio,
            ),
        )
        .result()

export const getIndicatorsConfig = (difference: Difference<OriginatingTransactionDiff>, hasLimitedAccess: boolean, searchTerm?: string[]) => {
    const indicatorSections = getIndicatorSections(hasLimitedAccess);

    if (!searchTerm || searchTerm.every(s => !s.length)) {
        return indicatorSections;
    }

    return indicatorSections
        .filter(indicatorSection => {
            const isMatchSearchTerm = (indicatorSection as ITableSection<unknown>)?.isMatchSearchTerm || stubFalse;

            const isEntityDifferenceMatchSearchTerm = isMatchSearchTerm(searchTerm, difference);

            return isEntityDifferenceMatchSearchTerm || indicatorSection.indicators.some(indicatorMatchesSearchTerm(searchTerm, difference));
        });
}
