import { Contact } from '../../../../../../../types/amr-pipeline/models/Contact';
import { Table } from '../../../../../../bidding/common/table';
import { EmptyPlaceholder } from '../../../../../../common';
import { memberColumns } from './member-columns';

interface Props {
    members?: Contact[];
    primaryContact?: Contact;
}

const MembersTab = ({ members, primaryContact }: Props) => {
    if (!members?.length) {
        return <EmptyPlaceholder text="There are no platform registered users in this company." />;
    }

    return (
        <Table
            className="data-list-striped"
            dataItems={members}
            columns={memberColumns}
            createSecurityCustomArgs={() => ({ primaryContact })}
        />
    );
};

export default MembersTab;
