import React from "react";
import { constants } from "../../../../../../constants";
import { OriginatingTransaction } from "../../../../../../types/amr-pipeline/models/OriginatingTransaction";
import { FormField } from "../../../../common/FormField";
import { SectionContent } from "../../../../common/section/SectionContent";
import { formatUtils } from "../../../../../../utils";
import { Difference } from '../../../../../../utils/differ/types';
import { DifferenceField } from '../../DifferenceField';
import { OriginatingTransactionDiff } from '../../../../../../types/amr-pipeline/models/OriginatingTransactionDiff';
import { ImSubscriptionValue } from '../../../../subscription/ImSubscriptionValue';

interface Props {
    selectedTransaction: OriginatingTransaction;
    difference?: Difference<OriginatingTransactionDiff>;
}

export function ManagementFee({ selectedTransaction, difference }: Props) {
    return (
        <SectionContent
            title="Management Fee"
            className="data-item-management-fee"
        >
            <FormField title="Senior Management Fee, bps">
                <ImSubscriptionValue transaction={selectedTransaction}>
                    <DifferenceField difference={difference?.seniorManagementFee}>
                        {formatUtils.formatDecimal(selectedTransaction.seniorManagementFee) || constants.emptyPlaceholder}
                    </DifferenceField>
                </ImSubscriptionValue>
            </FormField>
            <FormField title="Sub Management Fee, bps">
                <ImSubscriptionValue transaction={selectedTransaction}>
                    <DifferenceField difference={difference?.subManagementFee}>
                        {formatUtils.formatDecimal(selectedTransaction.subManagementFee) || constants.emptyPlaceholder}
                    </DifferenceField>
                </ImSubscriptionValue>
            </FormField>
            <FormField title="Incentive Management Fee, %">
                <ImSubscriptionValue transaction={selectedTransaction}>
                    <DifferenceField difference={difference?.incentiveManagementFee}>
                        {formatUtils.formatDecimal(selectedTransaction.incentiveManagementFee) || constants.emptyPlaceholder}
                    </DifferenceField>
                </ImSubscriptionValue>
            </FormField>
        </SectionContent>
    );
}
