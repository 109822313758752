import React from 'react';
import { isNaN, isNil } from 'lodash';
import { Difference, DifferenceType, ObjectArrayDifference, ObjectArrayItemDifference, PrimitiveDifference } from '../../../../utils/differ/types';
import Highlighter from '../../common/Highlighter';
import { DynamicsIcon } from './DynamicsIcon';
import { Formatter, TableRenderOptions, TableIndicator, TableRenderMode } from './types';
import { OnHoverTooltip } from '../../../common';
import { constants } from '../../../../constants';

export class TablePrimitiveIndicator<T, K> implements TableIndicator<T, K> {
    /**
     * Represents table primitive indicator
     * @param title Indicator title
     * @param fieldDifference Function, which accepts diff of array item, and returns an array of indicator fields
     * @param objectArrayDifference
     * @param formatter
     */
    constructor(
        public title: string,
        protected getArrayDifference: (difference: Difference<T>) => ObjectArrayDifference<K[]> | undefined,
        protected getFieldDifference: (itemDifference: Difference<K>) => PrimitiveDifference<unknown> | undefined,
        protected formatter?: Formatter<K>,
    ) {}

    getCurrentValue(arrayItem: ObjectArrayItemDifference<K>) {
        const fieldDifference = arrayItem.difference && this.getFieldDifference(arrayItem.difference);

        if (!fieldDifference) {
            return null;
        }

        return this.formatter
            ? this.formatter(fieldDifference.currentValue, arrayItem.currentValue)
            : fieldDifference.currentValue;
    }

    getPreviousValue(arrayItem: ObjectArrayItemDifference<K>) {
        const fieldDifference = arrayItem.difference && this.getFieldDifference(arrayItem.difference);

        if (!fieldDifference) {
            return null;
        }

        const formattedValue = this.formatter
            ? this.formatter(fieldDifference.previousValue, arrayItem.previousValue)
            : fieldDifference.previousValue;

        return isNil(formattedValue) || isNaN(formattedValue) ? constants.emptyPlaceholder : formattedValue;
    }

    arrayItemDifferenceMatchesSearchTerm(searchTerms: string[], arrayItem: ObjectArrayItemDifference<K>) {
        const fieldDifference = arrayItem.difference && this.getFieldDifference(arrayItem.difference);

        if (fieldDifference?.type === DifferenceType.Unchanged) {
            return false;
        }

        return (
            !!searchTerms.length &&
            searchTerms.some(
                s =>
                    String(this.getCurrentValue(arrayItem)).toLowerCase().includes(s.toLowerCase()) ||
                    String(this.getPreviousValue(arrayItem)).toLowerCase().includes(s.toLowerCase()) ||
                    String(fieldDifference?.currentValue).toLowerCase().includes(s.toLowerCase()) ||
                    String(fieldDifference?.previousValue).toLowerCase().includes(s.toLowerCase()),
            )
        );
    }

    matchesSearchTerm(searchTerms: string[], difference: Difference<T>) {
        const arrayDifference = this.getArrayDifference(difference);

        if (!arrayDifference) {
            return false;
        }

        return arrayDifference.some(i => this.arrayItemDifferenceMatchesSearchTerm(searchTerms, i));
    }

    rendreTitle(searchTerms: string[]) {
        return (
            <span className="history-class-col">
                <OnHoverTooltip overlay={this.title}>
                    <Highlighter
                        searchWords={searchTerms}
                        autoEscape={true}
                        textToHighlight={this.title}
                    />
                </OnHoverTooltip>
            </span>
        );
    }

    renderAddedRow(
        searchTerms: string[],
        arrayItem: ObjectArrayItemDifference<K>,
        title: JSX.Element,
        withTitle?: boolean
    ) {
        return (
            <React.Fragment key={arrayItem.id as string}>
                {withTitle && <>{title}</>}
                <span className="history-class-col">
                    <Highlighter
                        searchWords={searchTerms}
                        autoEscape={true}
                        textToHighlight={String(this.getCurrentValue(arrayItem))}
                        className="history-class-col"
                    />
                </span>
            </React.Fragment>
        );
    }

    renderUpdatedRow(
        searchTerms: string[],
        arrayItem: ObjectArrayItemDifference<K>,
        title: JSX.Element,
        withTitle?: boolean
    ) {
        const fieldDifference = arrayItem.difference && this.getFieldDifference(arrayItem.difference);

        if (!fieldDifference) {
            return null;
        }

        return (
            <React.Fragment key={arrayItem.id as string}>
                {withTitle && <>{title}</>}
                <span className="history-class-col">
                    <OnHoverTooltip overlay={this.getPreviousValue(arrayItem) as string}>
                        <Highlighter
                            searchWords={searchTerms}
                            autoEscape={true}
                            textToHighlight={String(this.getPreviousValue(arrayItem))}
                        />
                    </OnHoverTooltip>
                </span>
                <span className="history-class-col">
                    {fieldDifference.type === DifferenceType.Removed
                        ? 'removed'
                        : <OnHoverTooltip overlay={this.getCurrentValue(arrayItem) as string}>
                            <>
                                <Highlighter
                                    searchWords={searchTerms}
                                    autoEscape={true}
                                    textToHighlight={String(this.getCurrentValue(arrayItem))}
                                />
                                <DynamicsIcon difference={fieldDifference} />
                            </>
                        </OnHoverTooltip>
                    }
                </span>
            </React.Fragment>
        );
    }

    renderDeletedRow(
        arrayItem: ObjectArrayItemDifference<K>,
        title: JSX.Element,
        withTitle?: boolean
    ) {
        if (!withTitle) {
            return null;
        }

        return (
            <React.Fragment key={arrayItem.id as string}>
                {title}
                <span className="history-class-col">removed</span>
            </React.Fragment>
        );
    }

    renderIndicatorItemView(
        searchTerms: string[] = [],
        arrayItem: ObjectArrayItemDifference<K>,
        options: TableRenderOptions = { withTitle: true, mode: TableRenderMode.ItemIndicator }
    ) {
        const fieldDifference = arrayItem.difference && this.getFieldDifference(arrayItem.difference);

        if (!fieldDifference) {
            return null;
        }

        const { withTitle } = options;

        const title = this.rendreTitle(searchTerms);

        switch (fieldDifference.type) {
            case DifferenceType.Added:
                return this.renderAddedRow(searchTerms, arrayItem, title, withTitle);
            case DifferenceType.Removed:
                return this.renderDeletedRow(arrayItem, title, withTitle);
            case DifferenceType.Updated:
                return this.renderUpdatedRow(searchTerms, arrayItem, title, withTitle);
            default:
                return null;
        }
    }

    renderItemIndicatorView(
        searchTerms: string[] = [],
        arrayItem: ObjectArrayItemDifference<K>,
        options: TableRenderOptions = { withTitle: true, mode: TableRenderMode.ItemIndicator }
    ) {
        const { withTitle } = options;
        const title = this.rendreTitle(searchTerms);

        if (arrayItem.type === DifferenceType.Added) {
            return this.renderAddedRow(searchTerms, arrayItem, title, withTitle);
        }

        return this.renderUpdatedRow(searchTerms, arrayItem, title, withTitle);
    }

    render(
        searchTerms: string[] = [],
        arrayItem: ObjectArrayItemDifference<K>,
        options: TableRenderOptions = { withTitle: true, mode: TableRenderMode.ItemIndicator }
    ) {
        const { mode } = options;

        if (mode === TableRenderMode.ItemIndicator) {
            return this.renderItemIndicatorView(searchTerms, arrayItem, options);
        }

        return this.renderIndicatorItemView(searchTerms, arrayItem, options);
    }

    hasChanges(difference: Difference<T>) {
        const arrayDifference = this.getArrayDifference(difference);

        if (!arrayDifference) {
            return false;
        }

        // In every array element, specified field is not unchanged
        return !arrayDifference.every(x => {
            return x.difference ? this.getFieldDifference(x.difference)?.type === DifferenceType.Unchanged : true;
        });
    }

    diffTypeInItem(item: ObjectArrayItemDifference<K>) {
        const fieldDifference = item.difference && this.getFieldDifference(item.difference);

        return fieldDifference?.type || DifferenceType.Unchanged;
    }

    hasChangesInItem(item: ObjectArrayItemDifference<K>) {
        if (!item.difference) {
            return false;
        }

        return this.getFieldDifference(item.difference)?.type !== DifferenceType.Unchanged;
    }
}
