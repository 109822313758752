import { constants } from '../../../constants/constants';
import IconSVG from '../../../styles/svg-icons';
import { ClassIndicators } from '../../../types/amr-pipeline/models/ClassIndicators';
import cn from 'classnames';
import { OnHoverTooltip } from '../../common';
import { Link } from 'react-router-dom';
import { ActionBlocker } from '../../access/ActionBlocker';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { Currency as TCurrency } from '../../../types/amr-pipeline/models/Currency';
import { Currency } from '../../../types/enums/Currency';

type Props = {
    identifier?: string;
    identifiers?: ClassIndicators;
    text?: string;
    className?: string;
    disabled?: boolean;
    placeholderIfEmpty?: boolean;
    secondaryPlaceholderColor?: boolean;
    checkSubscription?: boolean;
    route: string;
    tooltipText: string;
    iconName: string;
    requiredFeatures: SubscriptionFeature[];
    currency?: TCurrency;
};

export function SecurityBwicLink({
    identifiers,
    identifier,
    className,
    placeholderIfEmpty,
    secondaryPlaceholderColor,
    disabled = false,
    route,
    tooltipText,
    iconName,
    requiredFeatures,
    currency
}: Props) {
    const identifierForLink = identifier ?? getIdentifier(identifiers);

    if (!identifierForLink) {
        return placeholderIfEmpty ? (
            <span className={cn({ 'action-empty-placeholder': secondaryPlaceholderColor })}>
                {constants.emptyPlaceholder}
            </span>
        ) : null;
    }

    if (disabled) {
        return (
            <button className={cn('btn-link', className)} disabled={disabled}>
                <IconSVG name={iconName} width={16} height={16} />
            </button>
        );
    }

    function getIdentifier(identifiers?: ClassIndicators) {
        if (!identifiers) {
            return undefined;
        }

        const { cusiP144A, isiN144A } = identifiers;

        if (currency && currency.code === Currency.EUR) {
            return isiN144A;
        }

        return cusiP144A;
    }

    const renderBlockedView = () => (
        <button className={cn('btn-link', className)} disabled>
            <IconSVG name={iconName} width={16} height={16} />
        </button>
    );

    const renderTooltip = () => (
        <OnHoverTooltip overlay={tooltipText}>
            <Link
                target="_blank"
                to={`${route}?dateFrom=unspecified&dateTo=unspecified&isinCusipsAndTickers=${identifierForLink}`}
                rel="noreferrer"
                className={cn('btn-link', className)}
            >
                <IconSVG name={iconName} width={16} height={16} />
            </Link>
        </OnHoverTooltip>
    );

    return (
        <ActionBlocker features={requiredFeatures} requireAllFeatures>
            {blocked => (blocked ? renderBlockedView() : renderTooltip())}
        </ActionBlocker>
    );
}
