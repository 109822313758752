import { useContext } from 'react';
import { TransactionAccessType } from '../../../../../types/amr-pipeline/enums/TransactionAccessType';
import { SectionContent } from "../../../common/section/SectionContent";
import { TabBody } from "../../../common/tabs/TabBody";
import TransactionContext from '../../../transactionContext';
import { CapitalStructure } from "../capital-structure/CapitalStructure";
import { TransactionTabProps } from '../types';
import { withTabTracking } from '../withTabTracking';
import { AdministrativeFee } from "./sections/AdministrativeFee";
import { CollateralAssumptions } from "./sections/CollateralAssumptions";
import { CollateralQualityTests } from "./sections/CollateralQualityTests";
import { ManagementFee } from "./sections/ManagmentFee";
import { SourceAndUses } from "./sections/SourceAndUses";

const DealStructureTab = ({ transaction, difference }: TransactionTabProps) => {
    const { limitedAccess } = useContext(TransactionContext);

    return (
        <TabBody className="tabs-data-padding component-tabs-deal-details">
            <SectionContent
                title="Capital Structure"
                className="data-item-capital-structure data-list-striped-cap-structure data-item-cap-structure"
            >
                <CapitalStructure
                    items={transaction.classes}
                    difference={difference?.classes}
                    transaction={transaction}
                    withCoverage
                />
            </SectionContent>
            {!limitedAccess && (
                <>
                    <div className="data-row">
                        <CollateralAssumptions selectedTransaction={transaction} difference={difference} />
                        <SourceAndUses selectedTransaction={transaction} difference={difference} />
                    </div>
                    <CollateralQualityTests
                        selectedTransaction={transaction}
                        items={transaction.collateralQualityTests}
                        difference={difference?.collateralQualityTests}
                    />
                    <div className="data-row">
                        <ManagementFee selectedTransaction={transaction} difference={difference} />
                        <AdministrativeFee selectedTransaction={transaction} difference={difference} />
                    </div>
                </>
            )}
        </TabBody>
    );
};

export const DealStructure = withTabTracking(TransactionAccessType.Structure)(DealStructureTab);
