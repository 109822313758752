import { IColumnDefinition } from "../../../../bidding/common/table/types/ColumnDefinition";
import { ColumnBuilder } from "../../../../bidding/common/table/columns/column-builder/ColumnBuilder";
import { constants } from "../../../../../constants";
import { amrFormatUtils, moneyUtils, numericUtils } from "../../../../../utils";
import { transactionClassStatusTitles } from "../../../../../types/amr-pipeline/enums/OriginatingTransactionClassStatus";
import { OriginatingTransCapitalStructure } from "../../../detailed/new-issue/capital-structure/CapitalStructure";

const mailColumnDefinitions: IColumnDefinition<OriginatingTransCapitalStructure>[] =
    [
        {
            columnKey: "className",
            renderColumnHeaderContent: () => "Class",
            renderColumnContent: (transactionClass) => transactionClass.name,
            className: "data-list-cell-xxs-02",
        },
        {
            columnKey: "rating",
            renderColumnHeaderContent: () => "Rtg (M/S/F/K/D)",
            renderColumnContent: (transactionClass) =>
                amrFormatUtils.formatRating(transactionClass),
            className: "data-list-cell-md",
        },
        {
            columnKey: "balance",
            renderColumnHeaderContent: () => "Balance",
            renderColumnContent: (
                transactionClass: OriginatingTransCapitalStructure
            ) =>
                transactionClass.balance != null &&
                    numericUtils.isNumber(transactionClass.balance)
                    ? moneyUtils.money(transactionClass.balance)
                    : constants.emptyPlaceholder,
            className: "text-right",
        },
        {
            columnKey: "coupon",
            renderColumnHeaderContent: () => "Coupon",
            renderColumnContent: (transactionClass) => transactionClass.coupon,
            className: "data-list-cell-sm",
        },
        {
            columnKey: "price",
            renderColumnHeaderContent: () => "Price",
            renderColumnContent: (transactionClass) =>
                transactionClass.price || constants.emptyPlaceholder,
            className: "data-list-cell-xs text-right"
        },
        {
            columnKey: "guidance",
            renderColumnHeaderContent: () => "Guidance",
            renderColumnContent: (transactionClass) =>
                transactionClass.guidance || constants.emptyPlaceholder,
        },
        {
            columnKey: "subscription",
            renderColumnHeaderContent: () => "Subscription",
            renderColumnContent: (transactionClass) =>
                transactionClass.subscription || constants.emptyPlaceholder,
        },
        {
            columnKey: "originatingTransactionClassStatus",
            renderColumnHeaderContent: () => "Class Status",
            renderColumnContent: (transactionClass) =>
                transactionClass.originatingTransactionClassStatus
                    ? transactionClassStatusTitles[
                    transactionClass.originatingTransactionClassStatus
                    ]
                    : constants.emptyPlaceholder,
        },
    ];

export const mailColumns = mailColumnDefinitions.map((c) => {
    return new ColumnBuilder(c);
});
