import IconSVG from '../../../styles/svg-icons';
import { Transaction } from '../../../types/amr-pipeline/models/Transaction';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { hasArrangerAccess } from '../../../utils/amr-pipeline.utils';
import { ActionBlocker } from '../../access/ActionBlocker';

export const ImSubscriptionValue = ({
    children,
    transaction,
    overrideRequiredFeatures,
}: {
    children: React.ReactNode;
    transaction?: Transaction;
    overrideRequiredFeatures?: boolean;
}) => {
    const hasArrangerProSubscription = hasArrangerAccess(transaction);

    if (hasArrangerProSubscription || overrideRequiredFeatures) {
        return <>{children}</>;
    }

    return (
        <ActionBlocker
            overrideRequiredFeatures={overrideRequiredFeatures}
            feature={SubscriptionFeature.IssuanceMonitorFullAccess}
        >
            {(blocked) => blocked
                ? <IconSVG width="16" height="16" name="subscribe-lock-grey" />
                : <>{children}</>
            }
        </ActionBlocker>
    );
};
