import { constants } from "../../../../../constants";
import { OnHoverTooltip } from "../../../../common";

interface PercentageBarProps {
    title: string;
    maxValue?: number;
    value?: number;
}

const lineWidth = 220;

export const PercentageBar = ({
    title,
    maxValue = 0,
    value = 0,
}: PercentageBarProps) => {
    const fillingPercent = ((value / maxValue) * lineWidth) || 0;

    return (
        <div className="percentage-bar">
            <div className="percentage-bar-label">{title}</div>

            <div className="percentage-bar-chart">

                <div
                    className="percentage-bar-progress"
                    style={{ width: fillingPercent }}
                >
                    <OnHoverTooltip
                        overlay={`${value} view(s)`}
                        placement="right"
                        disabled={!value}
                        overlayClassName="viewed-tabs-tooltip"
                    />
                </div>
            </div>
            <div className="percentage-bar-stat">
                {value ? `${value} view(s)` : constants.emptyPlaceholder}
            </div>
        </div>
    );
};
