import React from 'react';
import { compareStrings } from '../../../../utils/compare.utils';
import { Difference, DifferenceType, ObjectArrayDifference, ObjectArrayItemDifference, PrimitiveDifference } from '../../../../utils/differ/types';
import Highlighter from '../../common/Highlighter';
import { TableSectionWrapper } from './TableSectionWrapper';
import { IndicatorItems, ITableSection, TableIndicator, TableRenderMode } from './types';
import { indicatorMatchesSearchTerm } from './utils';

/**
 * This section renders changes in indicator-items view.
 * Indicator-items view presents it in a way that Indicator A has changes in items A, B and C.
 */
export class TableIndicatorItemsSection<T, K extends unknown> implements ITableSection<T> {
    private indicatorItems: IndicatorItems<T, K>[] = [];

    constructor(
        public title: string,
        public entityTitle: string,
        protected getItemsDifference: (difference: Difference<T>) => ObjectArrayDifference<K[]> | undefined,
        protected getEntityDifference: (itemDifference: Difference<K>) => PrimitiveDifference<unknown> | undefined,
        public indicators: TableIndicator<T, K>[],
        protected entityTitleFormatter?: (value: unknown) => string,
    ) {
        this.isMatchSearchTerm = this.isMatchSearchTerm.bind(this);
    }

    get renderedIndicatorItems() {
        return this.indicatorItems;
    }

    isMatchSearchTerm(searchTerms: string[], difference: Difference<T>) {
        if (!searchTerms.length || searchTerms.some(s => this.entityTitle.toLowerCase().includes(s.toLowerCase()))) {
            return true;
        }

        const itemsDifference = this.getItemsDifference(difference);

        const entityMatchSearchTerm = !!itemsDifference?.reduce((acc: boolean, item) => {
            // Preserve 'true' value
            if (acc) {
                return acc;
            }

            if (!item.difference) {
                return false;
            }

            const entityDifference = this.getEntityDifference(item.difference);
            const searchValue = this.entityTitleFormatter
                ? this.entityTitleFormatter(String(entityDifference?.derivedValue))
                : `${this.entityTitle} ${String(entityDifference?.derivedValue)}`;

            const isSearchTermMatchTitle = searchTerms.some(
                s => searchValue.toLowerCase().includes(s.toLowerCase())
            );

            return entityDifference?.type !== DifferenceType.Unchanged && isSearchTermMatchTitle;
        }, false);

        const indicatorTitleInSectionMatchSearchTerm = this.indicatorItems.reduce((acc: boolean, item) => {
            // Preserve 'true' value
            if (acc) {
                return acc;
            }

            return item.items.some(i => i.type !== DifferenceType.Unchanged && searchTerms.some(s => `${this.entityTitle} ${String(i?.id)}`.toLowerCase() === s.toLowerCase()));

        }, false);

        return entityMatchSearchTerm || indicatorTitleInSectionMatchSearchTerm;
    }

    hasChanges(difference: Difference<T>) {
        return this.indicators.some(indicator => indicator.hasChanges(difference));
    }

    renderHighlighted(searchTerms: string[], value: string) {
        return <Highlighter searchWords={searchTerms} autoEscape={true} textToHighlight={value} />;
    }

    renderHeader(indicator: TableIndicator<T, K>, searchTerms: string[], type: DifferenceType) {
        if (type === DifferenceType.Updated) {
            return (
                <>
                    <Highlighter searchWords={searchTerms} textToHighlight={this.entityTitle} />
                    <span>From</span>
                    <span>To</span>
                </>
            );
        }

        if (type === DifferenceType.Added) {
            return (
                <>
                    <Highlighter searchWords={searchTerms} textToHighlight={this.entityTitle} />
                    <span>{this.renderHighlighted(searchTerms, indicator.title)}</span>
                </>
            );
        }

        return <span>{this.entityTitle}</span>;
    }

    renderIndicatorsView(searchTerms: string[], indicatorItems: IndicatorItems<T, K>[], difference: Difference<T>) {
        const indicatorItemsWithChanges = indicatorItems.filter(({ indicator }) => {
            return indicatorMatchesSearchTerm(searchTerms, difference)(indicator) || this.isMatchSearchTerm(searchTerms, difference)
        });

        const getEntityIdTitle = (item: ObjectArrayItemDifference<K>) => {
            const entityDifferenceValue =
                item.difference && (this.getEntityDifference(item.difference) as PrimitiveDifference<unknown>);

            return this.entityTitleFormatter
                ? this.entityTitleFormatter(entityDifferenceValue?.derivedValue)
                : (entityDifferenceValue?.derivedValue as string);
        };

        // Divide indicators on three main sections — those were added, updated and removed.
        // Indicator doesn't live by its own, it depends on a particular item diff. `Price` as an
        // indicator may be `added` in class A, or may be `removed` in class B. Thus, we need
        // to go through all item diffs and check each indicator on it to determine the diff type.
        // To avoid this procedure further in render, we map it to a primitive structure with indicator,
        // items and diff type

        // Filters by Difference type
        const indicatorInItemFilter = (type: DifferenceType) => (indicatorItem: IndicatorItems<T, K>) =>
            indicatorItem.items.find(item => indicatorItem.indicator.diffTypeInItem(item) === type);

        // Maps to convenient sructure
        const indicatorItemDifferenceTypeMapper = (type: DifferenceType) => (indicatorItems: IndicatorItems<T, K>) => ({
            ...indicatorItems,
            type,
        });

        // Combines filter and mapping
        const indicatorInItemFilterMap = (indicatorItems: IndicatorItems<T, K>[], type: DifferenceType) =>
            indicatorItems.filter(indicatorInItemFilter(type)).map(indicatorItemDifferenceTypeMapper(type));

        // And used here to sort by difference type
        const indicatorsItemsWithDiffType = [
            ...indicatorInItemFilterMap(indicatorItemsWithChanges, DifferenceType.Added),
            ...indicatorInItemFilterMap(indicatorItemsWithChanges, DifferenceType.Updated),
            ...indicatorInItemFilterMap(indicatorItemsWithChanges, DifferenceType.Removed),
        ];

        return indicatorsItemsWithDiffType.map(({ indicator, items, type }) => (
            <React.Fragment key={`${indicator.title}${type}`}>
                <li>
                    {this.renderHighlighted(searchTerms, indicator.title)} {type}
                    <div className="history-table">
                        <div className="history-row history-row-header">
                            {this.renderHeader(indicator, searchTerms, type)}
                        </div>
                        {items.map((item, index) => {
                            const entityIdTitle = getEntityIdTitle(item);
                            const searchTermWithId = searchTerms.some(s =>
                                s.toLowerCase().startsWith(`${this.entityTitle} ${entityIdTitle}`.toLowerCase()),
                            )
                                ? [...searchTerms, entityIdTitle]
                                : searchTerms;

                            if (indicator.diffTypeInItem(item) !== type) {
                                return null;
                            }

                            return (
                                <div key={`${indicator.title}${type}${index}`} className="history-row">
                                    <span className="history-class-col">
                                        <Highlighter textToHighlight={entityIdTitle} searchWords={searchTermWithId} />
                                    </span>
                                    {indicator.render(searchTerms, item, { mode: TableRenderMode.IndicatorItem })}
                                </div>
                            );
                        })}
                    </div>
                </li>
            </React.Fragment>
        ));
    }

    renderSection(
        searchTerms: string[] = [],
        difference: Difference<T>,
    ) {
        if (!this.hasChanges(difference)) {
            return null;
        }

        // Just to not do excess job in next steps
        if (
            searchTerms.some(s => !!s.length) &&
            !this.isMatchSearchTerm(searchTerms, difference) &&
            !this.indicators.some(indicatorMatchesSearchTerm(searchTerms, difference))
        ) {
            return null;
        }

        const itemsDifference = this.getItemsDifference(difference);

        if (!itemsDifference) {
            return null;
        }

        // Depending on what we collect first (indicators or items), will define priority of modes
        // Here we collect items, which were changed under particular indicator (Indicator View)
        this.indicatorItems = this.indicators.reduce((acc: IndicatorItems<T, K>[], indicator) => {
            // Collect items, that have changes in current indicator (with one additional condition)
            const items = itemsDifference.filter(item => {
                // Include only updated items (full row)
                // Give back priority for Added and Deleted rows to `Item view`
                if (item.type !== DifferenceType.Updated) {
                    return false;
                }

                // Include if it has any changes
                return indicator.hasChangesInItem(item);
            });

            const isSearchTermMatchEntityTitle = itemsDifference.some(({ type, id }) =>
                type !== DifferenceType.Unchanged &&
                searchTerms.some(s =>
                    `${this.entityTitle} ${String(id)}`.toLowerCase() === s.toLowerCase(),
                ),
            );

            // If there's more than 1 item collected and user is not searching by entity — it's a green light
            // to show it as `Indicator view`
            if (items.length > 1 && !isSearchTermMatchEntityTitle) {
                return [...acc, { indicator, items }];
            }

            return acc;
        }, []);

        // Perform sorting by title/id of indicator/item, so present it more accurately
        this.indicatorItems.sort((a, b) => compareStrings(a.indicator.title, b.indicator.title));

        return this.renderIndicatorsView(searchTerms, this.indicatorItems, difference);
    }

    render(searchTerms: string[] = [], difference: Difference<T>) {
        const section = this.renderSection(searchTerms, difference);

        if (!section) {
            return null;
        }

        return <TableSectionWrapper title={this.title}>{section}</TableSectionWrapper>;
    }
}
