import * as React from 'react';

interface InlineAlertPanelProps {
    isWarning?: boolean,
    children: any,
    className: string
}

export function InlineAlertPanel({ isWarning = false, children, className }: InlineAlertPanelProps) {
    return (
        <p className={`panel-info ${className}`}>
            {isWarning && <i className="icon icon-warning yellow" />}
            {children}
        </p>
    );
}
