import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { amrPipelineDetailedActions, gridActions } from '../../../../../actions';
import { roles } from '../../../../../constants';
import { TransactionStatus } from '../../../../../types/amr-pipeline/enums/TransactionStatus';
import { AppState } from '../../../../../types/state/AppState';
import { GridDataItem } from '../../../../../types/state/GridState';
import { user } from '../../../../../user';
import { Table } from '../../../../bidding/common/table/Table';
import { Preloader, EmptyPlaceholder, OnHoverTooltip } from '../../../../common';
import { Checkbox, Popup, PopupBody, PopupFooter } from '../../../../controls';
import { Grid } from '../../../../grid';
import { RouteLeavingGuard } from '../../../../routing';
import { SectionContent } from '../../../common/section/SectionContent';
import { TabBody } from '../../../common/tabs/TabBody';
import { TabContent } from '../../../common/tabs/TabContent';
import { investorColumns, arrangerColumns } from './ioi-columns';
import { IOI } from '../../../../../types/amr-pipeline/models/IOI';
import IconSVG from '../../../../../styles/svg-icons';
import { InvestorPopup } from './investorPopup/InvestorPopup';
import { OriginatingTransactionClassStatus } from '../../../../../types/amr-pipeline/enums/OriginatingTransactionClassStatus';
import { TransactionTabProps } from '../types';
import { PipelineDetailedTabTypes } from '../../../types/PipelineDetailedTabTypes';
import { withTabTracking } from '../withTabTracking';
import { TransactionAccessType } from '../../../../../types/amr-pipeline/enums/TransactionAccessType';
import { isCollateralManagersOwnTransaction } from '../../../../../utils/amr-pipeline.utils';
import { SubscriptionFeature } from '../../../../../types/billing/SubscriptionFeature';
import { ActionBlocker } from '../../../../access/ActionBlocker';
import { useAppDispatch } from '../../../../../effects/useAppDispatch';
import { useHistory } from 'react-router';

interface IOIsTabProps extends TransactionTabProps {
    isLoading?: boolean;
    activeTab: PipelineDetailedTabTypes;
}

const IOIsTab = ({ transaction, isLoading, activeTab }: IOIsTabProps) => {
    const dispatch = useAppDispatch();
    const history = useHistory();

    const { dealReferenceName, referenceName, iois, status, dealLegalName } = transaction;
    const editIOIs = useSelector((state: AppState) => state.issuanceMonitor.amrPipelineDetailed.editIOIs);
    const isInvestorPopupVisible =  useSelector((state: AppState) => state.issuanceMonitor.amrPipelineDetailed.investorPopup.isVisible);
    const dataItems: GridDataItem<IOI>[] = useSelector((state: AppState) => state.grid.dataItems);
    const nonDraftDataItems = dataItems.filter(item => !item.draft);

    const [isUnsavedChangesPopupVisible, setUnsavedChangesPopupVisible] = useState(false);
    const [isDeleteAllPopupVisible, setDeleteAllPopupVisible] = useState(false);
    const [showDeletedClasses, setShowDeletedClasses] = useState(false);

    const isCollateralManagerOwnTransactions = isCollateralManagersOwnTransaction(transaction);
    const isSellerTrader = user.hasRoles(roles.SellerTrader);
    const isSellerViewer = user.hasRoles(roles.SellerViewer);
    const isSeller = isSellerTrader || isSellerViewer;
    const isInvitedClient = user.hasSingleRole(roles.ArrangersClient);

    const unreadIOIsLength = !isSeller && iois?.filter(ioi => !ioi.isRead).length;
    const ioisWithoutDeletedClasses = iois?.filter(ioi => !ioi.isDeleted);

    const isAllClassesDisabledForIoIs =
        !transaction.classes.length ||
        transaction.classes.every(
            classItem =>
                classItem.originatingTransactionClassStatus === OriginatingTransactionClassStatus.NotOffered ||
                classItem.originatingTransactionClassStatus === OriginatingTransactionClassStatus.Subject,
        );
    const showAddButton =
        (isSellerTrader || isInvitedClient) && !isCollateralManagerOwnTransactions && transaction.status === TransactionStatus.Active && !isAllClassesDisabledForIoIs;

    const allOnDeletedClasses = iois?.every(ioi => ioi.isDeleted);
    const someOnDeletedClasses = iois?.some(ioi => ioi.isDeleted);
    const ioisList = showDeletedClasses ? iois : ioisWithoutDeletedClasses;
    const isIOIsListWithContent = !!ioisList?.length;
    const showEditButton = isIOIsListWithContent && !isCollateralManagerOwnTransactions && (isSellerTrader || isInvitedClient) && transaction.status === TransactionStatus.Active;

    useEffect(() => {
        return () => {
            dispatch(amrPipelineDetailedActions.editIOIs(false));
            dispatch(gridActions.reset());
        };
    }, [dispatch]);

    useEffect(() => {
        if (iois && unreadIOIsLength) {
            dispatch(
                amrPipelineDetailedActions.readIOIsByUser(
                    dealReferenceName,
                    referenceName,
                    iois.map(ioi => ioi.ioiReferenceName),
                ),
            );
            dispatch(amrPipelineDetailedActions.setUnreadIOIsRead(referenceName));
        }
    }, [dealReferenceName, referenceName, unreadIOIsLength, dispatch, iois]);

    const getPlaceholderText = () => {
        if (isAllClassesDisabledForIoIs) {
            return 'No classes available for submitting IOIs';
        }

        if ((isSellerTrader || isInvitedClient) && status === TransactionStatus.Active) {
            return 'There are no submitted IOIs yet. To submit IOIs just add a new one.';
        }
        if ((isSellerTrader || isInvitedClient) && status !== TransactionStatus.Active) {
            return 'No information to display. IOIs can be submitted when the deal is in ‘Active’ status.';
        }

        if (status === TransactionStatus.Active) {
            return 'There are no submitted IOIs yet.';
        }

        return 'There is no information to display';
    };

    const isEditWithChanges = () => {
        const isChangedOrDeleted = (original: IOI, editItem?: GridDataItem<IOI>) => {
            if (!editItem) {
                return true;
            }

            const { draft, errors, ...fields } = editItem;
            return !isEqual(original, fields);
        };

        if (!editIOIs) {
            return false;
        }

        if (nonDraftDataItems.length !== iois.length) {
            return true;
        }

        return iois.some(i =>
            isChangedOrDeleted(
                i,
                nonDraftDataItems.find(d => d?.ioiReferenceName === i.ioiReferenceName),
            ),
        );
    };

    const handleSubmitButtonClick = () => {
        if (iois.length && !nonDraftDataItems.length) {
            setDeleteAllPopupVisible(true);
            return;
        }

        submitIOIs();
    };

    const submitIOIs = () => {
        dispatch(amrPipelineDetailedActions.submitIOIs(dealReferenceName, referenceName, dealLegalName, activeTab));
    };

    const showEditMode = () => {
        dispatch(amrPipelineDetailedActions.showGridIOIs(referenceName));
    };

    const exportIOIs = () => {
        dispatch(amrPipelineDetailedActions.exportIOIs(dealReferenceName, referenceName, !showDeletedClasses));
    };

    const cancelEdit = () => {
        if (isEditWithChanges()) {
            setUnsavedChangesPopupVisible(true);
        } else {
            dispatch(amrPipelineDetailedActions.editIOIs(false));
        }
    };

    const renderViewActionButtons = () => {
        if (!iois.length) {
            return null;
        }

        return (
            <div className="controls-iois">
                {someOnDeletedClasses && (
                    <Checkbox
                        label="Show removed classes"
                        checked={showDeletedClasses}
                        onChange={() => setShowDeletedClasses(!showDeletedClasses)}
                    />
                )}
                {!allOnDeletedClasses && (
                    <ActionBlocker
                        feature={SubscriptionFeature.IssuanceMonitorFullAccess}
                        overrideRequiredFeatures={isInvitedClient}
                    >
                        {blocked => (
                            <button disabled={blocked} onClick={exportIOIs} className="btn-link">
                                <IconSVG name="export" width="16" height="16" />
                                Export
                            </button>
                        )}
                    </ActionBlocker>
                )}
                {showEditButton && (
                    <OnHoverTooltip
                        overlay={isAllClassesDisabledForIoIs ? 'No classes available for submitting IOIs' : null}
                        placement="bottomRight"
                    >
                        <button
                            disabled={isAllClassesDisabledForIoIs}
                            onClick={showEditMode}
                            className="btn btn-sm btn-ghost"
                        >
                            Edit
                        </button>
                    </OnHoverTooltip>
                )}
            </div>
        );
    };

    const renderEditActionButtons = () => {
        return (
            <>
                <button onClick={cancelEdit} className="btn btn-sm btn-ghost">
                    Cancel
                </button>
                <button
                    className="btn btn-sm btn-main btn-save-iois"
                    disabled={!isEditWithChanges()}
                    onClick={handleSubmitButtonClick}
                >
                    Save
                </button>
            </>
        );
    };

    const renderActionButtons = () => (
        <div className="flex-row">
            <div className="flex-item-right">
                {editIOIs ? renderEditActionButtons() : renderViewActionButtons()}
            </div>
        </div>
    );

    return (
        <TabContent>
            <TabBody className="tabs-data-padding component-tabs-iois position-relative">
                <Preloader inProgress={!!isLoading}>
                    <SectionContent className="container-flex" actionBtn={renderActionButtons()}>
                        {editIOIs ? (
                            <Grid dataUploadDisabled />
                        ) : isIOIsListWithContent ? (
                            <Table
                                createSecurityCustomArgs={() => ({classes: transaction.classes})}
                                className="data-list-striped"
                                createSecurityCustomClassName={(ioi: IOI) => ioi.isDeleted ? 'deleted' : null}
                                dataItems={ioisList}
                                columns={isSeller || isInvitedClient  ? investorColumns() : arrangerColumns()}
                            />
                        ) : (
                            <EmptyPlaceholder text={getPlaceholderText()}>
                                {showAddButton && (
                                        <button
                                            className="btn btn-main text-transform-none"
                                            onClick={showEditMode}
                                        >
                                            ADD IOIs
                                        </button>
                                    )}
                            </EmptyPlaceholder>
                        )}
                    </SectionContent>
                </Preloader>
            </TabBody>
            {isUnsavedChangesPopupVisible && (
                <Popup title="Unsaved Changes" onClose={() => setUnsavedChangesPopupVisible(false)}>
                    <PopupBody>
                        <p className="status-message alert">
                            <i className="icon icon-warning" />
                            <span className="status-message-cnt">
                                You have unsaved changes. Are you sure you want to leave?
                            </span>
                        </p>
                    </PopupBody>
                    <PopupFooter>
                        <button className="btn btn-ghost" onClick={() => setUnsavedChangesPopupVisible(false)}>
                            Cancel
                        </button>
                        <button
                            className="btn btn-main"
                            onClick={() => {
                                dispatch(amrPipelineDetailedActions.editIOIs(false));
                                setUnsavedChangesPopupVisible(false);
                            }}
                        >
                            Confirm
                        </button>
                    </PopupFooter>
                </Popup>
            )}
            {isDeleteAllPopupVisible && (
                <Popup title="Confirmation" onClose={() => setDeleteAllPopupVisible(false)}>
                    <PopupBody>
                        <p className="status-message alert">
                            <i className="icon icon-warning" />
                            <span className="status-message-cnt">Are you sure you want to delete all IOIs?</span>
                        </p>
                    </PopupBody>
                    <PopupFooter>
                        <button className="btn btn-ghost" onClick={() => setDeleteAllPopupVisible(false)}>
                            Cancel
                        </button>
                        <button
                            className="btn btn-main"
                            onClick={() => {
                                submitIOIs();
                                setDeleteAllPopupVisible(false);
                            }}
                        >
                            Yes
                        </button>
                    </PopupFooter>
                </Popup>
            )}
            {isInvestorPopupVisible && <InvestorPopup />}
            <RouteLeavingGuard
                navigate={(pathname: string) => history.push(pathname)}
                shouldBlockNavigation={isEditWithChanges}
            />
        </TabContent>
    );
};

export const IOIs = withTabTracking(TransactionAccessType.Iois)(IOIsTab);
