import { roles, routes } from "../../../constants";
import { SubscriptionFeature } from "../../../types/billing/SubscriptionFeature";
import { ActionBlocker } from "../../access/ActionBlocker";
import { FeatureLink } from "../../access/FeatureLink";
import { RoleActionBlocker } from "../../access/RoleActionBlocker";

interface Props {
    transactionReferenceName: string;
    dealReferenceName: string;
    children?: React.ReactNode;
    requiredFeatures?: SubscriptionFeature[];
}


export function ImTransactionDetailsLink({
    transactionReferenceName, dealReferenceName, requiredFeatures, children }: Props) {

    return (
    <RoleActionBlocker roles={roles.getAllRolesExclude(roles.Viewer)}>
            {
                roleBlocked =>
                    <ActionBlocker features={requiredFeatures} overrideRequiredFeatures={roleBlocked}>
                        <FeatureLink
                            className="btn-link secondary regular"
                            path={routes.transactionDetailUrl(transactionReferenceName, dealReferenceName)}
                            target="_blank"
                        >
                            <>{children}</>
                        </FeatureLink>
                    </ActionBlocker>
            }
        </RoleActionBlocker>

    );
}