import classnames from 'classnames';
import IconSVG from '../../../styles/svg-icons';
import { FilterLink, FilterLinkBaseProps } from './FilterLink';

export function FilterLinkIcon(props: FilterLinkBaseProps) {
    return (
        <FilterLink
            {...props}
            renderContent={(isLinkAvailable, onClick) =>
                <button onClick={onClick} disabled={props.disabled} className={classnames('btn-link', { 'btn-ghost': !isLinkAvailable })}>
                    <IconSVG name="go-to" width={16} height={16} />
                </button>
            }
        />
    );
}
