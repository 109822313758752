import React from "react";
import { useSelector } from "react-redux";
import { amrPipelineDetailedActions, imDetailsDealsActions } from "../../../../actions";
import { AmrTransaction } from "../../../../types/amr-pipeline/models/AmrTransaction";
import { Deal } from "../../../../types/amr-pipeline/models/Deal";
import { AppState } from "../../../../types/state/AppState";
import { ImDetailsDealsState } from "../../../../types/state/ImDetailsDealsState";
import { AmrDealTabTypes } from "../../types/AmrDealTabTypes";
import { AmrDealTrancheTabTypes } from "../../types/AmrDealTrancheTabTypes";
import { TransactionDetails} from "./transaction-details/TransactionDetails";
import { ClassDetails } from "./class-details/ClassDetails";
import { useAppDispatch } from "../../../../effects/useAppDispatch";


interface Props {
    transaction: AmrTransaction;
    classReferenceName?: string;
    activeTab?: AmrDealTabTypes | AmrDealTrancheTabTypes;
    disabled?: boolean;
}

export const AmrDetails = ({
    transaction,
    classReferenceName,
    activeTab = AmrDealTabTypes.details,
    disabled,
}: Props) => {
    const dispatch = useAppDispatch();
    const { isDealDetailsRequesting, isTrancheDetailsRequesting, deals } = useSelector<
        AppState,
        ImDetailsDealsState
    >((store) => store.imDetailsDeals);

    const loadedTransactionsWithDetails= useSelector((store: AppState) => store.issuanceMonitor.amrPipelineDetailed.loadedTransactionsWithDetails);

    const deal: Deal | undefined = deals?.find(
        (d) => d.referenceName === transaction?.dealReferenceName
    );
    const selectedTranche =
        deal && classReferenceName
            ? deal.classes.find((a) => a.referenceName === classReferenceName)
            : undefined;
    const actualAuction = selectedTranche?.actualAuction;
    const auctions = selectedTranche?.auctions;
    const selectedClass = transaction.classes.find(
        (c) => c.referenceName === classReferenceName
    );

    React.useEffect(() => {
        if (!loadedTransactionsWithDetails.some(t => t.referenceName === transaction.referenceName)) {
            dispatch(amrPipelineDetailedActions.storeTransactionDetails(transaction.referenceName, transaction));
        }

        if (!isDealDetailsRequesting) {
            dispatch(
                imDetailsDealsActions.loadDealDetails(
                    transaction.dealReferenceName,
                    transaction.referenceName
                )
            );
        }

        if (selectedTranche && !isTrancheDetailsRequesting) {
            dispatch(
                imDetailsDealsActions.loadTrancheDetails(
                    transaction.dealReferenceName,
                    selectedTranche.referenceName
                )
            );
        }
    }, [
        dispatch,
        transaction,
        selectedTranche,
        isDealDetailsRequesting,
        isTrancheDetailsRequesting,
        loadedTransactionsWithDetails
    ]);

    return (
        <>
            {
                selectedClass
                    ? <ClassDetails
                        transactionReferenceName={transaction.referenceName}
                        deal={deal}
                        amrClass={{...selectedClass, auctions, actualAuction}}
                        isAuctionServiceProvider={false}
                        disabled={disabled}
                        activeTab={activeTab as AmrDealTrancheTabTypes}
                    />
                    : <TransactionDetails
                        deal={deal}
                        transaction={transaction}
                        transactionReferenceName={transaction.referenceName}
                        activeTab={activeTab as AmrDealTabTypes}
                        disabled={disabled}
                    />

            }
        </>
    );
};
