import { Link } from "react-router-dom";
import { routes } from "../../../constants";

interface HistoryInfoProps {
    versionTitle: string;
    referenceName: string;
    dealReferenceName: string;
}

export const HistoryInfo = ({
    versionTitle,
    referenceName,
    dealReferenceName,
}: HistoryInfoProps) => (
    <div className="status-message info history-info-head">
        <i className="icon icon-info" />
        <span className="status-message-cnt">
            You are viewing <b>{versionTitle}</b>
        </span>
        <Link
            className="btn btn-sm btn-main flex-item-right"
            to={routes.transactionDetailUrl(referenceName, dealReferenceName)}
        >
            Exit history
        </Link>
    </div>
);
