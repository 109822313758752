import { findAll } from "../../../utils/highlightWord.utils";
import { createElement } from "react";
interface Props {
    activeClassName?: string;
    activeIndex?: number;
    activeStyle?: React.CSSProperties;
    autoEscape?: boolean;
    caseSensitive?: boolean;
    className?: string;
    findChunks?: () => {};
    highlightClassName?: string;
    highlightStyle?: React.CSSProperties;
    highlightTag?: string;
    sanitize?: (s: string) => string;
    searchWords: string[];
    textToHighlight: string;
    unhighlightClassName?: string;
    unhighlightStyle?: React.CSSProperties;
}

export default function Highlighter({
    activeClassName = "",
    activeIndex = -1,
    activeStyle = {},
    autoEscape = false,
    caseSensitive = false,
    className = "",
    highlightClassName = "",
    highlightStyle = {},
    highlightTag = "mark",
    sanitize = undefined,
    searchWords,
    textToHighlight,
    unhighlightClassName = "",
    unhighlightStyle = {},
    ...rest
}: Props) {
    const chunks = findAll({
        autoEscape,
        caseSensitive,
        sanitize,
        searchWords,
        textToHighlight,
    });
    const HighlightTag = highlightTag;
    let highlightIndex = -1;
    let highlightClassNames = "";
    let highlightStyles;

    return createElement("span", {
        className,
        ...rest,
        children: chunks.map((chunk, index) => {
            const text = textToHighlight.substr(
                chunk.start,
                chunk.end - chunk.start
            );

            if (chunk.highlight) {
                highlightIndex++;

                let highlightClass = highlightClassName;

                const isActive = highlightIndex === +activeIndex;

                highlightClassNames = `${highlightClass} ${
                    isActive ? activeClassName : ""
                }`;
                highlightStyles =
                    isActive === true && activeStyle != null
                        ? Object.assign({}, highlightStyle, activeStyle)
                        : highlightStyle;

                const props = {
                    children: text,
                    className: highlightClassNames,
                    key: index,
                    style: highlightStyles,
                };

                return createElement(HighlightTag, props);
            } else {
                return createElement("span", {
                    children: text,
                    className: unhighlightClassName,
                    key: index,
                    style: unhighlightStyle,
                });
            }
        }),
    });
}
