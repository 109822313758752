import { Difference, DifferenceType } from '../../../../utils/differ/types';
import Highlighter from '../../common/Highlighter';
import { PrimitiveIndicator } from './PrimitiveIndicator';
import { indicatorMatchesSearchTerm } from './utils';

export class DeletedFileIndicator<T> extends PrimitiveIndicator<T> {
    /**
     * Represents indicator for deleted file. This is done as a workaround
     * because updated files should be shown as
     * — `new file name added`
     * - `old file name deleted`
     * With current markup structure it is impossible to render two bullet points
     * within one indicator. So two indicators are used instead — one for added, and
     * this one for deleted files.
     * Template:
     * - Updated    [Old file name] removed
     * - Removed:   [Old file name] removed
     */

    hasChanges(difference: Difference<T>) {
        const valueDifference = this.getValueDifference(difference);

        if (!valueDifference) {
            return false;
        }

        return [DifferenceType.Updated, DifferenceType.Removed].includes(valueDifference.type);
    }

    render(searchTerms: string[] = [], difference: Difference<T>) {
        const valueDifference = this.getValueDifference(difference);

        if (!valueDifference) {
            return null;
        }

        if (searchTerms.some(s => !!s.length) && !indicatorMatchesSearchTerm(searchTerms, difference)(this)) {
            return null;
        }

        const { previousValue } = valueDifference;

        return <span>{<Highlighter searchWords={searchTerms} autoEscape={true} textToHighlight={String(this.formatter(previousValue))} />} removed</span>;
    }
}
