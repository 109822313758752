import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { notificationActions } from "../../../../actions";
import { amrPipelineService } from "../../../../services/amr-pipeline.service";
import { AmrTransaction } from "../../../../types/amr-pipeline/models/AmrTransaction";
import { OriginatingTransaction } from "../../../../types/amr-pipeline/models/OriginatingTransaction";

export const useRequestInviteClients = (
    transaction: OriginatingTransaction | AmrTransaction,
    emailList: string[],
    message: string,
    sending: boolean,
    setSending: (isSending: boolean) => void,
    onLoadSuccess: () => void
) => {
    const { dealReferenceName, referenceName, dealLegalName } = transaction;
    const dispatch = useDispatch();

    useEffect(() => {
        const request = async () => {
            try {
                await amrPipelineService.shareTransactionToClients(
                    dealReferenceName,
                    referenceName,
                    emailList,
                    message
                );
                onLoadSuccess();
                dispatch(
                    notificationActions.notificationAddInfo(
                        "Transaction shared",
                        `${dealLegalName} transaction was shared with recipients.`
                    )
                );
            } catch (e) {
                dispatch(
                    notificationActions.notificationAddErrorMessage(
                        "Could not send invitation"
                    )
                );
            } finally {
                setSending(false);
            }
        };

        if (sending) {
            request();
        }
        // eslint-disable-next-line
    }, [sending]);
};
