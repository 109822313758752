import { Difference, DifferenceType } from '../../../../utils/differ/types';
import Highlighter from '../../common/Highlighter';
import { PrimitiveIndicator } from './PrimitiveIndicator';
import { indicatorMatchesSearchTerm } from './utils';

export class FileIndicator<T> extends PrimitiveIndicator<T> {
    /**
     * Represents indicator for added/updated file. It should be used
     * together with DeletedFileIndicator. See more specific description
     * and explanation in DeletedFileIndicator.tsx itself.
     * Template:
     * - Added      [File name] added
     * - Updated    [File name] added
     */

    render(searchTerms: string[] = [], difference: Difference<T>) {
        const valueDifference = this.getValueDifference(difference);

        if (!valueDifference) {
            return null;
        }

        if (searchTerms.some(s => !!s.length) && !indicatorMatchesSearchTerm(searchTerms, difference)(this)) {
            return null;
        }

        const { type, derivedValue } = valueDifference;

        if (type === DifferenceType.Unchanged) {
            return null;
        }

        switch (type) {
            case DifferenceType.Added:
            case DifferenceType.Updated:
                return (
                    <span>
                        {
                            <Highlighter
                                searchWords={searchTerms}
                                autoEscape={true}
                                textToHighlight={String(this.formatter(derivedValue))}
                            />
                        }{' '}
                        added
                    </span>
                );
            default:
                return null;
        }
    }
}
