import React from "react";
import { OriginatingTransactionClassStatus } from "../../../types/amr-pipeline/enums/OriginatingTransactionClassStatus";
import { OriginatingTransactionClass } from "../../../types/amr-pipeline/models/OriginatingTransactionClass";

interface Props {
    transactionClass: OriginatingTransactionClass;
}
export function OverlayClassNameContent({ transactionClass }: Props) {
    return (
        <div>
            {transactionClass.name}
            {(transactionClass as OriginatingTransactionClass)
                .originatingTransactionClassStatus ===
                OriginatingTransactionClassStatus.NotOffered && (
                <>
                    <br />
                    Not Offered
                </>
            )}
        </div>
    );
}
